import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import "./EditPage.scss";
import TopicSiderBar from '../office-lib/topic-sider-bar/TopicSiderBar';
import TopicSiderBarV2 from '../office-lib/topic-sider-bar/TopicSiderBarV2';
import { OptionBarActionType, TypeEditorColor, TypeEditorNav, TypeExcelConfig, TypeInlineStyleDict, TableDataCell, DocEditorPageBaseMode } from '../../types';
import { ContentBlock } from 'draft-js';
import EditorPageNav from '../office-lib/editor-page-nav/EditorPageNav';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import EditPageLoading from './EditPageLoading';
import systemApi from '../../api/system';
import {
    CellStyleConfig,
    DocConfig,
    DocConfigBaseInfo,
    DocOptionHistory,
    DocInstance,
    ExcelFormatBrush,
    LuckySheetSelectionRange,
    TopicType,
    PlanningBasisType,
    EditPageNavType
} from '../../utils/types';
import "./EditPage.scss";
import DocContextProvider from '../../context/DocContext';
import DocEditorContext from './../../context/DocEditorContext';
import EditorOptionBarForExcel from '../office-lib/excel-editor-option-bar/EditorOptionBarForExcel';
import EditorOptionBarForWord from '../office-lib/word-editor-option-bar/EditorOptionBarForWord';
import WordEditorV2 from '../office-lib/word-editor-deprecated/WordEditorV2';
import {
    deepCloneV2,
    defaultDocConfig,
    defaultExcelCellStyleConfig,
    defaultExcelFormatBrushConfig,
    isEmpty,
    makeClassNameList
} from '../../utils';
import WordEditorPluginsTabs from '../office-lib/word-editor-plugins/WordEditorPluginsTabs';
import ExcelEditorV2 from '../office-lib/excel-editor/ExcelEditorV2';
import WordEditorV3 from '../office-lib/word-editor/WordEditorV3';
import { generateDocInstance } from '../office-lib/word-editor/editor-doc-item-generate';
import DocEditorProvider from '../../context/DocEditorProvider';
import { DocItem } from '../slate-editor';
import { saasConfig } from '../../config/theme-config';
// import TopicManagePage from '../office-lib/topic-manage-page/TopicManagePageV2';
import TopicManagePage from '../office-lib/topic-manage-page/TopicManagePageV3';
import useReSize from '../../hooks/useResize';
import DocVersionHistroy from '../office-lib/doc-version-history/DocVersionHistroy';
import { ClickToFold } from '@icon-park/react';
import emitter from '../../utils/emitter';
import { showGuide } from '../../guide/guide-steps-config';

const EditPage = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [pageLoading, setPageLoading] = useState(true);

    const delayTimer = useRef<any>(null);
    const delayTimer1 = useRef<any>(null);
    const wordEditorRef = useRef<any>(null);
    const excelEditorRef = useRef<any>(null);
    const editorPageNavRef = useRef<any>(null);
    const wordRecommandSiderRef = useRef<any>(null);
    const wordEditorOptionBarRef = useRef<any>(null);
    const excelEditorOptionBarRef = useRef<any>(null);
    const editPageContentBodyRef = useRef<any>(null);
    const topicSideBarContainerRef = useRef<any>(null);

    const comDocInstanceChangeTimeoutRef = useRef<any>(null);

    //基础模式
    const [baseMode, setBaseMode] = useState<DocEditorPageBaseMode>('Editor');
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    //当前的规划实例
    const [comDocInstance, setComDocInstance] = useState<DocInstance>(null);
    //当前的规划配置
    const [comDocConfig, setComDocConfig] = useState<DocConfig>(defaultDocConfig)
    const [comFocusTopicNode, setcomFocusTopicNode] = useState<TopicType>(null);
    const [comFocusTableCellList, setComFocusTableCellList] = useState<TableDataCell[]>(null);
    const [comCellStyleConfig, setComCellStyleConfig] = useState<CellStyleConfig>({});

    const [currentFocusedDocItem, setCurrentFocusedDocItem] = useState<DocItem>(null);

    const [currentComExcelSelectionRange, setCurrentComExcelSelectionRange] = useState<LuckySheetSelectionRange>({
        column: [0, 12],
        row: [0, 0]
    });

    useEffect(() => {
        const listener = emitter.sub('onExcelFullScreenChange', (e: { value: boolean }) => {
            const { value } = e;
            setIsFullScreen(value);
            // //@ts-ignore
            // toggleEditPageLayoutVisible(searchParams.get('screen'));
        })
    }, [comDocConfig])

    const [currentComExcelFormatBrushConfig, setCurrentComExcelFormatBrushConfig] = useState<ExcelFormatBrush>(defaultExcelFormatBrushConfig);

    const editPageTopicPageRef = useRef<any>(null);
    const editPageExcelRef = useRef<any>(null);
    const editPageWordRef = useRef<any>(null);

    const pageContainerEl = useRef<any>(null);

    const [comFocusLayoutTopicNode, setComFocusLayoutTopicNode] = useState(null);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
            return '确认离开此规划编制页面吗？请确保所有修改已保存';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        queryProjectDetails();
        setComCellStyleConfig(deepCloneV2(defaultExcelCellStyleConfig));
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
            delayTimer1.current && clearTimeout(delayTimer1.current);
            comDocInstanceChangeTimeoutRef.current && clearTimeout(comDocInstanceChangeTimeoutRef.current)
        }
    }, [])

    const checkPlanBasisProtectlandType = (docInstance: DocInstance, protectlandTypeStr: string, planBasisProvince: string): boolean => {
        let isInclude = false;
        const {
            protectland: {
                subType,
                subTypeStr,
            },
            protectlandProvince
        } = docInstance;
        const protectlandType = subTypeStr.substring(0, subTypeStr.length - subType.length);
        const protectlandSubType = subType;
        let planBasisProtectlandGroupList = protectlandTypeStr.split(',');
        planBasisProtectlandGroupList.forEach(ele => {
            let planBasisProtectlandType = ele.split('/')[0];
            let planBasisProtectlandSubType = ele.split('/')[1] ? ele.split('/')[1] : '';
            if (planBasisProtectlandType == protectlandType) {
                //如果没有
                if (isEmpty(planBasisProtectlandSubType)) {
                    isInclude = true;
                } else if (planBasisProtectlandSubType == protectlandSubType) {
                    isInclude = true;
                }
            }
        })
        if (planBasisProvince.indexOf(protectlandProvince) == -1 && planBasisProvince !== '全选') {
            isInclude = false;
        }
        return isInclude;
    }

    const handleQuitFullScreenMode = () => {
        emitter.emit('handleQuitFullScreenMode');
        setIsFullScreen(false);
    };

    const checkGuideStep = () => {
        if(saasConfig.systemType !== 'HuaWeiTestServer'){
            return;
        }
        systemApi.getUserGuideCurrentStep({})
        .then(res => {
            if(res && res.data){
                //@ts-ignore
                const guideNowStep = res.data.guideNowStep
                if(isEmpty(guideNowStep) || guideNowStep !== 'TopicManagePageV3'){
                    localStorage.setItem('guideNowStep', guideNowStep)
                    showGuide('TopicManagePageV3');
                    systemApi.updateUserGuideCurrentStep({guideNowStep: 'TopicManagePageV3'});
                }
            }
        })
        .catch(err => {
            console.log("获取当前步骤失败---->",  err)
        })
    };


    const queryProjectDetails = () => {
        let params = {
            id: searchParams.get("docId")
        }
        let pageParams = {
            page: 1,
            size: 9999,
        }
        Promise.all([
            systemApi.getProjectBaseInfo(params),
            systemApi.queryDocExtraConfigInfo(params),
            systemApi.getPanningBasisList(pageParams)
        ])
            .then(resList => {
                //@ts-ignore
                let tempComDocConfig: DocConfig = resList[1].data;
                let tempComDocInstance: DocInstance = resList[0].data;
                const planBasisList: PlanningBasisType[] = resList[2].data.list.filter(ele => {
                    return checkPlanBasisProtectlandType(tempComDocInstance, ele.protectlandType, ele.province)
                });
                let {
                    generatedDocConfig,
                    generatedDocInstance
                } = generateDocInstance(
                    resList[0].data,
                    tempComDocConfig,
                    planBasisList
                );
                if (generatedDocInstance) {
                    generatedDocInstance.updateComponentName = 'EditPageContainer';
                    document.title = generatedDocInstance.name + ` | ${saasConfig.systemNameFirst}${saasConfig.systemNameSecond}`;
                    setComDocConfig(generatedDocConfig);
                    handleSetcomDocInstance(generatedDocInstance);
                    delayTimer.current = setTimeout(() => {
                        setPageLoading(false);
                        //@ts-ignore
                        const currentScreen: EditPageNavType = searchParams.get("screen");
                        toggleEditPageLayoutVisible(currentScreen);
                        editorPageNavRef.current?.handleSetDefaultActiveNavType(currentScreen);
                        switch(currentScreen){
                            case 'topic':
                                // showGuide('TopicManagePageV3')
                                checkGuideStep();
                                break;
                            case 'excel':
                                break;
                            case 'word':
                                break;
                            default:
                                break;
                        }
                    }, 200);
                }
            })
            .catch(err => {
                console.log("err", err)
            })
    }

    /**
     * 
     * @param layoutVisible 是否展示word
     * @returns 
     */
    const toggleEditPageLayoutVisible = (activeNavType: EditPageNavType) => {
        try {
            if (!wordEditorRef.current || (baseMode == 'Editor' && !wordRecommandSiderRef.current)) {
                console.log("toggleEditPageLayoutVisible被拦截")
                return;
            }
            editorPageNavRef.current.handleSetDefaultActiveNavType(activeNavType)
            switch (activeNavType) {
                case 'topic':
                    editPageTopicPageRef.current.style.zIndex = 999;
                    editPageExcelRef.current.style.zIndex = -999;
                    editPageWordRef.current.style.zIndex = -999;
                    //
                    editPageTopicPageRef.current.style.opacity = 1;
                    editPageExcelRef.current.style.opacity = 0;
                    editPageWordRef.current.style.opacity = 0;
                    break;
                case 'excel':
                    editPageTopicPageRef.current.style.zIndex = -999;
                    editPageExcelRef.current.style.zIndex = 999;
                    editPageWordRef.current.style.zIndex = -999;
                    //
                    editPageTopicPageRef.current.style.opacity = 0;
                    editPageExcelRef.current.style.opacity = 1;
                    editPageWordRef.current.style.opacity = 0;
                    break;
                case 'word':
                    editPageTopicPageRef.current.style.zIndex = -999;
                    editPageExcelRef.current.style.zIndex = -999;
                    editPageWordRef.current.style.zIndex = 999;
                    //
                    editPageTopicPageRef.current.style.opacity = 0;
                    editPageExcelRef.current.style.opacity = 0;
                    editPageWordRef.current.style.opacity = 1;
                    break;
                default:
                    break;
            }
            if (
                activeNavType == 'topic'
                || isFullScreen
            ) {
                topicSideBarContainerRef.current.style = "display: none";
            } else {
                topicSideBarContainerRef.current.style = "display: flex";
            }
            wordEditorOptionBarRef.current?.toggleLayoutVisible(activeNavType === 'word');
            excelEditorOptionBarRef.current?.toggleLayoutVisible(activeNavType === 'excel');
        } catch (e) {
            console.log("toggleEditPageLayoutVisible---err", e)
        }
    }

    const _onHandleResize = (newLayoutWidth: number) => {
        tempLayoutWidth.current = newLayoutWidth;
        editPageContentBodyRef.current.style.width = `calc(100% - ${newLayoutWidth}px)`;
        excelEditorRef.current?.handleChangeMainResize(newLayoutWidth);
    }

    const onEditorNavChange = (editorNavType: EditPageNavType) => {
        switch (editorNavType) {
            case 'topic':
                setSearchParams({ docId: searchParams.get("docId"), screen: 'topic', sidetab: searchParams.get("sidetab") || 'allTopic' });
                break;
            case 'excel':
                setSearchParams({ docId: searchParams.get("docId"), screen: 'excel', sidetab: searchParams.get("sidetab") || 'allTopic' });
                break;
            case 'word':
                setSearchParams({ docId: searchParams.get("docId"), screen: 'word', sidetab: searchParams.get("sidetab") || 'allTopic' });
                break;
            default:
                break;
        }
    }

    const onNavigateBack = () => {
        const formPath = localStorage.getItem('formEditPagePath')
        if (formPath) {
            navigate(formPath)
        } else {
            navigate('/home')
        }
    }

    const _handleSetcomDocInstance = (newComDocInstance: DocInstance) => {
        console.log("=========editPage更新comDocInstance======")
        setComDocInstance(deepCloneV2(newComDocInstance));
    }

    const _handleSetBaseMode = (newBaseMode: DocEditorPageBaseMode) => {
        setBaseMode(newBaseMode);
    }

    const handleSetcomDocInstance = (newComDocInstance: DocInstance) => {
        console.log("handleSetcomDocInstance--->", newComDocInstance)
        setComDocInstance(newComDocInstance);
    }

    const handleSetcomDocConfig = (newcomDocConfig: DocConfig) => {
        setComDocConfig(newcomDocConfig);
    }

    const handleContextMenu = (event: any) => {
        event.preventDefault();
    }

    const handleSetcomFocusTopicNode = (topicNode: TopicType) => {
        setcomFocusTopicNode(topicNode)
    }

    const handleSetComFocusTableCellList = (tableDataCellList: TableDataCell[]) => {
        setComFocusTableCellList(tableDataCellList);
    }

    const handleSetComCellStyleConfig = (newComCellStyleConfig: CellStyleConfig) => {
        localStorage.setItem('comCellStyleConfig', JSON.stringify(newComCellStyleConfig));
        setComCellStyleConfig(newComCellStyleConfig);
    }

    const renderWordEditor = useMemo(() => () => {
        return (
            <WordEditorV3
                ref={wordEditorRef}
            />
        )
    }, [comDocInstance])

    const renderExcelEditor = useMemo(() => () => {
        return (
            <ExcelEditorV2
                ref={excelEditorRef}
                handleSetFocusTableCellList={_handleSetFocusTableCellList}
            />
        )
    }, [comDocInstance])

    const renderTopicManagePage = useMemo(() => () => {
        return (
            <TopicManagePage />
        )
    }, [comDocInstance]);

    const getExcelExportParams = () => {
        const params = excelEditorRef.current?.getExcelExportParams();
        return params;
    }

    const onDispathCellStyle = (e) => {
        excelEditorRef.current?.onDispathCellStyle(e);
    }

    const onDispathCellBorderStyle = (e) => {
        excelEditorRef.current?.onDispathCellBorderStyle(e);
    }

    const onDispathExcelConfig = (e) => {
        excelEditorRef.current?.onDispathExcelConfig(e);
    }

    const getWordExportParams = () => {
        const params = wordEditorRef.current?.getWordExportParams();
        return params;
    }

    const fillSpeciesInfoList = (e) => {
        wordEditorRef.current?.fillSpeciesInfoList(e);
    };

    const insertDocItemToCursorPosition = (e) => {
        wordEditorRef.current?.insertDocItemToCursorPosition(e)
    }

    const handleInsertDocItemByTargetTopicName = (e) => {
        wordEditorRef.current?.insertDocItemByTargetTopicName(e)
    }

    const handleSetFocusDocItem = (e) => {
        wordEditorRef.current?.handleSetFocusDocItem(e)
    }

    const _handleSetFocusTableCellList = (e) => {
        excelEditorOptionBarRef.current && excelEditorOptionBarRef.current.handleSetFocusTableCellList(e)
    }

    const renderTopicSideBar = useMemo(() => () => {
        return <TopicSiderBarV2
            onHandleResize={_onHandleResize}
        />
        if (saasConfig.systemType == 'QingYuanDefault') {
            return <TopicSiderBarV2
                onHandleResize={_onHandleResize}
            />
        }
        return (
            <TopicSiderBar
                onHandleResize={_onHandleResize}
            />
        )
    }, [])

    const handlePasteCellText = useMemo(() => (newText: string) => {
        const luckSheetInputEl: Element = document.getElementById("luckysheet-input-box");
        const inputStyleStr: string = luckSheetInputEl.getAttribute('style');
        if (inputStyleStr.includes("z-index: 1")) {

        } else {
            excelEditorRef.current?.pasteCellText(newText)
        }
    }, [])

    const handleSetCurrentFocusParagraphBlock = (docItem: DocItem) => {
        setCurrentFocusedDocItem(docItem)
    }

    const handleSetCurrentComExcelSelectionRange = (range: LuckySheetSelectionRange) => {
        setCurrentComExcelSelectionRange(range)
    }

    const handleSetCurrentComExcelFormatBrushConfig = (excelFormatBrush: ExcelFormatBrush) => {
        setCurrentComExcelFormatBrushConfig(excelFormatBrush)
    }

    const handleSetComFocusLayoutTopicNode = (e) => {
        setComFocusLayoutTopicNode(e);
    }

    const changeBackgroundParagraph = (e) => {
        wordEditorRef.current.changeBackgroundParagraph &&
            wordEditorRef.current.changeBackgroundParagraph(e)
    }

    const changeBackgroundParagraphV2 = (e) => {
        wordEditorRef.current.changeBackgroundParagraphV2 &&
            wordEditorRef.current.changeBackgroundParagraphV2(e)
    }

    const handleRecommandMainProjectDevice = () => {
        console.log("edit-page--->")
        excelEditorRef.current.recommandMainProjectDevice();
    }

    const onMouseDown = (e: any) => {
        e.stopPropagation();
    }

    const _handleClearCellStyle = (e) => {
        excelEditorRef.current.handleClearCellStyle(e);
    }

    const containerSize = useReSize(pageContainerEl);

    useEffect(() => {
        buildLayout();
        const timer = setTimeout(() => {
            buildLayout();
        }, 1000);
        return () => {
            timer && clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        buildLayout();
    }, [containerSize, searchParams, baseMode, isFullScreen]);


    const tempLayoutWidth = useRef<number>(242);

    const buildLayout = () => {
        const { height } = containerSize;
        if (editPageTopicPageRef.current) {
            const diffHeight = baseMode == 'Editor' ? 55 : 55;
            editPageTopicPageRef.current.style.height = (height - diffHeight) + 'px';
        }
        if (editPageExcelRef.current) {
            const diffHeight = baseMode == 'Editor' ? 156 : 74;
            editPageExcelRef.current.style.height = (height - diffHeight) + 'px';
        }
        if (editPageWordRef.current) {
            const diffHeight = baseMode == 'Editor' ? 156 : 74;
            editPageWordRef.current.style.height = (height - diffHeight) + 'px';
        }
        // console.log("场景是--->", searchParams.get("screen"))
        //@ts-ignore
        toggleEditPageLayoutVisible(searchParams.get("screen") || "topic");
        ///@ts-ignore
        const currenPagetNavType: EditPageNavType = searchParams.get("screen");
        toggleEditPageLayoutVisible(currenPagetNavType);
        try {
            if (editPageContentBodyRef.current) {
                switch (currenPagetNavType) {
                    case 'excel':
                        editPageContentBodyRef.current.style.width = `calc(100% - ${tempLayoutWidth.current}px)`;
                        excelEditorRef.current?.handleChangeMainResize(tempLayoutWidth.current);
                        break;
                    case 'word':
                        editPageContentBodyRef.current.style.width = `calc(100% - ${tempLayoutWidth.current}px)`;
                        break;
                    case 'topic':
                        editPageContentBodyRef.current.style.width = `100%`;
                        break;
                    default:
                        break;
                }
            } else {

            }
        } catch (e) {

        }
    }

    return (
        <DocContextProvider.Provider
            value={{
                baseMode,
                _setBaseMode: _handleSetBaseMode,
                //规划报告实例
                comDocInstance,
                _setComDocInstance: _handleSetcomDocInstance,
                //规划配置
                comDocConfig,
                _setComDocConfig: handleSetcomDocConfig,
                //
                comFocusTopicNode: comFocusTopicNode,
                comFocusTableCellList: comFocusTableCellList,
                _setComFocusTopicNode: handleSetcomFocusTopicNode,
                _setComFocusTableCellList: handleSetComFocusTableCellList,
                //剪切板
                _pasteCellText: handlePasteCellText,
                comCellStyleConfig: comCellStyleConfig,
                _setComCellStyleConfig: handleSetComCellStyleConfig,
                //当前光标段落
                currentFocusedDocItem: currentFocusedDocItem,
                _setCurrentFocusedDocItem: handleSetCurrentFocusParagraphBlock,
                comExcelSelectionRange: currentComExcelSelectionRange,
                _setComExcelSelectionRange: handleSetCurrentComExcelSelectionRange,
                comExcelFormatBrushConfig: currentComExcelFormatBrushConfig,
                _setComExcelFormatBrushConfig: handleSetCurrentComExcelFormatBrushConfig,
                comFocusLayoutTopicNode: comFocusLayoutTopicNode,
                _setComFocusLayoutTopicNode: handleSetComFocusLayoutTopicNode
            }}
        >
            <DocEditorProvider>
                <div
                    id="editPageId"
                    ref={pageContainerEl}
                    className='flex-col edit-page-container-v1'
                    onContextMenu={handleContextMenu}
                >
                    {
                        isFullScreen ?
                            <div
                                className='flex-row quit-full-screen-btn'
                                onClick={handleQuitFullScreenMode}
                            >
                                <ClickToFold theme="outline" size="24" fill="#ffffff" />
                                &nbsp;退出全屏
                            </div>
                            :
                            null
                    }
                    <div style={{ width: '100vw', zIndex: 99, display: isFullScreen ? 'none' : 'flex' }}>
                        <EditorPageNav
                            ref={editorPageNavRef}
                            onEditorNavChange={onEditorNavChange}
                            onNavigateBack={onNavigateBack}
                        />
                    </div>
                    <div
                        className={makeClassNameList([
                            'flex-row edit-page-with-history-container',
                            isFullScreen ? 'is-fullscreen' : null
                        ])}
                    >
                        <div
                            className={makeClassNameList([
                                'flex-col edit-page-with-history-content',
                                baseMode == 'VersionPreview' ? 'with-version-preview' : null
                            ])}
                        >
                            {
                                baseMode !== 'VersionPreview' &&
                                    !isFullScreen
                                    ?
                                    <>
                                        <EditorOptionBarForExcel
                                            ref={excelEditorOptionBarRef}
                                            getExcelExportParams={getExcelExportParams}
                                            getWordExportParams={getWordExportParams}
                                            onDispathCellStyle={onDispathCellStyle}
                                            onDispathCellBorderStyle={onDispathCellBorderStyle}
                                            onDispathExcelConfig={onDispathExcelConfig}
                                            handleRecommandMainProjectDevice={handleRecommandMainProjectDevice}
                                            _handleClearCellStyle={_handleClearCellStyle}
                                        />
                                        <EditorOptionBarForWord
                                            ref={wordEditorOptionBarRef}
                                            getExcelExportParams={getExcelExportParams}
                                            getWordExportParams={getWordExportParams}
                                            fillSpeciesInfoList={fillSpeciesInfoList}
                                        />
                                    </>
                                    :
                                    null
                            }
                            {
                                comDocInstance && comDocInstance.topicList && comDocInstance.topicList.length && comDocConfig ?
                                    <div
                                        className={'edit-page-content-v1 flex-row'}
                                    >
                                        <div
                                            ref={topicSideBarContainerRef}
                                            className='topic-side-bar-container'
                                        >
                                            {renderTopicSideBar()}
                                        </div>
                                        <div
                                            ref={editPageContentBodyRef}
                                            className='edit-page-content-body-v1'
                                        >
                                            <div
                                                ref={editPageTopicPageRef}
                                                className='edit-page-content-wapper-v1'
                                                style={{ marginTop: baseMode == 'VersionPreview' ? -10 : 0 }}
                                            >
                                                {renderTopicManagePage()}
                                            </div>
                                            <div
                                                ref={editPageExcelRef}
                                                className={'flex-col edit-page-content-wapper-v1'}
                                                style={{ height: baseMode == 'Editor' ? 'calc(100vh - 156px)' : 'calc(100vh - 74px)' }}
                                            >
                                                {renderExcelEditor()}
                                            </div>
                                            <div
                                                ref={editPageWordRef}
                                                className='flex-row edit-page-content-wapper-v1'
                                                style={{ height: baseMode == 'Editor' ? 'calc(100vh - 156px)' : 'calc(100vh - 74px)' }}
                                                onMouseDown={onMouseDown}
                                                onMouseUp={onMouseDown}
                                                onClick={onMouseDown}
                                            >
                                                {renderWordEditor()}
                                                {
                                                    baseMode == 'Editor' ?
                                                        <div
                                                            ref={wordRecommandSiderRef}
                                                            className='flex-row edit-page-content-right-v1 content-border content-border-radius'
                                                            style={{ marginLeft: 10 }}
                                                        >
                                                            <WordEditorPluginsTabs
                                                                changeBackgroundParagraph={changeBackgroundParagraph}
                                                                changeBackgroundParagraphV2={changeBackgroundParagraphV2}
                                                                insertDocItemByTargetTopicName={handleInsertDocItemByTargetTopicName}
                                                                insertDocItemToCursorPosition={insertDocItemToCursorPosition}
                                                                fillSpeciesInfoList={fillSpeciesInfoList}
                                                                handleSetFocusDocItem={handleSetFocusDocItem}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='flex-col' style={{ width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                        <h1 style={{ color: '#909399', marginTop: '12vh' }}>规划数据异常</h1>
                                    </div>
                            }
                        </div>
                        {
                            baseMode == 'VersionPreview' ?
                                <DocVersionHistroy />
                                :
                                null
                        }
                    </div>
                    {
                        pageLoading ?
                            <EditPageLoading />
                            :
                            null
                    }
                </div>
            </DocEditorProvider>
        </DocContextProvider.Provider>
    )
}


export default EditPage;