import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { copyTextToClipboard, downloadAndRenameFile, handleDownloadByName, handleDownloadByNameV2, isEmpty, toastShort } from '../../utils';
import { UserAddOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Progress, Space } from 'antd';
import systemApi from '../../api/system';
import { DocConfigBaseInfo, DocInstance, WordParagraphBlock, WordParagraphType } from '../../utils/types';
import { convertExcelCellDataListToTableData, generateBlockId } from '../../views/office-lib/word-editor-deprecated/WordEditorHelper';
import { convertWordParagraphListToDocItemList } from '../../views/office-lib/word-editor/editor-doc-item-generate';
import { processDocItemListForExport } from '../../views/office-lib/word-editor/process-doc-Item-list';
import { DocItem } from '../../views/slate-editor';

interface Props {
    exportExcel?: Function
    exportWord?: Function
    type: 'excel' | 'word'
    clientX: number
    clientY: number
    comDocInstance: DocInstance
}

const ExportPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY,
        type = 'excel',
        exportExcel,
        exportWord,
        comDocInstance
    } = props;

    const delayTimer = useRef<any>(null);
    const delayTimer2 = useRef<any>(null);
    const [percent, setPercent] = useState<number>(0);
    const currentPercent = useRef<number>(0);
    const isActive = useRef<boolean>(false);
    const customSelectRef = useRef<any>(null);
    const tempDownloadUrl = useRef<string>(null);
    const tempDownloadName = useRef<string>('建设工程投资估算与安排表');

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
            delayTimer2.current && clearTimeout(delayTimer2.current);
        }
    }, [])

    const autoDownloadExportFile = () => {
        setPercent(100)
        currentPercent.current = 0;
        if (tempDownloadUrl.current) {
            toastShort('success', '导出文件已生成')
            handleDownloadByNameV2(tempDownloadUrl.current, tempDownloadName.current)
        }
        setTimeout(() => {
            customSelectRef.current._handleClose();
        }, 200);
    }

    const openPopover = () => {
        setPercent(0)
        customSelectRef.current.show();
        let wordExportParams = exportWord();
        const excelExportParams = exportExcel();
        const wordExportParamsObject = JSON.parse(wordExportParams);
        const excelExportParamsObject = JSON.parse(excelExportParams);
        const {
            protectlandBaseInfo: {
                protectlandName
            }
        } = comDocInstance;
        const excelFileName = `附表 ${protectlandName}建设工程投资估算与安排表`;
        const wordFileName = `${protectlandName} (${comDocInstance.protectlandBaseInfo.startYear.substr(0, 4)}-${comDocInstance.protectlandBaseInfo.endYear.substr(0, 4)}年)`;
        if (type == 'word') {
            const covertExcelCellDataListToTableDataResult = convertExcelCellDataListToTableData(
                excelExportParamsObject.excelCellDataList,
                excelExportParamsObject.docExtraConfigInfo.mergeBoundInfo
            )
            let planSheetTableNameParagraph: WordParagraphBlock = {
                type: 'text',
                paragraphStyle: 'table_name_paragraph',
                text: excelFileName,
            }
            let planSheetTableDataParagraph: WordParagraphBlock = {
                blockId: generateBlockId(),
                tableData: covertExcelCellDataListToTableDataResult.tableData.splice(1, covertExcelCellDataListToTableDataResult.tableData.length - 1),
                // tableHeaderRowIndex: 3,
                tableHeaderRowIndex: 2,
                tableName: excelFileName,
                tableRowSpanList: covertExcelCellDataListToTableDataResult.tableRowSpanInfoList.map(rowSpanInfo => {
                    let newRowSpanInfo = rowSpanInfo;
                    newRowSpanInfo.rowIndex = rowSpanInfo.rowIndex - 1;
                    return newRowSpanInfo;
                }),
                tableColSpanList: covertExcelCellDataListToTableDataResult.tableColSpanInfoList.map(colSpanInfo => {
                    let newColSpanInfo = colSpanInfo;
                    newColSpanInfo.rowIndex = colSpanInfo.rowIndex - 1;
                    return newColSpanInfo;
                }).filter(colSpanInfo => colSpanInfo.rowIndex >= 0),
                type: 'Table'
            }
            const tableNameDocItem: DocItem = convertWordParagraphListToDocItemList([planSheetTableNameParagraph])[0];
            tableNameDocItem.textStyleMap = {
                "$$[99999]": {
                    text: tableNameDocItem.text,
                }
            }
            tableNameDocItem.text = "$$[99999]";
            wordExportParamsObject.docItemList.push(tableNameDocItem);
            wordExportParamsObject.docItemList.push(convertWordParagraphListToDocItemList([planSheetTableDataParagraph]));
        }
        wordExportParams = JSON.stringify(wordExportParamsObject);
        console.log("wordExportParams------>", wordExportParams);
        // return;
        startCountTimer();
        tempDownloadUrl.current = null;
        if (type == 'excel') {
            systemApi.exportExcel({ xls_data: excelExportParams })
                .then(res => {
                    console.log("生成结果是----->", res)
                    //@ts-ignore
                    tempDownloadUrl.current = res.data.url;
                    tempDownloadName.current = excelFileName;
                    autoDownloadExportFile();
                })
                .catch(err => {

                })
        } else {
            systemApi.exportWord({ doc_data: wordExportParams })
                .then(res => {
                    console.log("生成结果是----->", res)
                    //@ts-ignore
                    tempDownloadUrl.current = res.data.url;
                    tempDownloadName.current = wordFileName;
                    autoDownloadExportFile()
                })
                .catch(err => {
                })
        }
    };

    const startCountTimer = () => {
        // console.log("startCountTime---->", currentPercent.current)
        // if (currentPercent.current >= 100) {
        //     // console.log("返回")
        //     delayTimer2.current = setTimeout(() => {
        //         customSelectRef.current._handleClose();
        //         setPercent(0)
        //         currentPercent.current = 0;
        //         if (tempDownloadUrl.current) {
        //             toastShort('success', '导出文件已生成')
        //             // handleDownloadByName(tempDownloadUrl.current, type == 'excel' ? '投资估算表' : '规划报告')
        //             handleDownloadByNameV2(tempDownloadUrl.current, tempDownloadName.current)
        //         }
        //     }, type == 'word' ? 10650 : 650);
        //     return false;
        // }
        // delayTimer.current && clearTimeout( delayTimer.current)
        delayTimer.current = setTimeout(() => {
            let _currentPercent = currentPercent.current + 2;
            currentPercent.current = _currentPercent;
            if(_currentPercent > 99 && isEmpty(tempDownloadUrl.current)){
                console.log("进入等待环节---->")
                return;
            }
            setPercent(_currentPercent);
            startCountTimer();
        }, type == 'word' ? 6.4 * 100 : 100);
    }

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}

            contentRender={(props) => (
                <div className='flex-col export-box'>
                    <Progress
                        type="circle"
                        percent={percent}
                        size={'small'}
                    // strokeColor={'#3fb075'}
                    // trailColor={'#3fb075'}
                    />
                    <p className='export-box-title'>文档导出中</p>
                    <p className='export-box-desc'>请勿关闭此窗口</p>
                    {/* <Button>立即下载</Button> */}
                </div>
            )}
        />
    )
}


export default forwardRef(ExportPopover);