import './WordEdit.scss';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import WordEditorContextMenu from "./WordEditorContextMenu";
import { Editor, Transforms, Text, Point } from 'slate';
import { DocEditor, DocItem, DocItemType } from '../../slate-editor';
import comDocContext from '../../../context/DocContext';
import {
    getChangedSlateEditorDocItemList, getTitleDocItemType, mergeNewSlateEditorInputValueToDocItemList,
    translateDocInstanceToSlateEditorData, translateSlateEditorDataToDocInstance
} from './editor-doc-item-generate';
import { DocInstance, SpeciesInfoType, TopicType } from '../../../utils/types';
import { commonErrorMsg, deepCloneV2, findTargetIndexInArrayListById, getUuid, insertArrayAtPosition, isEmpty, toastShort } from '../../../utils';
import DocEditorContext from './../../../context/DocEditorContext'
import { checkIsSlaterEditorTextParagraph, moveToParagraphById } from './utils';
import { replaceDocInfo } from '../word-editor-deprecated/WordEditorHelper';
import { processDocItemListForExport } from './process-doc-Item-list';
import emitter from '../../../utils/emitter';
import { message, Modal } from 'antd';
import { fillSpeciesLatinName, fillSpeciesLatinNameV3, mergeSpeciesInfoList } from './fill-species-latin-utils';
import LoadingWapper from '../../../components/loading/LoadingWapper';
import systemApi from '../../../api/system';
import axios from 'axios';
import { saasConfig } from '../../../config/theme-config';
import { debounce, throttle } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {

}

const WordEditorV3 = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        getWordExportParams: getWordExportParams,
        insertDocItemToCursorPosition: handleInsertDocItemToCursorPosition,
        fillSpeciesInfoList: handleFillSpeciesInfoList,
        insertDocItemByTargetTopicName: handleInsertDocItemByTargetTopicName,
        changeBackgroundParagraph: handleChangeBackgroundParagraph,
        changeBackgroundParagraphV2: handleChangeBackgroundParagraphV2,
        handleSetFocusDocItem: handleSetFocusDocItem,
    }))

    const {
        comDocInstance,
        _setComDocInstance,
        _setCurrentFocusedDocItem,
        comFocusTopicNode,
        comDocConfig,
        comFocusLayoutTopicNode,
        _setComFocusLayoutTopicNode,
        _setComFocusTopicNode
    } = useContext(comDocContext);

    const [modal, modalContextHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();

    const containerRef = useRef(null);
    const scrollContentRef = useRef(null);

    const docEditorRef = useContext(DocEditorContext);

    const wordEditorFocusTrackLineRef = useRef(null);
    const wordEditorHighLightTrackLineRef = useRef(null);

    const wordEditorContextMenuRef = useRef(null);
    const isWordEditorContextMenuOpened = useRef<boolean>(null);
    const slateEditorBaseData = { '[*]': '保护区', '[NAME]': '名称', '[type]': '类型', '[P]': 'P数据' };

    const oldSlateEditorValue = useRef<DocItem[]>([]);
    const tempSlateEditorValue = useRef<DocItem[]>([]);
    const freshSlateEditorValue = useRef<DocItem[]>([]);

    const tempDocInstance = useRef<DocInstance>(null);
    const [currentDocInstance, setCurrentDocInstance] = useState<DocInstance>(null);

    const slateEditorChangeDelayTimer = useRef(null);

    const delayScrollTimer = useRef(null);
    const delayerAddParagraphMarkerTimer = useRef(null);
    const delayFocusCurrentParagraphTimer = useRef(null);
    const forceUpdateNextTime = useRef(false);
    const forceUpdateDelayTimer = useRef(null);
    const generateDocItemListDelayTimer = useRef(null);
    const delayScrollToTargetTopicTimer = useRef(null);
    const updateCurrentEditorValueTimes = useRef(0);
    const docItemDeleteClickTimer = useRef(null);

    const wordEditorLoadingRef = useRef<any>(null);

    const cursorLocatedDocItem = useRef<DocItem>(null);

    const onWordEditorContextMenuClose = () => {
        isWordEditorContextMenuOpened.current = false;
    }

    const slateEditorScrollTop = useRef<number>(0);
    const slateEditorIsRefreshing = useRef<boolean>(false);

    const addtionalDocItemListRef = useRef<DocItem[]>([]);

    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        emitter.sub('AddNewLine', (e: { docItemId: string }) => {
            const { docItemId } = e;
            addEmptyParagraphToDocItemList(docItemId);
        })
        emitter.sub('RemoveLine', (e: { docItemId: string }) => {
            const { docItemId } = e;
            removeEmptyParagraphFromDocItemList(docItemId);
        })
        emitter.sub('onWordPaste', () => {
            try {
                const editor: Editor = docEditorRef.current.getSlateEditor();
                navigator.clipboard.readText().then(text => {
                    if (!text) return;
                    if (!editor.selection) {
                        Transforms.select(editor, Editor.start(editor, []));
                    }
                    Transforms.insertText(editor, text);
                }).catch(err => {
                    console.error('文本粘贴失败', err);
                    toastShort('error', commonErrorMsg)
                });
            } catch (e) {
                toastShort('error', commonErrorMsg)
            }
        })
        emitter.sub('onWordCopy', () => {
            try {
                const editor: Editor = docEditorRef.current.getSlateEditor();
                if (!editor.selection) return;
                const selectedText = Editor.string(editor, editor.selection);
                console.log(selectedText);
                navigator.clipboard.writeText(selectedText).then(() => {
                }).catch(err => {
                    console.error('Failed copy', err);
                    toastShort('error', commonErrorMsg)
                });
            } catch (e) {
                console.log("copy-err", e)
                toastShort('error', commonErrorMsg)
            }
        })
        return () => {
            delayScrollToTargetTopicTimer.current && clearTimeout(delayScrollToTargetTopicTimer.current);
            generateDocItemListDelayTimer.current && clearTimeout(generateDocItemListDelayTimer.current);
            forceUpdateDelayTimer.current && clearTimeout(forceUpdateDelayTimer.current);
            delayScrollTimer.current && clearTimeout(delayScrollTimer.current);
            slateEditorChangeDelayTimer.current && clearTimeout(slateEditorChangeDelayTimer.current);
            delayerAddParagraphMarkerTimer.current && clearTimeout(delayerAddParagraphMarkerTimer.current);
            delayFocusCurrentParagraphTimer.current && clearTimeout(delayFocusCurrentParagraphTimer.current);
        }
    }, []);

    const focusToParagraph = (targtNodeId: string) => {
        try {
            if (targtNodeId) {
                const findTopicNode = comDocInstance.topicList.find(topic => topic.id == targtNodeId);

                let wordEditorFocusTrackLineHeight = 0;
                let wordEditorFocusTrackLineOffsetTop = 0;
                let textTopicId = '';
                if (findTopicNode.topicType == 'text') {
                    textTopicId = findTopicNode.id;
                } else if (findTopicNode.topicType == 'device') {
                    const findTextTopic = comDocInstance.topicList.find(node => {
                        return node.id == findTextTopic.pid;
                    })
                    textTopicId = findTextTopic.id;
                }
                let docItemListOfCurrentFocusTopicNode: DocItem[] = [];
                if (textTopicId) {
                    docItemListOfCurrentFocusTopicNode = tempSlateEditorValue.current.filter(item => {
                        return String(item.id).includes(textTopicId)
                    });
                }
                if (docItemListOfCurrentFocusTopicNode.length) {
                    docItemListOfCurrentFocusTopicNode.forEach(docItem => {
                        let elementTag = '';
                        switch (docItem.type) {
                            case DocItemType.H1:
                                elementTag = 'h1';
                                break;
                            case DocItemType.H2:
                                elementTag = 'h2';
                                break;
                            case DocItemType.H3:
                                elementTag = 'h3';
                                break;
                            case DocItemType.H4:
                                elementTag = 'h4';
                                break;
                            case DocItemType.H5:
                                elementTag = 'h5';
                                break;
                            case DocItemType.H6:
                                elementTag = 'h6';
                                break;
                            case DocItemType.P:
                            case DocItemType.CP:
                                elementTag = 'p';
                                break;
                        }
                        const docItemId = docItem.id;
                        console.log("elementTag---->", elementTag)
                        const docItemIdQuerySelector = `div[data-doc-item-id="${docItemId}"], 
                        p[data-doc-item-id="${docItemId}"], 
                        h1[data-doc-item-id="${docItemId}"], 
                        h2[data-doc-item-id="${docItemId}"], 
                        h3[data-doc-item-id="${docItemId}"], 
                        h4[data-doc-item-id="${docItemId}"], 
                        h5[data-doc-item-id="${docItemId}"], 
                        h6[data-doc-item-id="${docItemId}"]`
                        // console.log("docItemIdQuerySelector--->", docItemIdQuerySelector)
                        const blockEl: Element = document.querySelector(docItemIdQuerySelector);
                        //@ts-ignore
                        // console.log("blockEl--->", blockEl, blockEl.offsetTop, blockEl.clientHeight)
                        //@ts-ignore
                        const blockOffsetTop = blockEl.offsetTop;
                        if (wordEditorFocusTrackLineOffsetTop == 0) {
                            wordEditorFocusTrackLineOffsetTop = blockOffsetTop;
                        }
                        wordEditorFocusTrackLineHeight += blockEl.clientHeight;
                    })
                }
                scrollContentRef.current.scrollTo({
                    top: wordEditorFocusTrackLineOffsetTop,
                    behavior: 'smooth',
                });
                wordEditorFocusTrackLineRef.current.style.height = wordEditorFocusTrackLineHeight + 'px';
                wordEditorFocusTrackLineRef.current.style.top = wordEditorFocusTrackLineOffsetTop + 'px';
            }
        } catch (e) {
            console.log("查找comFocusTopicNode对应的文档锚点--->失败", e)
            toastShort('warning', '无法自动聚焦文本段落')
        }
    };

    useEffect(() => {
        if (comFocusTopicNode) {
            focusToParagraph(comFocusTopicNode.id)
        }
    }, [comFocusTopicNode]);

    useEffect(() => {
        if (isEmpty(comDocInstance) || comDocInstance.updateComponentName == 'WordEditor') {
            return;
        }
        console.log("文本编辑器收到更新--->comDocInstance", deepCloneV2(comDocInstance))
        const newDocInstance: DocInstance = deepCloneV2(comDocInstance);
        const { additionalDocItemList } = newDocInstance;
        if (additionalDocItemList && addtionalDocItemListRef.current.length == 0) {
            addtionalDocItemListRef.current = [...additionalDocItemList];
        }
        tempDocInstance.current = newDocInstance;
        setCurrentDocInstance(newDocInstance);
        // }, [comDocInstance]);
    }, [comDocInstance, comDocConfig]);

    const addEmptyParagraphToDocItemList = (cursorPositionDocItemId: string) => {
        try {
            const textTopicId = cursorPositionDocItemId.split('-')[0];
            let _tempDocInstance = tempDocInstance.current;
            let _tempTopicList = _tempDocInstance.topicList;
            let needToFocusDocItemId = null;
            _tempTopicList.forEach(topic => {
                if (topic.topicType == 'text' && topic.id == textTopicId) {
                    let { topicSlateDocItemList } = topic;
                    const newDocItemId = `${textTopicId}-usp`;
                    const findIndex = topicSlateDocItemList.findIndex(docItem => docItem.id == cursorPositionDocItemId);
                    const newDocItemList = [{
                        id: newDocItemId,
                        plainText: "",
                        text: "",
                        type: "p"
                    }]
                    if (findIndex > -1) {
                        needToFocusDocItemId = newDocItemId;
                        const newTopicSlateDocItemList = insertArrayAtPosition(topicSlateDocItemList, newDocItemList, findIndex + 1);
                        topic.topicSlateDocItemList = deepCloneV2(newTopicSlateDocItemList);
                    }
                }
            })
            _tempDocInstance.topicList = deepCloneV2(_tempTopicList);
            const {
                docItemList
            } = translateDocInstanceToSlateEditorData(_tempDocInstance, comDocConfig);
            let tempDocItemList = docItemList;
            docEditorRef.current.forceUpdateV2();
            updateCurrentEditorValue([...tempDocItemList], false);
            tempDocInstance.current = _tempDocInstance;
            // autoScrollToTargetTopic(targetTextTopic);
            handleUpdateComDocInstance(_tempDocInstance);
            if (needToFocusDocItemId) {
                setTimeout(() => {
                    if (docEditorRef.current) {
                        const editor: Editor = docEditorRef.current.getSlateEditor();
                        moveToParagraphById(editor, needToFocusDocItemId, true)
                    }
                }, 500);
                setTimeout(() => {
                    if (docEditorRef.current) {
                        const editor: Editor = docEditorRef.current.getSlateEditor();
                        moveToParagraphById(editor, needToFocusDocItemId, true)
                    }
                }, 1000);
            }
        } catch (e) {
            toastShort("error", "添加段落失败")
        }
    };

    const removeEmptyParagraphFromDocItemList = (cursorPositionDocItemId: string) => {
        try {
            const textTopicId = cursorPositionDocItemId.split('-')[0];
            let _tempDocInstance = tempDocInstance.current;
            let _tempTopicList = _tempDocInstance.topicList;
            let needToFocusDocItemId = null;
            _tempTopicList.forEach(topic => {
                if (topic.topicType == 'text' && topic.id == textTopicId) {
                    let { topicSlateDocItemList } = topic;
                    const findIndex = topicSlateDocItemList.findIndex(docItem => docItem.id == cursorPositionDocItemId);
                    if (findIndex > -1) {
                        needToFocusDocItemId = topicSlateDocItemList[findIndex - 1].id;;
                        topicSlateDocItemList.splice(findIndex, 1);
                        topic.topicSlateDocItemList = deepCloneV2(topicSlateDocItemList);
                    }
                }
            })
            _tempDocInstance.topicList = deepCloneV2(_tempTopicList);
            const {
                docItemList
            } = translateDocInstanceToSlateEditorData(_tempDocInstance, comDocConfig);
            let tempDocItemList = docItemList;
            docEditorRef.current.forceUpdateV2();
            updateCurrentEditorValue([...tempDocItemList], false);
            tempDocInstance.current = _tempDocInstance;
            handleUpdateComDocInstance(_tempDocInstance);
            if (needToFocusDocItemId) {
                setTimeout(() => {
                    if (docEditorRef.current) {
                        const editor: Editor = docEditorRef.current.getSlateEditor();
                        moveToParagraphById(editor, needToFocusDocItemId, false)
                    }
                }, 500);
                setTimeout(() => {
                    if (docEditorRef.current) {
                        const editor: Editor = docEditorRef.current.getSlateEditor();
                        moveToParagraphById(editor, needToFocusDocItemId, false)
                    }
                }, 1000);
            }
        } catch (e) {
            toastShort("error", "移除段落失败")
        }
    }

    const handleUpdateComDocInstance = (newDocInstance: DocInstance) => {
        // if(checkTwoDocInstanceEquals(newDocInstance, tempDocInstance.current)){
        //     console.warn("wordEditorV3两个数据相等--->拦截")
        //     return;
        // }
        // console.log("handleUpdateComDocInstance--->", newDocInstance)
        tempDocInstance.current = newDocInstance;
        newDocInstance.updateComponentName = 'WordEditor';
        _setComDocInstance(newDocInstance);
    }

    useEffect(() => {
        if (
            comFocusLayoutTopicNode &&
            comFocusLayoutTopicNode.topic &&
            comFocusLayoutTopicNode.from !== 'word'
        ) {
            focusToParagraph(comFocusLayoutTopicNode.topic.id)
        }
    }, [comFocusLayoutTopicNode]);

    useEffect(() => {
        let resizeObserver = null;
        try {
            resizeObserver = new ResizeObserver(entries => {
                addPargraphMarker();
            });
            resizeObserver && resizeObserver.observe(containerRef.current);
        } catch (e) { }
        return () => {
            try {
                resizeObserver && resizeObserver?.unobserve(containerRef.current);
            } catch (e) { }
        }
    }, []);

    const addPargraphMarker = () => {
        return;
        delayerAddParagraphMarkerTimer.current && clearTimeout(delayerAddParagraphMarkerTimer.current);
        delayerAddParagraphMarkerTimer.current = setTimeout(() => {
            try {
                const editorContent = document.getElementsByClassName("word-edit-area-content")[0];
                const systemChapterParagraphMarkerItems = document.getElementsByClassName(`paragraph-marker`);
                Array.from(systemChapterParagraphMarkerItems).forEach(el => {
                    el.remove();
                })
                let editorWrapper = document.querySelector(`div[role="textbox"]`);
                const childrenElemens = editorWrapper.children;
                let docItemIdGroupList: { startIndex: number; endIndex: number; docItemIdList: string[] }[] = [];
                Array.from(childrenElemens).forEach((ele, index) => {
                    const docItemId = ele.getAttribute('data-doc-item-id');
                    if (docItemId?.includes('-SP-')) {
                        if (docItemIdGroupList.length) {
                            const lastDocItemGroup = docItemIdGroupList[docItemIdGroupList.length - 1];
                            if (lastDocItemGroup.endIndex == index) {
                                lastDocItemGroup.docItemIdList.push(docItemId);
                                lastDocItemGroup.endIndex++;
                            } else {
                                docItemIdGroupList.push({
                                    startIndex: index,
                                    endIndex: index + 1,
                                    docItemIdList: [docItemId]
                                })
                            }
                        } else {
                            docItemIdGroupList.push({
                                startIndex: index,
                                endIndex: index + 1,
                                docItemIdList: [docItemId]
                            })
                        }
                    }
                })
                let uniquenDocItemIdList: string[] = [];
                docItemIdGroupList.forEach(group => {
                    let layoutHeight = 0;
                    let startBlockEl = null;
                    let layoutOffsetTop = 0;
                    group.docItemIdList.forEach((docItemId) => {
                        if (!uniquenDocItemIdList.includes(docItemId)) {
                            const selector = `div[data-doc-item-id="${docItemId}"], 
                                  p[data-doc-item-id="${docItemId}"], 
                                  h1[data-doc-item-id="${docItemId}"], 
                                  h2[data-doc-item-id="${docItemId}"], 
                                  h3[data-doc-item-id="${docItemId}"], 
                                  h4[data-doc-item-id="${docItemId}"], 
                                  h5[data-doc-item-id="${docItemId}"], 
                                  h6[data-doc-item-id="${docItemId}"]`
                            const blockEl: HTMLBaseElement = document.querySelector(selector);
                            if (!startBlockEl) {
                                startBlockEl = blockEl;
                                layoutOffsetTop = blockEl ? blockEl.offsetTop : 0;
                            }
                            layoutHeight += blockEl.offsetHeight;
                            uniquenDocItemIdList.push(docItemId);
                        }
                    })
                    if (layoutHeight > 0) {
                        const markerContainerEl = document.createElement('div');
                        const markerContainerTopEl = document.createElement('div');
                        markerContainerTopEl.className = 'paragraph-marker-top';
                        const markerContainerBottomEl = document.createElement('div');
                        markerContainerBottomEl.className = 'paragraph-marker-bottom';
                        const markerContentEl = document.createElement('div');
                        markerContentEl.className = 'paragraph-marker-content';
                        markerContentEl.innerText = '系统章节'
                        markerContainerEl.className = 'paragraph-marker flex-col';
                        markerContainerEl.style.height = `${layoutHeight}px`;
                        markerContainerEl.style.top = `${layoutOffsetTop + 18}px`;
                        markerContainerEl.appendChild(markerContainerTopEl);
                        markerContainerEl.appendChild(markerContentEl);
                        markerContainerEl.appendChild(markerContainerBottomEl);
                        editorContent.appendChild(markerContainerEl);
                    }
                })
            } catch (e) {
            }
        }, 1500)
    }

    const addCurrentParagraphFocusMarker = () => {
        delayFocusCurrentParagraphTimer.current && clearTimeout(delayFocusCurrentParagraphTimer.current);
        delayFocusCurrentParagraphTimer.current = setTimeout(() => {
            let layoutOffsetTop = -1;
            let layoutOffsetHeight = 0;
            if (cursorLocatedDocItem.current) {
                let editorWrapper = document.querySelector(`div[role="textbox"]`);
                //@ts-ignore
                const childrenElemens: HTMLBaseElement[] = Array.from(editorWrapper.children);
                childrenElemens.forEach((el, index) => {
                    if (el.getAttribute('data-doc-item-id') == cursorLocatedDocItem.current.id) {
                        layoutOffsetHeight += el.offsetHeight;
                        if (layoutOffsetTop < 0) {
                            layoutOffsetTop = el.offsetTop;
                        }
                    }
                })
                wordEditorHighLightTrackLineRef.current.style.top = layoutOffsetTop + 'px';
                wordEditorHighLightTrackLineRef.current.style.height = layoutOffsetHeight + 'px';
            } else {
                wordEditorHighLightTrackLineRef.current.style.height = 0 + 'px';
            }
        }, 200);
    }

    const [currentSlateEditorValue, setCurrentSlateEditorValue] = useState([]);

    const generateDocItemList = () => {
        if (generateDocItemListDelayTimer.current) {
            clearTimeout(generateDocItemListDelayTimer.current)
        }
        generateDocItemListDelayTimer.current = setTimeout(() => {
            let tempDocItemList: DocItem[] = [];
            if (currentDocInstance) {
                slateEditorIsRefreshing.current = true;
                const {
                    docItemList
                } = translateDocInstanceToSlateEditorData(
                    currentDocInstance,
                    comDocConfig
                )
                console.log("=======本次生成的docItemList=======", docItemList)
                tempDocItemList = deepCloneV2(docItemList);
            }
            updateCurrentEditorValue(tempDocItemList, true);
            addPargraphMarker();
        }, 10);
    }

    useEffect(() => {
        if (forceUpdateDelayTimer.current) {
            clearTimeout(forceUpdateDelayTimer.current);
        }
        if (forceUpdateNextTime.current) {
            forceUpdateNextTime.current = false;
            docEditorRef.current.forceUpdateV2();
            forceUpdateDelayTimer.current = setTimeout(() => {
                generateDocItemList();
            }, 100);
        } else {
            generateDocItemList();
        }
    }, [currentDocInstance]);

    useEffect(() => {
        if (currentSlateEditorValue.length) {
            delayScrollTimer.current = setTimeout(() => {
                scrollContentRef.current.scrollTop = slateEditorScrollTop.current;
                slateEditorIsRefreshing.current = false;
            }, 20);
        }
    }, [currentSlateEditorValue])

    const onSlateEditorChange = (newSlateValue: DocItem[]) => {
        if (slateEditorChangeDelayTimer.current) {
            clearTimeout(slateEditorChangeDelayTimer.current)
        }
        slateEditorChangeDelayTimer.current = setTimeout(() => {
            if (newSlateValue.length == 0 || newSlateValue.length == 1) {
                return;
            }
            const changedDocItemList = getChangedSlateEditorDocItemList(newSlateValue, tempSlateEditorValue.current);
            freshSlateEditorValue.current = deepCloneV2(newSlateValue);
            if (changedDocItemList.length) {
                updateComDocInstanceBySlateEditor(deepCloneV2(newSlateValue));
                addPargraphMarker();
            } else {
                console.error("不更新---->")
            }
        }, 1500);
    }

    const onDocItemClick = (docItem: DocItem) => {
        try {
            let findTextTopic = comDocInstance.topicList.find(topic => topic.id == docItem.id.toString().split('-')[0]);
            if (findTextTopic) {
                _setComFocusLayoutTopicNode({
                    from: 'word',
                    topic: findTextTopic
                })
                //这段代码已经废弃
                // _setComFocusTopicNode(findTextTopic)
            }
        } catch (e) {
        }
        cursorLocatedDocItem.current = docItem;
        addCurrentParagraphFocusMarker();
        if (docItem.type !== DocItemType.TABLE) {
            _setCurrentFocusedDocItem(docItem);
        }
    }

    const confirmToDeleteDocItem = (docItem: DocItem) => {
        let tempDocItemList: DocItem[] = deepCloneV2(tempSlateEditorValue.current);
        const findIndex = tempDocItemList.findIndex(item => item.id == docItem.id);
        tempDocItemList.splice(findIndex, 1);
        const newDocItemList = deepCloneV2(tempDocItemList);
        updateCurrentEditorValue(newDocItemList, true);
        onSlateEditorChange(newDocItemList);
    }

    const onDocItemDeleteClick = (docItem: DocItem) => {
        if (docItemDeleteClickTimer.current) {
            clearTimeout(docItemDeleteClickTimer.current)
        }
        docItemDeleteClickTimer.current = setTimeout(() => {
            // console.log("docItem: DocItem--->", docItem)
            modal.confirm({
                title: '温馨提示',
                icon: <ExclamationCircleOutlined />,
                content: '确认删除此图片？',
                okText: '确认',
                cancelText: '取消',
                centered: true,
                onOk: () => confirmToDeleteDocItem(docItem),
                okButtonProps: { loading: deleteLoading }
            });
        }, 200);
    }

    const onSlateInputDataChanged = (inputData: Record<string, any>) => {
        let _tempSlateEditorValue = tempSlateEditorValue.current;
        let newSlateEditorValue = mergeNewSlateEditorInputValueToDocItemList(
            inputData,
            _tempSlateEditorValue,
        );
        updateComDocInstanceBySlateEditor(newSlateEditorValue, inputData);
    }

    const updateComDocInstanceBySlateEditor = (
        newSlateEditorValue: DocItem[],
        newSlateEditorInputData?: Record<string, any>
    ) => {
        const {
            newDocInstance,
            newDocItemList,
            needForceUpdate
        } = translateSlateEditorDataToDocInstance(
            deepCloneV2(tempDocInstance.current),
            //新的
            newSlateEditorValue,
            //旧的
            deepCloneV2(tempSlateEditorValue.current),
            newSlateEditorInputData,
        );
        updateCurrentEditorValue(newDocItemList);
        let _newDocInstance = deepCloneV2(newDocInstance);
        tempDocInstance.current = _newDocInstance;
        forceUpdateNextTime.current = true;
        if (needForceUpdate) {
            setCurrentDocInstance(deepCloneV2(_newDocInstance))
        }
        handleUpdateComDocInstance(_newDocInstance);
    };

    const onEditorContentScroll = () => {
        if (!slateEditorIsRefreshing.current) {
            const editorContentEl = scrollContentRef.current;
            slateEditorScrollTop.current = editorContentEl.scrollTop;
        }
    }

    /**
     * 插入章节到光标段落
     * @param docItemListFragment 
     * @param lastSubTable 
     */
    const handleInsertDocItemToCursorPosition = (
        params: { 
            docItemListFragment: DocItem[], 
            docItemListAdditional: DocItem[], 
            docItemGroupList: any[],
            needFillSpeciesLatinName: boolean
        }
    ) => {
        const { 
            docItemListFragment, 
            docItemListAdditional, 
            docItemGroupList,
            needFillSpeciesLatinName
        } = params;
        const {
            docExtraConfigInfo : {
                plantSpeciesInfoList = [],
                animalSpeciesInfoList = [],
                speciesInfoList = []
            }
        } = comDocConfig;
        if (docItemGroupList && docItemGroupList.length) {
            handleInsertDocItemByTargetTopicName(docItemGroupList, docItemListAdditional)
        } else {
            const currentDocItem = cursorLocatedDocItem.current;
            console.log("当前聚焦的段落是--->", currentDocItem);
            if (!currentDocItem) {
                return toastShort("error", "没有聚焦的段落，无法完成插入附表文本内容！")
            }
            let tempDocItemList: DocItem[] = deepCloneV2(tempSlateEditorValue.current);
            //@ts-ignore
            let findTargetDocItemTextTopicId = currentDocItem.id.split('-')[0];
            docItemListFragment.forEach(item => {
                //@ts-ignore
                item.id = findTargetDocItemTextTopicId + '-INSERT_P';
            })
            const findTargetIndex = findTargetIndexInArrayListById(currentDocItem.id, tempDocItemList);
            if (findTargetIndex !== -1) {
                tempDocItemList.splice(findTargetIndex + 1, 0, ...docItemListFragment);
            }
            tempDocItemList.forEach(item => {
                const findIndex = docItemListAdditional.findIndex(ele => ele.id == item.id);
                if (findIndex > -1) {
                    item = deepCloneV2(docItemListAdditional[findIndex]);
                    docItemListAdditional.splice(findIndex, 1);
                }
            })
            tempDocItemList = tempDocItemList.concat(docItemListAdditional);
            // 暂时先不填充了
            // if(needFillSpeciesLatinName){
            //     const mergedSpeciesInfoList = mergeSpeciesInfoList([
            //         speciesInfoList,
            //         plantSpeciesInfoList,
            //         animalSpeciesInfoList
            //     ])
            //     // v1
            //     // const newDocItemList = fillSpeciesNameInfoForSlateEditor([...currentSlateEditorValue], speciesInfoList);
            //     // v2
            //     // const newDocItemList = fillSpeciesLatinName([...currentSlateEditorValue], speciesInfoList);
            //     // v3 (正确填充科名、 拉丁名填充、动物附表、植物附表三者全局联动处理)
            //     tempDocItemList = fillSpeciesLatinNameV3([...currentSlateEditorValue], mergedSpeciesInfoList);
            // }
            docEditorRef.current.forceUpdate();
            const newDocItemList = deepCloneV2(tempDocItemList);
            updateCurrentEditorValue(newDocItemList, true);
            onSlateEditorChange(newDocItemList);
        }
    }

    /**
     * 拉丁名填充
     * @param speciesInfoList 
     */
    const handleFillSpeciesInfoList = (
        speciesInfoList: SpeciesInfoType[]
    ) => {
        const {
            docExtraConfigInfo : {
                plantSpeciesInfoList = [],
                animalSpeciesInfoList = [],
            }
        } = comDocConfig;
        const mergedSpeciesInfoList = mergeSpeciesInfoList([
            speciesInfoList,
            plantSpeciesInfoList,
            animalSpeciesInfoList
        ])
        // v1
        // const newDocItemList = fillSpeciesNameInfoForSlateEditor([...currentSlateEditorValue], speciesInfoList);
        // v2
        // const newDocItemList = fillSpeciesLatinName([...currentSlateEditorValue], speciesInfoList);
        // v3 (正确填充科名、 拉丁名填充、动物附表、植物附表三者全局联动处理)
        const newDocItemList = fillSpeciesLatinNameV3([...currentSlateEditorValue], mergedSpeciesInfoList);
        updateCurrentEditorValue(deepCloneV2(newDocItemList), true);
    }

    /**
     * 插入段落到指定topic下面
     * 给每一个段落都指定- INSERT
     * @param docItemGroupList 
     */
    const handleInsertDocItemByTargetTopicName = (
        docItemGroupList: { topicName: string, docItemList?: DocItem[], topicListFragment: TopicType[] }[],
        docItemListAdditional?: DocItem[]
    ) => {
        console.log("handleInsertDocItemByTargetTopicName---->", docItemGroupList, docItemListAdditional)
        try {
            let _tempDocInstance = tempDocInstance.current;
            let _tempTopicList = _tempDocInstance.topicList;
            let targetTextTopic: TopicType = null;
            docItemGroupList.forEach(group => {
                const { topicName, topicListFragment = [], docItemList = [] } = group;
                const findTargetTopic = _tempTopicList.find(topic => topic.topicName == topicName);
                let originDocItemList = findTargetTopic.topicSlateDocItemList || [];
                let tempDocItemList: DocItem[] = [];
                if (findTargetTopic) {
                    targetTextTopic = findTargetTopic;
                    if (topicListFragment && topicListFragment.length) {
                        const serialNumber = findTargetTopic.serialNumberByChecked;
                        const titleLevel = Number(findTargetTopic.treeLevel);
                        topicListFragment.forEach((topic, topicIndex) => {
                            tempDocItemList.push({
                                id: `${findTargetTopic.id}-INSERT-${topicIndex}-0`,
                                type: getTitleDocItemType(titleLevel + 2),
                                text: `${serialNumber}.${topicIndex + 1} ${topic.topicName}`
                            })
                            topic.wordParagraphList.forEach((paragraph, paragraphIndex) => {
                                tempDocItemList.push({
                                    id: `${findTargetTopic.id}-INSERT-${topicIndex}-${paragraphIndex + 1}`,
                                    type: DocItemType.P,
                                    text: replaceDocInfo(paragraph.text, _tempDocInstance)
                                })
                            })
                        })
                    } else if (docItemList && docItemList.length) {
                        tempDocItemList = docItemList.map((docItem, docItemIndex) => {
                            docItem.id = `${findTargetTopic.id}-INSERT-0-${docItemIndex + 1}`;
                            return docItem;
                        })
                    }
                    originDocItemList = originDocItemList.filter(ele => !String(ele.id).includes('INSERT'));
                    originDocItemList = originDocItemList.concat(tempDocItemList);
                    let newTopic = findTargetTopic;
                    newTopic.topicSlateDocItemList = originDocItemList;
                } else {
                    toastShort('error', `没有可以插入文本的大纲，请确保是否存在${topicName}的文本大纲`)
                }
            })
            _tempDocInstance.topicList = _tempTopicList;
            const {
                docItemList
            } = translateDocInstanceToSlateEditorData(_tempDocInstance, comDocConfig);
            let tempDocItemList = docItemList;
            let tempDocItemListAdditional = addtionalDocItemListRef.current;
            const needReplaceDocItemList = [];
            const needPushDocItemList = [];
            if (isEmpty(docItemListAdditional)) {
                docItemListAdditional = [];
            }
            tempDocItemListAdditional = tempDocItemListAdditional.filter(docItem => {
                return !docItemListAdditional.map(ele => ele.id).includes(docItem.id);
            })
            tempDocItemListAdditional = tempDocItemListAdditional.concat(docItemListAdditional);
            addtionalDocItemListRef.current = tempDocItemListAdditional.length ? deepCloneV2(tempDocItemListAdditional) : [];
            if (docItemListAdditional && docItemListAdditional.length) {
                tempDocItemListAdditional.forEach(docItem => {
                    const findIndex = tempDocItemList.findIndex(ele => ele.id == docItem.id);
                    if (findIndex > -1) {
                        tempDocItemList.splice(findIndex, 1);
                    }
                })
                console.log("tempDocItemListAdditional--->", tempDocItemListAdditional)
                tempDocItemList = tempDocItemList.concat(deepCloneV2(tempDocItemListAdditional));
            }
            docEditorRef.current.forceUpdateV2();
            updateCurrentEditorValue([...tempDocItemList], true);
            tempDocInstance.current = _tempDocInstance;
            // autoScrollToTargetTopic(targetTextTopic, 200);
            autoScrollToTargetTopic(targetTextTopic, 3000);
            handleUpdateComDocInstance(_tempDocInstance);
        } catch (e) {
            console.error("失败！！！", e)
            toastShort('error', `没有可以插入文本的大纲，请确保是否存在${docItemGroupList[0].topicName}的文本大纲`)
        }
    };

    /**
     * 把指定的图片地址插入到文档中
     * @param imageUrl 
     */
    const handleInsertImageToDocItemList = (imageUrl: string) => {
        const currentDocItem = cursorLocatedDocItem.current;
        if (!currentDocItem) {
            return toastShort("error", "没有聚焦的段落，无法完成插入图片！")
        }
        let findTargetDocItemTextTopicId = currentDocItem.id.toString().split('-')[0];
        let tempDocItemList: DocItem[] = deepCloneV2(tempSlateEditorValue.current);
        const findTargetIndex = findTargetIndexInArrayListById(currentDocItem.id, tempDocItemList);
        const docItemListFragment: DocItem[] = [
            {
                id: findTargetDocItemTextTopicId + '-INSERT_Image-' + getUuid(8),
                text: '图片',
                type: DocItemType.Image,
                externalData: {
                    imageUrl: imageUrl
                }
            }
        ];
        if (findTargetIndex !== -1) {
            tempDocItemList.splice(findTargetIndex + 1, 0, ...docItemListFragment);
        }
        docEditorRef.current.forceUpdate();
        const newDocItemList = deepCloneV2(tempDocItemList);
        updateCurrentEditorValue(newDocItemList, true);
        onSlateEditorChange(newDocItemList);
    }

    const autoScrollToTargetTopic = (textTopic: TopicType, timerNs: number) => {
        // delayScrollToTargetTopicTimer.current && clearTimeout(delayScrollToTargetTopicTimer.current);
        // toastShort('success', '段落插入中，即将为您自动跳转至段落所在章节...')
        setTimeout(() => {
            messageApi.open({
                key: 'autoScrolling',
                type: 'loading',
                content: '段落插入中，即将为您自动跳转至段落所在章节...',
            });
        }, 500);
        // delayScrollToTargetTopicTimer.current = 
        setTimeout(() => {
            let docItemId = `${textTopic.id}-h`;
            const selector = `h1[data-doc-item-id="${docItemId}"], 
            h2[data-doc-item-id="${docItemId}"], 
            h3[data-doc-item-id="${docItemId}"], 
            h4[data-doc-item-id="${docItemId}"], 
            h5[data-doc-item-id="${docItemId}"], 
            h6[data-doc-item-id="${docItemId}"]`
            // console.log("docItemId--->", docItemId)
            const blockEl: HTMLBaseElement = document.querySelector(selector);
            scrollContentRef.current.scrollTop = blockEl.offsetTop || 0;
            messageApi.open({
                key: 'autoScrolling',
                type: 'success',
                content: '已跳转',
                duration: 1,
            });
        }, timerNs);
    }

    const handleChangeBackgroundParagraphV2 = (params) => {
        console.log("切换范文v2---->", params)
    };

    const handleChangeBackgroundParagraph = (params) => {
        const {
            index,
            textTopicId,
            backgroundText,
        } = params;
        let _tempDocInstance = tempDocInstance.current;
        let _tempTopicList = _tempDocInstance.topicList;
        const newDocItem: DocItem = {
            id: `${textTopicId}-p`,
            text: backgroundText,
            type: DocItemType.P
        }
        let _tempSlateEditorValue = tempSlateEditorValue.current.map(docItem => {
            if (docItem.id == newDocItem.id) {
                return newDocItem;
            }
            return docItem;
        })
        updateCurrentEditorValue(_tempSlateEditorValue, true);
        //更新index
        for (let i = 0; i < _tempTopicList.length; i++) {
            if (_tempTopicList[i].id == textTopicId) {
                _tempTopicList[i].currentInUseBackgroundParagraphIndex = index;
                let oldDocItemList: DocItem[] = _tempTopicList[i].topicSlateDocItemList;
                let findIndex = oldDocItemList.findIndex(docItem => docItem.type == DocItemType.P)
                if (findIndex > -1) {
                    let filterDocItemList = oldDocItemList.filter(item => {
                        return item.type !== DocItemType.P;
                    })
                    filterDocItemList = insertArrayAtPosition(filterDocItemList, [newDocItem], findIndex);
                    _tempTopicList[i].topicSlateDocItemList = [...filterDocItemList];
                }
                break;
            }
        }
        _tempDocInstance.topicList = deepCloneV2(_tempTopicList);
        tempDocInstance.current = _tempDocInstance;
        handleUpdateComDocInstance(_tempDocInstance);
    }

    const handleSetFocusDocItem = (docItem: DocItem) => {
        try {
            let wordEditorFocusTrackLineHeight = 0;
            let wordEditorFocusTrackLineOffsetTop = 0;
            let elementTag = '';
            switch (docItem.type) {
                case DocItemType.H1:
                    elementTag = 'h1';
                    break;
                case DocItemType.H2:
                    elementTag = 'h2';
                    break;
                case DocItemType.H3:
                    elementTag = 'h3';
                    break;
                case DocItemType.H4:
                    elementTag = 'h4';
                    break;
                case DocItemType.H5:
                    elementTag = 'h5';
                    break;
                case DocItemType.H6:
                    elementTag = 'h6';
                    break;
                case DocItemType.P:
                case DocItemType.CP:
                    elementTag = 'p';
                    break;
            }
            const docItemIdQuerySelector = `${elementTag}[data-doc-item-id="${docItem.id}"]`;
            const blockEl = document.querySelector(docItemIdQuerySelector);
            //@ts-ignore
            const blockOffsetTop = blockEl.offsetTop;
            if (wordEditorFocusTrackLineOffsetTop == 0) {
                wordEditorFocusTrackLineOffsetTop = blockOffsetTop;
            }
            wordEditorFocusTrackLineHeight += blockEl.clientHeight;
            scrollContentRef.current.scrollTo({
                top: wordEditorFocusTrackLineOffsetTop,
                behavior: 'smooth',
            });
            wordEditorFocusTrackLineRef.current.style.height = wordEditorFocusTrackLineHeight + 'px';
            wordEditorFocusTrackLineRef.current.style.top = wordEditorFocusTrackLineOffsetTop + 'px';
        } catch (e) {
            toastShort('warning', '无法自动聚焦文本段落')
        }
    }

    const updateCurrentEditorValue = (newDocItemList: DocItem[], forceUpdate?: boolean) => {
        console.log("updateCurrentEditorValue--->", newDocItemList)
        if (forceUpdate) {
            setCurrentSlateEditorValue([]);
            setTimeout(() => {
                freshSlateEditorValue.current = newDocItemList;
                tempSlateEditorValue.current = deepCloneV2(newDocItemList);
                setCurrentSlateEditorValue(newDocItemList);
            }, 200);
        } else {
            freshSlateEditorValue.current = newDocItemList;
            tempSlateEditorValue.current = deepCloneV2(newDocItemList);
            setCurrentSlateEditorValue(newDocItemList);
        }
    }

    const getWordExportParams = () => {
        const docItemList: DocItem[] = deepCloneV2(tempSlateEditorValue.current);
        docItemList.forEach(docItem => {
            // if (docItem.type == DocItemType.TABLE) {
            //     const findTableInfo = addtionalDocItemListRef.current?.find(ele => {
            //         ele.id == docItem.id;
            //     })
            //     if (!docItem.externalData && findTableInfo) {
            //         docItem.externalData = findTableInfo.externalData
            //     }
            // }
            if (checkIsSlaterEditorTextParagraph(docItem)) {
                console.log("docItem--->", docItem)
                // if (docItem.textStyleMap) {
                //     let filterTextStyleMap = {};
                //     Object.keys(docItem.textStyleMap).forEach((key, index) => {
                //         if (key.includes('$$')) {
                //             if (Number(key.replace('$$', '').replace('[', '').replace(']', '')) >= 99999) {
                //                 filterTextStyleMap[key] = docItem.textStyleMap[key]
                //             }
                //         }
                //     })
                //     docItem.textStyleMap = {...filterTextStyleMap}
                // }
                if (docItem.textStyleMap) {
                    let filterTextStyleMap = {};
                    Object.keys(docItem.textStyleMap).forEach((key, index) => {
                        if (key.includes('$$')) {
                            if(docItem.text.includes(key)){
                                filterTextStyleMap[key] = docItem.textStyleMap[key]
                            }
                        }
                    })
                    docItem.textStyleMap = { ...filterTextStyleMap }
                }
            }
        })
        const exportWordParams = {
            docItemList: processDocItemListForExport(docItemList)
        };
        console.log("exportWordParams---->", JSON.stringify(exportWordParams))
        return JSON.stringify(exportWordParams);
    }

    const onWordInsertImageInputChange = (e: any) => {
        console.log("onWordInsertImageInputChange--->", e)
        const file = e.target.files[0];
        if (file && file.size < 1024 * 1024 * 10) {
            wordEditorLoadingRef.current.showLoading();
            let formData = new FormData();
            console.log("file---->", file);
            formData.append('file', file);
            const instance = axios.create();
            instance.post(
                saasConfig.nodeJsApiBaseUrl + '/shared_folder/base_file_upload',
                formData,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
                .then(res => {
                    console.log("res.data.url--->", res)
                    const imageUrl = res.data.data.url;
                    wordEditorLoadingRef.current.hideLoading();
                    if (imageUrl) {
                        handleInsertImageToDocItemList(imageUrl);
                    } else {
                        toastShort('error', '图片上传失败，请稍后重试！')
                    }

                })
                .catch(err => {
                    wordEditorLoadingRef.current.hideLoading();
                    console.log("上传图片失败----->", err)
                    toastShort('error', '图片上传失败，请稍后重试！')
                })
        } else {
            return toastShort('error', '上传图片最大不超过10M')
        }
        e.target.value = null;
        e.target.files = [];
    }

    return (
        <div
            ref={containerRef}
            id="WordExitorV3ContainerId"
            className='word-edit-area-container content-border content-border-radius'
        >
            {/* <button onClick={getWordExportParams}>测试导出</button> */}
            <div
                ref={scrollContentRef}
                className={'flex-col word-edit-area-content'}
                onScroll={onEditorContentScroll}
            >
                <div
                    ref={wordEditorFocusTrackLineRef}
                    className="word-editor-focus-track-line"
                ></div>
                <div
                    ref={wordEditorHighLightTrackLineRef}
                    className="word-editor-high-light-track-line"
                ></div>
                {
                    currentDocInstance && currentSlateEditorValue.length ?
                        <DocEditor
                            ref={docEditorRef}
                            value={currentSlateEditorValue}
                            data={slateEditorBaseData}
                            onChange={onSlateEditorChange}
                            onDocItemClick={onDocItemClick}
                            onDocItemDeleteClick={onDocItemDeleteClick}
                            onSlateInputDataChanged={onSlateInputDataChanged}
                        />
                        :
                        null
                }
            </div>
            <WordEditorContextMenu
                ref={wordEditorContextMenuRef}
                onWordEditorContextMenuClose={onWordEditorContextMenuClose}
            />
            <LoadingWapper
                ref={wordEditorLoadingRef}
                title={'正在处理中'}
                autoControl={false}
            />
            <input
                type={'file'}
                id="wordInsertImageInput"
                style={{ display: 'none' }}
                accept='.png,.jpeg,.jpg'
                onChange={onWordInsertImageInputChange}
            />
            {contextHolder}
            {modalContextHolder}
        </div>
    )
}

export default forwardRef(WordEditorV3);