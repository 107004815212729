import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CustomPopover from './CustomPopover';
import './custom-popover.scss';
import { UserType } from '../../utils/types';
import redux from './../../redux/store';
import UserAvatar from '../user-center/UserAvatar';
import { ArrowRight, HeadsetOne, Helpcenter, Info, Log, Logout, Navigation, Right, RightAngle } from '@icon-park/react';
import { DocSettingConfigParams, DocSettingMenuType } from '../../types';
import { adaptUserAccountType } from '../../utils';
import { Switch } from 'antd';
import { saasConfig } from '../../config/theme-config';

interface Props {
    clientX: number
    clientY: number
    onSelect?: (type: DocSettingMenuType, configParams: DocSettingConfigParams) => void
}

const DocInstanceSettingPopover = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        openPopover
    }))

    const {
        clientX,
        clientY,
        onSelect
    } = props;

    const [userInfo, setuserInfo] = useState<UserType>(redux.getState().systemConfig.userInfo);
    const [isSaveLoginAccountCache, setIsSaveLoginAccountCache] = useState(true);

    const customSelectRef = useRef<any>(null);

    const openPopover = () => {
        customSelectRef.current.show();
    };

    const handleSelect = (type: DocSettingMenuType) => {
        customSelectRef.current.hide();
        onSelect && onSelect(type, { isSaveLoginAccountCache })
    };

    return (
        <CustomPopover
            ref={customSelectRef}
            clientX={clientX}
            clientY={clientY}
            contentRender={(props) => (
                <div className='doc-setting-popover'>
                    <div className='flex-row doc-setting-user-info'>
                        <UserAvatar size={52} />
                        <div
                            className='flex-col'
                            style={{
                                paddingLeft: 12,
                                height: 52,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                            }}
                        >
                            <div style={{ fontSize: 16, marginTop: 8, fontWeight: '700' }}>{userInfo.nickName}</div>
                            <div className='flex-row' style={{ fontSize: 13, color: '#555', height: 32, alignItems: 'center' }}>
                                {
                                    saasConfig.systemType !== 'YunNanLinGuiYuan' ?
                                        <>
                                            <div>{adaptUserAccountType(userInfo)}</div>
                                            <div style={{ height: 12, width: 1, backgroundColor: '#909399', marginLeft: 7, marginRight: 5 }}></div>
                                        </>
                                        :
                                        null
                                }
                                <div
                                    className='flex-row'
                                    style={{ height: 24, padding: 5, cursor: 'pointer' }}
                                    onClick={() => handleSelect('UserCenter')}
                                >
                                    {'账号管理'}<Right />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='doc-setting-item-row-border'></div>
                    <div
                        className='flex-row doc-setting-item-row'
                        onClick={() => handleSelect('DocBaseInfo')}
                    >
                        <div className='flex-row doc-setting-item-row-left'>
                            <div className='doc-setting-item-row-icon'>
                                <Info theme="outline" size="18" fill="#303133" />
                            </div>
                            <div className='doc-setting-item-row-title'>基本信息</div>
                        </div>
                    </div>
                    {
                        saasConfig.systemType == 'HuaWeiTestServer' ?
                            <>
                                <div
                                    className='flex-row doc-setting-item-row'
                                    onClick={() => handleSelect('VersionList')}
                                >
                                    <div className='flex-row doc-setting-item-row-left'>
                                        <div className='doc-setting-item-row-icon'>
                                            <Log theme="outline" size="18" fill="#303133" />
                                        </div>
                                        <div className='doc-setting-item-row-title'>版本记录</div>
                                    </div>
                                </div>
                                <div className='doc-setting-item-row-border'></div>
                            </>
                            :
                            null
                    }
                    <div
                        className='flex-row doc-setting-item-row'
                        onClick={() => handleSelect('ServiceModal')}
                    >
                        <div className='flex-row doc-setting-item-row-left'>
                            <div className='doc-setting-item-row-icon'>
                                <HeadsetOne theme="outline" size="18" fill="#303133" />
                            </div>
                            <div className='doc-setting-item-row-title'>人工客服</div>
                        </div>
                    </div>
                    {
                        saasConfig.systemType === 'YunNanLinGuiYuan' ?
                            null
                            :
                            <div
                                className='flex-row doc-setting-item-row'
                                onClick={() => handleSelect('HelpCenter')}
                            >
                                <div className='flex-row doc-setting-item-row-left'>
                                    <div className='doc-setting-item-row-icon'>
                                        <Helpcenter theme="outline" size="18" fill="#303133" />
                                    </div>
                                    <div className='doc-setting-item-row-title'>使用帮助</div>
                                </div>
                            </div>
                    }
                    {
                        saasConfig.systemType !== 'YunNanLinGuiYuan' ?
                            <div
                                className='flex-row doc-setting-item-row'
                                onClick={() => handleSelect('UserGuide')}
                            >
                                <div className='flex-row doc-setting-item-row-left'>
                                    <div className='doc-setting-item-row-icon'>
                                        {/* <Helpcenter theme="outline" size="18" fill="#303133" /> */}
                                        <Navigation theme="outline" size="18" fill="#303133" />
                                    </div>
                                    <div className='doc-setting-item-row-title'>新手教程</div>
                                </div>
                                <div className='flex-row doc-setting-item-row-right'>
                                    随时学习操作流程
                                </div>
                            </div>
                            :
                            null
                    }
                    <div
                        className='flex-row doc-setting-item-row'
                        onClick={() => handleSelect('FeedBack')}
                    >
                        <div className='flex-row doc-setting-item-row-left'>
                            <div className='doc-setting-item-row-icon'>
                                {/* <Helpcenter theme="outline" size="18" fill="#303133" /> */}
                                <img src={require('./../../assets/image/feed-back-icon-dark.png')} style={{ width: 18 }} />
                            </div>
                            <div className='doc-setting-item-row-title'>问题反馈</div>
                        </div>
                    </div>
                    <div className='doc-setting-item-row-border'></div>
                    <div
                        className='flex-row doc-setting-item-row'
                    >
                        <div
                            className='flex-row doc-setting-item-row-left'
                            onClick={() => handleSelect('LoginOut')}
                        >
                            <div className='doc-setting-item-row-icon'>
                                <Logout theme="outline" size="18" fill="#cf1322" />
                            </div>
                            <div className='doc-setting-item-row-title' style={{ color: '#cf1322' }}>退出登录</div>
                        </div>
                        <div
                            className='flex-row doc-setting-item-row-right'
                        >
                            <Switch
                                size={'small'}
                                value={isSaveLoginAccountCache}
                                onChange={(e) => setIsSaveLoginAccountCache(e)}
                            />
                            <div className='notice-text'>保留登录信息</div>
                        </div>
                    </div>
                </div>
            )}
        />
    )
}


export default forwardRef(DocInstanceSettingPopover);