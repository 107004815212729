import React, {
  forwardRef, useState, useImperativeHandle,
  ChangeEvent, useRef, useEffect,
} from "react";
import {
  Modal, Input, Space, Form, Select,
  Row,
  Col,
  Tabs,
  Upload,
  UploadProps,
  Button,
  Divider,
  ConfigProvider,
  Image,
  Tooltip
} from "antd";
import * as XLSX from "xlsx";
import {
  CaretRightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  checkIsTempAccount,
  comDownloadTemplate,
  deepCloneV2, deepCopy, defaultProjectSubTableList, filterAndSortProtectLandSubTypeList, filterAndSortProtectLandTypeList, formatYear,
  getChangeEventValue, getLocalDefaultFormData, handleDownloadByName, handleDownloadByNameV2, handleJumpEditPage, isEmpty, provinceList, replaceUnitUpperCase, setLocalDefaultFormData, toastShort,
} from "../../../utils";
import systemApi from "../../../api/system";
import {
  DocInstance,
  ProtectedNaturalEnvironmentType,
  ProtectlandBaseInfoType,
  ProtectlandExtraInfoType,
  ProtectlandNameInfo,
  ProtectlandType,
  SearchSelectResult,
  SelectOption,
  SupplementaryInformationType,
  TopicTemplateType,
} from "../../../utils/types";
import ProtectlandDetailsModal from "./ProtectlandDetailsModal";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingWapper from "../../../components/loading/LoadingWapper";
import { protectlandNameList } from "../../../utils/data";
import SearchSelect from "../../../components/select/SearchSelect";
import { saasConfig } from "../../../config/theme-config";
import { Close, CloseOne, FileWord } from "@icon-park/react";
import { TypeProjectSubTable } from "../../../types";
import {
  subTableWorkSheet1Template,
  subTableWorkSheet1Template1,
  subTableWorkSheet2Template,
  subTableWorkSheet2Template1,
  subTableWorkSheet3Template,
  subTableWorkSheet4Template,
  subTableWorkSheet5Template,
  subTableWorkSheet6Template,
} from "./../../office-lib/word-editor-plugins/WorkSheetTemplate";
import DeviceNodeManageModal from "../../office-lib/topic-sider-bar/sider-modal/DeviceNodeManageModal";
import { defaultProjectFormData } from "../../../utils/default-local-item-data";

interface Props {
  onFinish?: Function;
}

const { TextArea } = Input;

const { Option } = Select;

const { Dragger } = Upload;

const AddProjectModal = (props: Props, ref: any) => {
  useImperativeHandle(ref, () => ({
    openModal: openModal,
  }));

  const { onFinish } = props;
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<ProtectlandBaseInfoType>();

  //
  const [formData, setFormData] = useState<ProtectlandBaseInfoType>({});

  //这里是初始化时候的默认表单配置，在每次打开弹窗时，还需要更新
  const [defaultFormData] = useState<ProtectlandBaseInfoType>(getLocalDefaultFormData() || defaultProjectFormData);

  const [startYearOptions, setStartYearOptions] = useState<SelectOption[]>([]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentRowData, setCurrentRowData] = useState<ProtectlandBaseInfoType>({});
  const [getDataLoading, setGetDataLoading] = useState(true);
  const [protectlandTypeList, setProtectlandTypeList] = useState<
    Array<ProtectlandType>
  >([]);
  const [currentProtectlandSubTypeList, setCurrentProtectlandSubTypeList] =
    useState<ProtectlandType[]>();
  const [loading, setLoading] = useState(false);
  const [endYearOptions, setEndYearOptions] = useState<SelectOption[]>([]);
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const [currentTypeProtectLandList, setCurrentTypeProtectLandList] = useState<
    SelectOption[]
  >([]);
  const [protectlandNameInfoList, setProtectlandNameInfoList] = useState<ProtectlandNameInfo[]>([]);

  const protectlandDetailsModalRef = useRef<any>(null);

  const delaySetInitalProtectlandTimer = useRef(null);

  const [supplementaryInformation, setSupplementaryInformation] = useState<SupplementaryInformationType>({
    geographicalLocationText: "",
    rangeText: "",
    historicalEvolutionText: "",
    legalStatusText: "",
    mainProtectionObjecText: "",
    administrativeDivisionText: "",
    landOwnershipText: "",
    geologyAndGeomorphologyText: "",
    climateText: "",
    hydrologyText: "",
    soilText: "",
    vegetationText: "",
    fungusText: "",
    landscapeResourcesText: "",
    naturalDisasterText: "",
  })

  const [protectedNaturalEnvironment, setProtectedNaturalEnvironment] = useState<ProtectedNaturalEnvironmentType>({
    geographicalLocationText: "",
    climateText: ""
  })

  const [projectSubTableList, setProjectSubTableList] = useState<TypeProjectSubTable[]>(defaultProjectSubTableList);

  const [isExpanded, setIsExpanded] = useState(true);

  const [fileName, setFileName] = useState('');

  const [isOpenExtraInfo, setIsOpenExtraInfo] = useState(false);

  const areaInputRef = useRef(null);

  const handleFocus = () => {
    areaInputRef.current.select();
  }
  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      setFormData(deepCloneV2(defaultFormData));
      setCurrentRowData({});
    }
  }, [isModalOpen]);

  useEffect(() => {
    let tempStartYearOptions: SelectOption[] = [];
    const startYear = new Date().getFullYear() - 1;
    let tempEndYearOptions: SelectOption[] = [];
    for (let i = 0; i < 50; i++) {
      tempStartYearOptions.push({
        label: `${startYear + i}年`,
        value: `${startYear + i}`,
      })
      tempEndYearOptions.push({
        label: `${i + 1}年`,
        value: `${i + 1}`,
      });
    }
    setStartYearOptions(tempStartYearOptions);
    setEndYearOptions(tempEndYearOptions);
    let protectlandTypeProtectLandOptions = [];
    protectlandNameList.forEach((ele) => {
      ele.children.forEach((ele) => {
        protectlandTypeProtectLandOptions.push({
          label: ele.name,
          value: ele.name,
        });
      });
    });
    setCurrentTypeProtectLandList([...protectlandTypeProtectLandOptions]);
    //查询保护地名录信息
    systemApi.getProtectlandNameInfoList({ page: 1, size: 9999, })
      .then(res => {
        console.log("保护地名录信息", res)
        res.data.list = res.data.list.map(ele => {
          ele.label = ele.protectlandName;
          return ele;
        })
        setProtectlandNameInfoList(res.data.list);
      })
      .catch(err => {

      })
    queryProtectlandTypeList();
    return () => {
      delaySetInitalProtectlandTimer.current && clearTimeout(delaySetInitalProtectlandTimer.current);
    }

  }, []);

  const openModal = (rowData: DocInstance, isCopyMode?: boolean) => {
    if (isCopyMode) {
      setIsCopy(isCopyMode);
    }
    setIsModalOpen(true);
    const { keys, values } = Object;
    setIsOpenExtraInfo(false);
    if (!isEmpty(rowData)) {
      rowData = deepCopy(rowData);
      const { protectlandBaseInfo, protectlandTypeId } = rowData;
      const { type, subType } = protectlandBaseInfo;
      protectlandBaseInfo.yearRange = [];
      let _formData = deepCopy(protectlandBaseInfo);
      _formData.protectlandExtraInfo = rowData.protectlandBaseInfo.protectlandExtraInfo
      _formData.type = type;
      _formData.protectlandName = rowData.protectlandBaseInfo.protectlandName;
      _formData.subType = subType;
      const keyList = keys(_formData);
      const valueList = values(_formData);
      _formData.endYearStep = (
        Number(formatYear(_formData.endYear)) -
        Number(formatYear(_formData.startYear)) +
        1
      ).toString();
      keyList.forEach((key, index) => {
        if (key == "startYear") {
          form.setFieldValue(key, formatYear(valueList[index]));
        } else if (key == "endYear") {
          form.setFieldValue("endYearStep", _formData.endYearStep);
        } else if (key == 'type' || key == 'subType') {
        } else {
          form.setFieldValue(key, valueList[index]);
        }
      });
      setFormData({ ..._formData });
      queryProtectlandTypeList(subType);
    } else {
      const localFormData: ProtectlandBaseInfoType = getLocalDefaultFormData();
      let _formData = deepCopy(localFormData ? localFormData : defaultFormData);
      form.setFieldsValue(_formData);
      setFormData(_formData);
      queryProtectlandTypeList(localFormData ? localFormData.protectlandTypeId : "6424f622019ebb24cd3d9ea7");
      setTimeout(() => {
        form.setFieldValue(
          "startYear",
          _formData.startYear
        );
        form.setFieldValue(
          "endYearStep",
          _formData.endYearStep
        );
      }, 100);
    }
  };

  const queryProtectlandTypeList = (initTypeId?: string) => {
    setGetDataLoading(true);
    let params = {
      page: 1,
      size: 100,
    };
    systemApi
      .getProtectlandTypeList(params)
      .then((res) => {
        setGetDataLoading(false);
        let protectlandList: ProtectlandType[] = res.data.list;
        //TODO 2024-09-02 移除临时账户的创建权限限制
        // if (checkIsTempAccount()) {
        //   protectlandList.forEach(ele => {
        //     if (
        //       ele.type !== '自然保护区' ||
        //       ele.subType !== '森林生态系统'
        //       || ele.subTypeStr !== '自然保护区森林生态系统'
        //     ) {
        //       ele._disabled = true;
        //     }
        //   })
        // }
        const treeData: ProtectlandType[] = [];
        protectlandList.forEach((ele) => {
          let findNodeIndex = -1;
          treeData.forEach((node, nodeIndex) => {
            if (node.type == ele.type) {
              findNodeIndex = nodeIndex;
            }
          });
          if (findNodeIndex > -1) {
            treeData[findNodeIndex].children.push(ele);
          } else {
            ele.children = [ele];
            treeData.push(ele);
          }
        });
        treeData.forEach((node) => {
          node.label = node.type;
          node.value = node.id;
        });
        if (!isEmpty(initTypeId)) {
          const find = treeData.find((ele) => {
            return ele.id == initTypeId;
          });
          if (find) {
            const subTypeList: ProtectlandType[] = find.children;
            let currentProtectlandSubTypeList = []
            subTypeList.forEach((ele) => {
              let option = {
                label: ele.subType,
                value: ele.id,
                _disabled: ele._disabled
              }
              currentProtectlandSubTypeList.push(option)
            });
            setCurrentProtectlandSubTypeList([...currentProtectlandSubTypeList]);
          } else {
            setCurrentProtectlandSubTypeList([]);
          }
          let tempProtectlandTypeList = treeData.map(ele => {
            let newOption = deepCloneV2(ele);
            newOption.label = ele.type;
            newOption.value = ele.id;
            return newOption;
          });
          console.log("tempProtectlandTypeList--->", tempProtectlandTypeList)
          setProtectlandTypeList(deepCloneV2(tempProtectlandTypeList));
          if (!isEmpty(initTypeId)) {
            let protectlandTypeId = null;
            let protectlandSubTypeId = null;
            let protectlandSubTypeList = [];
            treeData.forEach(typeGroup => {
              typeGroup.children.forEach(info => {
                if (info.id == initTypeId) {
                  protectlandTypeId = typeGroup.id;
                  protectlandSubTypeId = info.id;
                  protectlandSubTypeList = deepCloneV2(typeGroup.children);
                }
              })
            })
            const currentProtectlandSubTypeList = protectlandSubTypeList.map(ele => {
              let newOption = {
                label: ele.subType,
                value: ele.id,
                _disabled: ele._disabled
              }
              return newOption;
            })
            delaySetInitalProtectlandTimer.current = setTimeout(() => {
              form.setFieldValue('type', protectlandTypeId)
              form.setFieldValue('subType', protectlandSubTypeId);
              setCurrentProtectlandSubTypeList([...currentProtectlandSubTypeList])
            }, 200);
          }
        } else {
          setProtectlandTypeList([...treeData]);
        }
      })
      .catch((err) => {
      });
  };

  const handleSetFormData = (target: string, value: any) => {
    let _formData = formData;
    //@ts-ignore
    _formData[target] = value;
    setFormData(deepCopy(_formData));
  };

  const checkSupplementaryInformationIsEmpty = () => {
    let result = false;
    Object.values(value => {
      if (isEmpty(value)) {
        result = true;
      }
    })
    return result;
  };

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();
      let params: ProtectlandBaseInfoType = formData;
      if (currentRowData && currentRowData.id) {
        params.id = currentRowData.id;
      }
      if (isEmpty(formData.startYear) || isEmpty(formData.endYear)) {
        return toastShort("error", "请选择规划年限");
      }
      if (isEmpty(formData.topicTemplateId)) {
        return toastShort("error", "大纲模版不能为空");
      }
      // if (checkSupplementaryInformationIsEmpty()) {
      //   return toastShort("error", "保护地基本信息不能为空");
      // }
      setSubmitLoading(true);
      let projectExtraInfo: ProtectlandExtraInfoType = {
        projectSubTableList: projectSubTableList,
        supplementaryInformation: supplementaryInformation,
        protectedNaturalEnvironment: protectedNaturalEnvironment,
      }
      params.protectlandExtraInfo = projectExtraInfo;
      setLocalDefaultFormData(formData);
      systemApi
        .addProtectlandBaseInfo(params)
        .then((res) => {
          let createProjectParams: DocInstance = {
            name: params.protectlandName + "总体规划",
            documentType: "master",
            hasSlaveDocument: false,
            masterDocumentId: "",
            protectlandTypeId: params.protectlandTypeId,
            documentGroupId: "",
            protectedBaseInfoId: res.data.id,
            topicTemplateId: formData.topicTemplateId,
            teamList: [],
            sort: "1",
            sectionalizationId: "6466d7c7d82ed22082e284e3",
            status: "1",
          };
          const sectionalizationId = searchParams.get("sectionalization-id");
          if (!isEmpty(sectionalizationId)) {
            createProjectParams.sectionalizationId = sectionalizationId;
          }
          systemApi
            .addProject(createProjectParams)
            .then((res) => {
              onFinish && onFinish();
              setIsModalOpen(false);
              handleResetModal();
              setSubmitLoading(false);
              const { pathname, search } = routerLocation;
              localStorage.setItem("formEditPagePath", pathname + search);
              handleJumpEditPage(
                "/edit?docId=" + res.data.id + "&screen=topic&sidetab=allTopic"
              );
            })
            .catch((err) => {
              // console.log("err", err);
              setSubmitLoading(false);
            });
        })
        .catch((err) => {
          console.log("err", err);
          setSubmitLoading(false);
        });
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleResetModal = () => {
    setFormData(defaultFormData);
    form.resetFields();
    setSubmitLoading(false);
  };

  const onProtectlandAreaUnitChange = (e: string) => {
    handleSetFormData("protectlandAreaUnit", e);
  }

  const areaUnitListSelect = (
    <Select defaultValue="hm2" onChange={onProtectlandAreaUnitChange}>
      <Option value="hm2">公顷</Option>
      <Option value="km2">k㎡</Option>
    </Select>
  );

  const onTypeChange = (value: string) => {
    const find = protectlandTypeList.find((ele) => {
      return ele.id == value;
    });
    //TODO 2024-09-02 移除临时账户的创建权限限制
    // if (find.subTypeStr !== '自然保护区森林生态系统' && checkIsTempAccount()) {
    //   form.setFieldValue("type", null)
    //   return toastShort('error', '当前账号无权限创建此类保护地规划报告')
    // }
    if (find) {
      const subTypeList: ProtectlandType[] = [...find.children];
      const findCurrentTypeProtectLand = protectlandNameList.find((ele) => {
        return ele.type == find.label;
      });
      let protectlandTypeProtectLandOptions = [];
      if (findCurrentTypeProtectLand && findCurrentTypeProtectLand.children) {
        findCurrentTypeProtectLand.children.forEach((ele) => {
          protectlandTypeProtectLandOptions.push({
            label: ele.name,
            value: ele.name,
            //@ts-ignore
            _disabled: ele._disabled
          });
        });
      }
      setCurrentTypeProtectLandList([...protectlandTypeProtectLandOptions]);
      let _subTypeList = subTypeList.map((ele) => {
        let option = {
          label: ele.subType,
          value: ele.id,
          _disabled: ele._disabled
        };
        option = {
          ...ele,
          ...option,
        };
        return option;
      });
      setCurrentProtectlandSubTypeList(_subTypeList);
    } else {
      setCurrentProtectlandSubTypeList([]);
    }
    handleSetFormData("type", value);
    handleSetFormData("subType", "");
    form.setFieldValue("subType", null);
  };

  const onSubTypeChange = (value: string) => {
    //TODO 2024-09-02 移除临时账户的创建权限限制
    // if (value !== '6424f622019ebb24cd3d9ea7' && checkIsTempAccount()) {
    //   form.setFieldValue("protectlandTypeId", null)
    //   form.setFieldValue("subType", null)
    //   return toastShort('error', '当前账号无权限创建此类保护地规划报告')
    // }
    handleSetFormData("protectlandTypeId", value);
    handleSetFormData("subType", value);
  };

  //自动查询保护地模板
  useEffect(() => {
    if (!isEmpty(formData.type) && !isEmpty(formData.subType)) {
      try {
        let typeStr = "";
        let subTypeStr = "";
        protectlandTypeList.forEach((ele) => {
          if (ele.id == formData.type) {
            typeStr = ele.type;
          }
        });
        currentProtectlandSubTypeList.forEach((ele) => {
          if (ele.value == formData.subType) {
            subTypeStr = ele.label;
          }
        });
        const params = {
          subTypeStr: typeStr + subTypeStr,
        };
        systemApi
          .getProtectlandTypeTopicTemplateList(params)
          .then((res) => {
            let options: SelectOption[] = [];
            if (res.data.list && res.data.list.length) {
              res.data.list.forEach((ele) => {
                options.push({
                  label: ele.name,
                  value: ele.id,
                });
              });
            } else {
              // if (isEmpty(formData.id)) {
              //   toastShort("error", "当前保护地类型没有关联的大纲模版,请选择其它保护地类型");
              // }
            }
            console.log("options---->", options)
            handleSetFormData("topicTemplateId", options[0].value);
          })
          .catch((err) => {
            // toastShort("error", commonErrorMsg);
          });
      } catch (e) { }
    }
  }, [formData.type, formData.subType, currentProtectlandSubTypeList]);

  const onProvinceChange = (value: string) => {
    handleSetFormData("protectlandProvince", value);
  };

  const onProtectlandLevelChange = (value: string) => {
    handleSetFormData("protectlandLevel", value);
  };

  const handleOpenProtectlandDetaildModal = () => {
    protectlandDetailsModalRef.current.openModal(
      formData.protectlandExtraInfo
    );
  };

  const onProtectlandNameTextChange = (e: SearchSelectResult) => {
    const { value, option } = e;
    if (value) {
      form.setFieldValue("protectlandName", value);
      handleSetFormData("protectlandName", value);
      if (option) {
        //@ts-ignore
        let protectlandNameInfo: ProtectlandNameInfo = option;
        // console.log("protectlandNameInfo--->", protectlandNameInfo)
        // console.log("protectlandTypeList--->", protectlandTypeList)
        let findProtectlandInfo = protectlandTypeList.find(protectlandGroup => {
          return protectlandGroup.label === protectlandNameInfo.protectlandType;
        })
        if (findProtectlandInfo) {
          //TODO 2024-09-02 移除临时账户的创建权限限制
          // if (
          //   findProtectlandInfo.subTypeStr !== '自然保护区森林生态系统' &&
          //   checkIsTempAccount()
          // ) {
          //   form.setFieldValue("type", null)
          //   return toastShort('error', '当前账号无权限创建此类保护地规划报告')
          // }
          const findProtectlandSubType = findProtectlandInfo.children.find(ele => ele.subType == protectlandNameInfo.protectlandSubType);
          // console.log("findProtectlandSubType--->", findProtectlandSubType)
          const { area, province } = protectlandNameInfo;
          let belongProtectlandTypeId = findProtectlandInfo ? findProtectlandInfo.id : '';
          let belongProtectlandSubTypeId = findProtectlandSubType ? findProtectlandSubType.id : '';
          setCurrentProtectlandSubTypeList([...findProtectlandInfo.children])
          let belongProtectlandLevel = '';
          let protectlandTypeMatchList = ["国家级", "省级", "市级", "州级", "自治区", "直辖市", "自治县", "县级", "县级市", "旗"];
          protectlandTypeMatchList.forEach((ele, index) => {
            if (protectlandNameInfo.protectlandLevel.includes(ele)) {
              switch (ele) {
                case "国家级":
                  belongProtectlandLevel = "1";
                  break;
                case "省级":
                  belongProtectlandLevel = "2";
                  break;
                case "市级":
                case "州级":
                case "自治区":
                case "直辖市":
                  belongProtectlandLevel = "3";
                  break;
                case "自治县":
                case "县级":
                case "县级市":
                case "旗":
                  belongProtectlandLevel = "4";
                  break;
                default:
                  break;
              }
            }
          })
          if (area) {
            form.setFieldValue("protectlandArea", area);
            handleSetFormData("protectlandArea", area);
          }
          if (province) {
            form.setFieldValue("protectlandProvince", province);
            handleSetFormData("protectlandProvince", province);
          }
          if (belongProtectlandTypeId) {
            form.setFieldValue("type", belongProtectlandTypeId);
            handleSetFormData("type", belongProtectlandTypeId);
            onTypeChange(belongProtectlandTypeId);
          }
          if (belongProtectlandSubTypeId) {
            form.setFieldValue("subType", belongProtectlandSubTypeId);
            handleSetFormData("subType", belongProtectlandSubTypeId);
          }
          if (belongProtectlandLevel) {
            form.setFieldValue("protectlandLevel", belongProtectlandLevel);
            handleSetFormData("protectlandLevel", belongProtectlandLevel);
          }
          form.validateFields()
            .then(res => { })
            .catch(e => { })
        }
      }
    } else {
      form.setFieldValue("protectlandName", "");
      handleSetFormData("protectlandName", "");
    }
  };

  const onProtectlandAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleSetFormData("protectlandArea", getChangeEventValue(e));
  };

  const onPickerProblems = (problemKeys: string) => {
    handleSetFormData("protectlandProblemList", problemKeys);
  };

  const onStartYearChange = (e: any) => {
    // const startYear = formatYear(e);
    const startYear = e;
    // console.log("startYear---->", e)
    handleSetFormData("startYear", startYear);
    handleSetFormData("endYearStep", "10");
    handleSetFormData("endYear", Number(startYear) + 9 + "");
    form.setFieldValue("endYearStep", "10");
  };

  const onEndYearSelectChange = (e: string) => {
    if (isEmpty(formData.startYear)) {
      return toastShort("warning", "请先选择规划基准年份");
    }
    const endYear = (Number(formData.startYear) + (Number(e) - 1)).toString();
    handleSetFormData("endYear", endYear);
  };

  const adaptModalTitle = () => {
    if (isCopy) {
      return "创建规划副本";
      // (创建后不可修改)
    } else {
      return formData.id
        ? "查看-保护地基本信息"
        : "保护地基本信息(必填)";
      // (创建后不可修改)
    }
  };

  const onTopicTemplateChange = (e: string) => {
    handleSetFormData("topicTemplateId", e);
  };

  const onFillProjectDetails = (projectExtraInfo: ProtectlandExtraInfoType) => {
    let _formData = formData;
    _formData.protectlandExtraInfo = projectExtraInfo;
    setFormData({ ..._formData })
  }

  const filterProtectlandInfoList = () => {
    try {
      const { protectlandLevel, protectlandProvince, type, subType } = formData;
      let originProtectlandNameInfoList: ProtectlandNameInfo[] = [...protectlandNameInfoList];
      //筛选级别
      if (protectlandLevel) {
        let keyWords = "";
        switch (protectlandLevel) {
          case "1":
            keyWords = "国家级";
            break;
          case "2":
            keyWords = "省级";
            break;
          case "3":
            keyWords = "市级";
            break;
          case "4":
            keyWords = "县级";
            break;
          default:
            break;
        }
        if (keyWords) {
          originProtectlandNameInfoList = originProtectlandNameInfoList.filter(item => item.protectlandLevel.includes(keyWords))
        }
      }
      if (type) {

        let protectlandTypeName = protectlandTypeList.find(item => item.id == type).type;
        if (protectlandTypeName) {
          originProtectlandNameInfoList = originProtectlandNameInfoList.filter(item => item.protectlandType.includes(protectlandTypeName))
        }
      }
      if (subType) {
        let protectlandSubTypeName = currentProtectlandSubTypeList.find(item => item.value == subType).label;
        if (protectlandSubTypeName) {
          originProtectlandNameInfoList = originProtectlandNameInfoList.filter(item => item.protectlandSubType.includes(protectlandSubTypeName))
        }
      }
      if (protectlandProvince) {
        originProtectlandNameInfoList = originProtectlandNameInfoList.filter(item => item.province.includes(protectlandProvince))
      }
      const protectlandNameInfoOptions: SelectOption[] = originProtectlandNameInfoList.map(ele => {
        //@ts-ignore
        ele.label = ele.protectlandName;
        //@ts-ignore
        ele.value = ele.protectlandName
        return ele;
      })
      return protectlandNameInfoOptions;
    } catch (e) {
      return []
    }
  }


  const uploadActionUrl = `${saasConfig.nodeJsApiBaseUrl}/export-api/tools-service/word-template-base-info-upload`;

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false
  };

  const onFileChange = (e) => {
    console.log("onFileChange--->", e)
    try {
      const fileName = e.file.name.split('.')[0];
      if (e.fileList.length == 0) {
      } else {
        if (e.event && e.event.percent) {
          // console.log("e.event.percent-->", e.event.percent)
        } else if (e.file && e.file.response) {
          const {
            file: {
              response: {
                data
              }
            }
          } = e;
          toastShort('success', '解析成功！')
          let _supplementaryInformation = supplementaryInformation;
          setFileName(fileName);
          //@ts-ignore
          Object.keys(data.baseInfo).forEach((key) => {
            let paragraphText = data.baseInfo[key];
            if (paragraphText == "请填充文本，字数不超过10000字。") {
              paragraphText = "";
            }
            //@ts-ignore
            _supplementaryInformation[key] = replaceUnitUpperCase(paragraphText);
          })
          setSupplementaryInformation({ ..._supplementaryInformation })
        }
      }
    } catch (e) {
      console.log("出错了--->", e)
    }
  };

  const beforeUploadWordFile = (file: any) => {
    if (file.size > 1 * 1024 * 1024) {
      toastShort('error', '上传文件不得大于1M')
      return false;
    }
  }

  const handleDownloadWordTemplate = () => {
    // const downloadUrl1 = 'http://220.163.43.218:9001/ghzs_api/assets/uploads/2024-04-19/4a4050bb-0387-4be0-a049-05f0e2050130保护地基本概况上传模板.docx';
    // const downloadUrl2 = 'https://ghzsapi.zhbhd.cn/assets/uploads/2024-04-09/5fe6c034-5025-464a-8e08-cc6deb51f538c6361d56-d5ea-4d4f-9166-59dad945dd51基本信息采集界面-补充信息上传模板.docx';
    // handleDownloadByNameV2(saasConfig.systemType == 'YunNanLinGuiYuan' ? downloadUrl1 : downloadUrl2, '保护地基本概况上传模板.docx')
    comDownloadTemplate('保护地基本概况上传模板.docx');
  }

  const onSupplementaryInformationTextChange = (e: ChangeEvent<HTMLTextAreaElement>, type: string) => {
    let _supplementaryInformation = supplementaryInformation;
    _supplementaryInformation[type] = replaceUnitUpperCase(getChangeEventValue(e));
    setSupplementaryInformation({ ..._supplementaryInformation })
  }

  const onProtectedNaturalEnvironmentTextChange = (e: ChangeEvent<HTMLTextAreaElement>, type: string) => {
    let _protectedNaturalEnvironment = protectedNaturalEnvironment;
    _protectedNaturalEnvironment[type] = replaceUnitUpperCase(getChangeEventValue(e));
    setProtectedNaturalEnvironment({ ..._protectedNaturalEnvironment })
  }

  const presetWorkSheet = (originWorkSheet: string[][]) => {
    let newWorkSheet: string[][] = [];
    originWorkSheet.forEach(rowList => {
      let newRowList = [];
      rowList.forEach(cellValue => {
        const replacedCellValue = replaceUnitUpperCase(cellValue);
        newRowList.push(replacedCellValue)
      })
      newWorkSheet.push(newRowList)
    })
    return newWorkSheet;
  }

  const processWorkSheet = (workSheet: string[][], currentSubTableType: string): any[][] => {
    let newWorkSheet = presetWorkSheet(workSheet);
    console.log("处理好的workSheet---->", newWorkSheet)
    switch (currentSubTableType) {
      case '1':
        if (
          JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet1Template) &&
          JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet1Template1)
        ) {
          return [];
        }
        break;
      case '2':
        if (JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet2Template1)) {
          return [];
        }
        break;
      case '3':
        if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet3Template)) {
          return [];
        }
        break;
      case '4':
        if (JSON.stringify(newWorkSheet.slice(0, 2)) !== JSON.stringify(subTableWorkSheet4Template)) {
          return [];
        }
        break;
      case '5':
        if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet5Template)) {
          return [];
        }
        break;
      case '6':
        if (JSON.stringify(newWorkSheet.slice(0, 1)) !== JSON.stringify(subTableWorkSheet6Template)) {
          return []
        }
        break;
      default:
        break;
    }
    return newWorkSheet;
  }

  const switchExtraInfoOpened = () => {
    setIsOpenExtraInfo(!isOpenExtraInfo);
  }

  const beforeUpload = (file: any, projectSubTable: TypeProjectSubTable) => {
    console.log("beforeUpload--->", file, projectSubTable)
    let reader = new FileReader()
    reader.readAsBinaryString(file)//读取这个文件
    reader.onload = function (event) {
      try {
        let result = event.target.result
        let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx
        // console.log("xlsxdata.Sheets--->", xlsxdata.Sheets)
        const workSheetNameList: string[] = xlsxdata.SheetNames;
        if (workSheetNameList.length == 0) {
          return toastShort('error', `${projectSubTable.name}无数据，请检查上传文件！`)
        }
        const workSheet: string[][] = XLSX.utils.sheet_to_json(xlsxdata.Sheets[workSheetNameList[0]], { header: 1, defval: '', blankrows: true });
        let processedWorkSheet = processWorkSheet(workSheet, projectSubTable.type);
        if (processedWorkSheet.length == 0) {
          return toastShort("error", `${projectSubTable.name}无法匹配`)
        }
        // console.log("projectSubTable--->", projectSubTable)
        let tempFileName = file.name;
        let _projectSubTableList = projectSubTableList;
        _projectSubTableList[Number(projectSubTable.type) - 1].workSheetFileName = tempFileName;
        //
        if (projectSubTable.type == '6') {
          // console.log("执行---->")
          const speciesNameList = [];
          processedWorkSheet.forEach(rowList => {
            speciesNameList.push(rowList[0])
          })
          systemApi.querySpeciesInfoMutiple({
            names: speciesNameList,
            range: 'all'
          })
            .then(res => {
              if (res.data.list) {
                processedWorkSheet = [...res.data.list];
                _projectSubTableList[Number(projectSubTable.type) - 1].workSheetData = processedWorkSheet;
              }
            })
            .catch(err => {
              console.log("err--->", err)
            })
        } else {
          _projectSubTableList[Number(projectSubTable.type) - 1].workSheetData = processedWorkSheet;
        }
        setProjectSubTableList([..._projectSubTableList]);
      } catch (e) {
        console.log("e", e)
      }
    }
    return false;
  }

  const handleDownLoadTemplate = (info: TypeProjectSubTable) => {
    //@ts-ignore
    comDownloadTemplate(info.fileName)
  }

  const renderProjectSubTable = () => {
    return (
      <div
        id="ProtectlandSubTableId"
        style={{ height: 256, flexWrap: 'wrap' }}
        className="flex-row"
      >
        {
          projectSubTableList.map((ele, index) => [
            <div
              style={{ marginBottom: '18px', width: 290 }}
              key={index + ''}
            >

              <Upload
                {...uploadProps}
                beforeUpload={(file) => beforeUpload(file, ele)}
              >
                <Button
                  type={ele.workSheetFileName ? 'primary' : 'default'}
                  icon={ele.workSheetFileName ? <CheckOutlined /> : <UploadOutlined />}
                  style={{ width: 240 }}
                >
                  {
                    ele.workSheetFileName ? ele.workSheetFileName : `上传${ele.name}`
                  }
                </Button>
              </Upload>
              <div
                className="single-line-text link-text"
                style={{ width: 320 }}
                onClick={() => handleDownLoadTemplate(ele)}
                title={ele.fileName}
              >
                <PaperClipOutlined />
                下载模板-{ele.name}
              </div>
            </div>
          ])

        }
      </div>
    )
  }

  const deleteBaseInfoFile = () => {
    setFileName('');
    setSupplementaryInformation({
      geographicalLocationText: "",
      rangeText: "",
      historicalEvolutionText: "",
      legalStatusText: "",
      mainProtectionObjecText: "",
      administrativeDivisionText: "",
      landOwnershipText: "",
      geologyAndGeomorphologyText: "",
      climateText: "",
      hydrologyText: "",
      soilText: "",
      vegetationText: "",
      fungusText: "",
      landscapeResourcesText: "",
      naturalDisasterText: "",
    })
  }

  const renderSupplementaryInformation = () => {
    return (
      <div
        style={{ height: 256 }}
        id="ProtectlandBaseTextInfoListId"
      >
        <div className='extra-info-upload-box'>
          {
            fileName ?
              <Tooltip
                title={'删除上传的基本概况文档'}
              >
                <div
                  className="extra-cancel-btn"
                  onClick={deleteBaseInfoFile}
                >
                  <Close theme="outline" size="14" fill="#333" />
                </div>
              </Tooltip>
              :
              null
          }
          <Dragger
            {...uploadProps}
            style={{ width: '640px', backgroundColor: fileName ? saasConfig.colorConfig.colorPrimary + '64' : '#fff' }}
            onChange={onFileChange}
            maxCount={1}
            beforeUpload={beforeUploadWordFile}
            action={uploadActionUrl}
            accept=".word,.docx"
          >
            <p
              className="ant-upload-drag-icon"
              style={{ marginTop: 32 }}
            >
              {
                fileName ?
                  <Image
                    src={require('./../../../assets/image/word-green-icon.png')}
                    style={{
                      width: 48,
                      height: 48,
                    }}
                    preview={false}
                  />
                  :
                  <FileWord theme="outline" size="48" fill={'#909399'} strokeWidth={3} />
              }
            </p>
            <p className="ant-upload-text">
              {fileName ? fileName + '.docx' : <span style={{color: '#909399'}}>点击上传或拖入保护地基本概况文档</span>}
            </p>
          </Dragger>
          <div
            className="single-line-text link-text"
            style={{ width: 320 }}
            onClick={() => handleDownloadWordTemplate()}
          >
            <PaperClipOutlined />
            下载模板-保护地基本概况
          </div>
        </div>
        {/* <Divider></Divider> */}
        {/* <div className='flex-row word-take-result-expanded-button' onClick={() => setIsExpanded(!isExpanded)}>
          基本概况提取结果<CaretRightOutlined rotate={isExpanded ? 90 : 0} />
        </div> */}
        {/* <div style={{ height: isExpanded ? 'auto' : 0, overflow: 'hidden' }} className='word-take-result-expanded-list'>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>地理位置</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.geographicalLocationText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'geographicalLocationText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>范围</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.rangeText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'rangeText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>历史沿革</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.historicalEvolutionText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'historicalEvolutionText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>法律地位</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.legalStatusText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'legalStatusText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>主要保护对象</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.mainProtectionObjecText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'mainProtectionObjecText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>行政区域</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.administrativeDivisionText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'administrativeDivisionText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>土地权属</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.landOwnershipText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'landOwnershipText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>地质地貌</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.geologyAndGeomorphologyText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'geologyAndGeomorphologyText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>气候</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.climateText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'climateText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>水文</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.hydrologyText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'hydrologyText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>土壤</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.soilText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'soilText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>植被</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.vegetationText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'vegetationText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>真菌</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.fungusText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'fungusText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
            <div className='extra-info-item-row-gap'></div>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>景观资源</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.landscapeResourcesText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'landscapeResourcesText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
          <div className='flex-row extra-info-item-row'>
            <div className='extra-info-item'>
              <div className='extra-info-item-title'>自然灾害</div>
              <TextArea
                className='extra-info-item-textarea'
                value={supplementaryInformation.naturalDisasterText}
                onChange={(e) => onSupplementaryInformationTextChange(e, 'naturalDisasterText')}
                maxLength={10000}
                placeholder="请填充文本，字数不超过10000字。"
                showCount
              />
            </div>
          </div>
        </div> */}
      </div>
    )
  }

  const fillFakeProtectlandData = () => {
    let _formData = deepCopy(defaultFormData);
    const fakeFormData = {
      protectlandProvince: "北京",
      protectlandLevel: "1",
      protectlandArea: "10000",
      protectlandAreaUnit: "hm2",
      startYear: (new Date().getFullYear()).toString(),
      endYear: (new Date().getFullYear() + 9).toString(),
      endYearStep: "10",
      protectlandName: saasConfig.systemType == 'QingYuanDefault' ? "青远生态保护区" : null,
    }
    _formData = {
      ..._formData,
      ...fakeFormData,
    }
    form.setFieldsValue(deepCopy(_formData));
    setFormData(_formData);
    queryProtectlandTypeList(checkIsTempAccount() ? "6424f622019ebb24cd3d9ea7" : null);
    setTimeout(() => {
      form.setFieldValue(
        "startYear",
        _formData.startYear
      );
      form.setFieldValue(
        "endYearStep",
        _formData.endYearStep
      );
    }, 100);
  }

  const handleSortProtectlandTypeList = (options: ProtectlandType[]) => {
    return filterAndSortProtectLandTypeList(options)
  }

  const handleSortProtectlandSubTypeList = (options: ProtectlandType[]) => {
    return filterAndSortProtectLandSubTypeList(options)
  }

  return (
    <Modal
      title={adaptModalTitle()}
      open={isModalOpen}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: !isEmpty(formData.id),
      }}
      okText={isEmpty(formData.id) ? "确定" : ""}
      cancelText={!isEmpty(formData.id) ? "关闭" : "取 消"}
      width={880}
      maskClosable={false}
      confirmLoading={submitLoading}
      centered
    >
      <div className="custom-modal-container">
        <LoadingWapper loading={loading} />
        <Form
          size={"middle"}
          form={form}
          layout={"horizontal"}
          autoComplete="off"
          labelAlign={"right"}
          labelCol={{ span: 4 }}
          requiredMark={false}
          style={{ width: "100%", marginLeft: -24 }}
        >
          <Form.Item label="类型" required>
            <Space align={"baseline"}>
              <Form.Item
                name={"type"}
                rules={[{ required: true, message: "请选择保护地一级分类" }]}
              >
                <Select
                  id="ProtectlandTypeSelectId"
                  placeholder="请选择保护地一级分类"
                  style={{ width: 316, marginRight: 17 }}
                  loading={getDataLoading}
                  value={formData.type}
                  onChange={onTypeChange}
                  disabled={!isEmpty(formData.id)}
                  allowClear
                >
                  {
                    handleSortProtectlandTypeList(protectlandTypeList).map((option, index) => {
                      1
                      return (
                        <Option
                          value={option.value}
                          key={index + ''}
                        >
                          <div style={{ opacity: option._disabled ? 0.6 : 1 }}>
                            {option.label}
                          </div>
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name={"subType"}
                rules={[{ required: true, message: "请选择保护地二级分类" }]}
              >
                <Select
                  placeholder="请选择保护地二级分类"
                  style={{ width: 316 }}
                  loading={getDataLoading}
                  value={formData.subType}
                  onChange={onSubTypeChange}
                  disabled={!isEmpty(formData.id)}
                  allowClear
                >
                  {
                    currentProtectlandSubTypeList && handleSortProtectlandSubTypeList(currentProtectlandSubTypeList).map((option, index) => {
                      return (
                        <Option
                          value={option.value}
                          key={index + ''}
                        >
                          <div style={{ opacity: option._disabled ? 0.6 : 1 }}>
                            {option.label}
                          </div>
                        </Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Space>
          </Form.Item>
          <Row gutter={24} align={'middle'} style={{ width: 740, marginLeft: 64, marginTop: -32 }}>
            <Col span={8}>
              <Form.Item
                label="省份"
                name={"protectlandProvince"}
                rules={[{ required: true, message: "选择省份" }]}
                labelCol={{ span: 6 }}
              >
                <Select
                  placeholder="选择省份"
                  style={{ width: 168 }}
                  options={provinceList}
                  onChange={onProvinceChange}
                  disabled={!isEmpty(formData.id)}
                  value={formData.protectlandProvince}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="级别"
                name={"protectlandLevel"}
                rules={[{ required: true, message: "选择保护地级别" }]}
                labelCol={{ span: 6 }}
              >
                <Select
                  placeholder="选择保护地级别"
                  onChange={onProtectlandLevelChange}
                  style={{ width: 168 }}
                  disabled={!isEmpty(formData.id)}
                  options={[
                    { value: "1", label: "国家级" },
                    { value: "2", label: "省（自治区、直辖市）级" },
                    { value: "3", label: "市（自治州）级" },
                    { value: "4", label: "县（自治县、旗、县级市）级" },
                  ]}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="面积"
                name={"protectlandArea"}
                rules={[{ required: true, message: "请输入保护地面积" }]}
                labelCol={{ span: 6 }}
              >
                <Input
                  ref={areaInputRef}
                  placeholder="请输入"
                  style={{ width: 168 }}
                  addonAfter={areaUnitListSelect}
                  type={"number"}
                  value={formData.protectlandArea}
                  onChange={onProtectlandAreaTextChange}
                  maxLength={30}
                  disabled={!isEmpty(formData.id)}
                  onFocus={handleFocus}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} align={'middle'} style={{ width: 740, marginLeft: 64 }}>
            <Col span={8}>
              <Form.Item
                label="名称"
                name={"protectlandName"}
                rules={[{ required: true, message: "请输入保护地名称" }]}
                labelCol={{ span: 6 }}
              >
                {
                  formData.id ?
                    <Select
                      showSearch
                      placeholder="请输入完整名称将用于名称的自动填充"
                      style={{ width: 168 }}
                      maxLength={30}
                      value={formData.protectlandName}
                      options={currentTypeProtectLandList}
                      disabled
                    />
                    :
                    <SearchSelect
                      options={filterProtectlandInfoList()}
                      style={{ width: 168 }}
                      selectPopoverWidth={536}
                      value={formData.protectlandName}
                      onChange={onProtectlandNameTextChange}
                      placeholder="请输入完整名称将用于名称的自动填充"
                      maxLength={300}
                    />
                }
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="基准年份"
                name={"startYear"}
                rules={[{ required: true, message: "请选择规划基准年份" }]}
                labelCol={{ span: 9 }}
              >
                <Select
                  value={formData.startYear}
                  options={startYearOptions}
                  onChange={onStartYearChange}
                  placeholder={"请选择规划基准年份"}
                  disabled={!isEmpty(formData.id)}
                  style={{ width: 140 }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="规划年限"
                name={"endYearStep"}
                rules={[{ required: true, message: "请选择规划基准年份" }]}
                labelCol={{ span: 9 }}
              >
                <Select
                  options={endYearOptions}
                  style={{ width: 140 }}
                  value={
                    isEmpty(formData.endYearStep)
                      ? formData.endYearStep
                      : undefined
                  }
                  placeholder={"请选择规划年限"}
                  disabled={!isEmpty(formData.id)}
                  onChange={onEndYearSelectChange}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            className="flex-row add-project-extra-switcher"
            id="ProtectlandExtraInfoExpandBtnId"
            onClick={switchExtraInfoOpened}
          >
            <CaretRightOutlined style={{ opacity: 0 }} />
            补充信息
            (非必填)
            <CaretRightOutlined style={isOpenExtraInfo ? { transform: `rotateZ(90deg)` } : null} />
          </div>
          {
            isOpenExtraInfo ?
              <div style={{ width: 680, marginLeft: 58 }}>
                <Tabs
                  defaultActiveKey="2"
                  tabPosition={'left'}
                  style={{ marginLeft: -20 }}
                  className='protectland-details-tabs'
                  items={[
                    // {
                    //     label: `保护地存在问题`,
                    //     key: '1',
                    //     children: renderProblemTable()
                    // }
                    {
                      label: `基本概况`,
                      key: '2',
                      children: renderSupplementaryInformation()
                    },
                    {
                      label: <div id="addProjectSubTableTabId">规划附表</div>,
                      key: '3',
                      children: renderProjectSubTable()
                    },

                  ]}
                />
              </div>
              :
              null
          }
        </Form>
      </div>
    </Modal>
  );
};

export default forwardRef(AddProjectModal);
