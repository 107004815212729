import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Segmented, Spin, Tabs, Checkbox, Form, Input, Button, Table, Space, Pagination, UploadProps, Empty, Alert } from 'antd';
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import useReSize from '../../../hooks/useResize';
import { addKeyToList, comDownloadTemplate, commonErrorMsg, deepCopy, getChangeEventValue, getSingleTdTableHeight, isEmpty, toastShort } from '../../../utils';
import { DocConfig, SpeciesInfoType } from '../../../utils/types';
import systemApi from '../../../api/system';
import LoadingWapper from '../../../components/loading/LoadingWapper';
import comDocContext from '../../../context/DocContext';
import * as XLSX from "xlsx";
import { Tag, Tooltip, Upload } from 'antd';
import { BookOutlined, CloudUploadOutlined, PaperClipOutlined, QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
    fillSpeciesInfoList: Function
}

const SpeciesDataBase = (props: Props) => {

    const {
        fillSpeciesInfoList
    } = props;

    const {
        comDocInstance,
        _setComDocInstance,
        comDocConfig,
        _setComDocConfig,
        comFocusTopicNode,
    } = useContext(comDocContext);

    useEffect(() => {
        // console.log("comDocConfig--->", comDocConfig);
        // if (
        //     comDocConfig &&
        //     comDocConfig.docExtraConfigInfo &&
        //     comDocConfig.docExtraConfigInfo.speciesInfoList
        // ) {
        //     setSpeciesInfoList(comDocConfig.docExtraConfigInfo.speciesInfoList)
        // }
    }, [comDocConfig])

    const [loading, setLoading] = useState(false);
    const [speciesInfoList, setSpeciesInfoList] = useState<SpeciesInfoType[]>([]);
    const delayTimer = useRef<any>(null);
    const delayTimer2 = useRef<any>(null);
    const [percent, setPercent] = useState<number>(0);
    const currentPercent = useRef<number>(0);
    const isActive = useRef<boolean>(false);
    const customSelectRef = useRef<any>(null);
    const tempDownloadUrl = useRef<string>(null);

    useEffect(() => {
        return () => {

        }
    }, [])

    const uploadProps: UploadProps = {
        name: 'file',
        showUploadList: false,
        beforeUpload: (file) => {
            // console.log("beforeUpload---->", file)
            let reader = new FileReader()
            reader.readAsBinaryString(file)//读取这个文件
            let _uploadParamsList = [];
            reader.onload = function (event) {
                try {
                    let result = event.target.result
                    let xlsxdata = XLSX.read(result, { type: 'binary' })//读取xlsx
                    // console.log("xlsxdata.Sheets--->", xlsxdata.Sheets)
                    const workSheetNameList: string[] = xlsxdata.SheetNames;
                    // console.log("workSheetNameList--->", workSheetNameList)
                    const spaciesSheet: string[][] = XLSX.utils.sheet_to_json(xlsxdata.Sheets[workSheetNameList[0]], { header: 1, defval: '', blankrows: true });
                    // console.log("spaciesSheet----->", spaciesSheet)
                    const tempSpeciesNameList = [];
                    spaciesSheet.forEach((row, rowIndex) => {
                        if (rowIndex > 0 && row[0]) {
                            tempSpeciesNameList.push(row[0])
                        }
                    })
                    setLoading(true);
                    let params = {
                        names: tempSpeciesNameList.filter(name => name),
                        range: 'all'
                    };
                    systemApi.querySpeciesInfoMutiple(params)
                        .then(res => {
                            setLoading(false);
                            // console.log("querySpeciesInfoMutiple--->", res)
                            let tempspeciesInfoList = [];
                            tempSpeciesNameList.forEach(speciesName => {
                                const find = res.data.list.find(ele => {
                                    return ele.species_c === speciesName;
                                })
                                if (find) {
                                    tempspeciesInfoList.push(find)
                                } else {
                                    tempspeciesInfoList.push({
                                        species_c: speciesName
                                    })
                                }
                            })
                            setSpeciesInfoList(tempspeciesInfoList);
                            let _tempComDocConfig = comDocConfig;
                            _tempComDocConfig.docExtraConfigInfo.speciesInfoList = tempspeciesInfoList;
                            handleUpdateComDocConfig(_tempComDocConfig);
                        })
                        .catch(err => {
                            setLoading(false);
                        })
                } catch (e) {

                }
            }
            return false;
        },
        onChange(info) {
            return false;
        },
    };

    const handleUpdateComDocConfig = (newDocConfig: DocConfig) => {
        let filterDoConfig = { ...newDocConfig };
        delete filterDoConfig.docExtraConfigInfo.dataVerificationMap;
        delete filterDoConfig.docExtraConfigInfo.mergeBoundInfo;
        filterDoConfig.updateComponentName = 'EditPageNav';
        _setComDocConfig(filterDoConfig);
    }

    const handleStartInsert = () => {
        if (speciesInfoList.length == 0) {
            return toastShort("error", "请上传物种清单")
        }
        let realUseSpeciesInfoList = speciesInfoList.filter(ele => {
            return ele.canorical_me;
        })
        fillSpeciesInfoList && fillSpeciesInfoList(realUseSpeciesInfoList);
        setSpeciesInfoList([]);
    }

    const handleDownLoadTemplate = () => {
        comDownloadTemplate("物种名单上传模板.xlsx")
    }

    const adaptDesc = () => {
        const successNameCount = speciesInfoList.filter(row => row.canorical_me).length;
        const failedNameCount = speciesInfoList.filter(row => isEmpty(row.canorical_me)).length;
        let descText =  `${successNameCount}个物种拉丁名匹配成功`;
        if(failedNameCount){
            descText += `，${failedNameCount}个物种匹配失败，请确认物种名称是否正确。`;
        }else {
            descText += '。'
        }
        if(failedNameCount == 0 && successNameCount > 1){
            descText = '全部物种拉丁名匹配成功。';
        }
        return descText;
    }

    const renderTab = () => {
        return (
            <div
                className='flex-col'
                style={{ width: '100%', height: `calc(100vh - 230px)` }}
            >
                <div
                    className='flex-row'
                    style={{
                        flex: 1,
                        width: '100%',
                        flexWrap: 'wrap',
                        alignContent: 'flex-start',
                        overflowY: 'auto'
                    }}
                >
                    <LoadingWapper
                        loading={loading}
                    />
                    {
                        speciesInfoList.length ?
                            <Alert
                                style={{ paddingTop: 3, paddingBottom: 3, marginBottom: 12, paddingLeft: 8, marginRight: 12 }}
                                // showIcon
                                // closable={true}
                                type={'warning'}
                                description={adaptDesc()}
                            />
                            :
                            null
                    }
                    {
                        speciesInfoList.length ?
                            <>
                                {
                                    speciesInfoList.map((speciesInfo, index) => {
                                        return (
                                            <div
                                                key={index + ''}
                                                className='species-name-tag'
                                            >
                                                {
                                                    speciesInfo.canorical_me ?
                                                        <Tag
                                                            color="blue"
                                                            className='flex-row'
                                                            title={`${speciesInfo.species_c}(${speciesInfo.canorical_me})`}
                                                        >
                                                            {speciesInfo.species_c}
                                                            {
                                                                <div
                                                                    style={{ fontStyle: 'italic', maxWidth: 72 }}
                                                                    className='single-line-text'
                                                                >({speciesInfo.canorical_me})</div>
                                                            }
                                                        </Tag>
                                                        :
                                                        <Tag
                                                            color="red"
                                                            className='flex-row'
                                                        >
                                                            {speciesInfo.species_c}(查询失败)&nbsp;
                                                            <Tooltip title={'在本系统物种库中，未能查询到此物种信息'}>
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </Tag>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>
                            :
                            <div className='flex-col' style={{width: '100%'}}>
                                <Empty
                                    description={"暂无物种名单"}
                                    style={{ marginTop: 120 }}
                                />
                            </div>
                    }
                </div>
                <div 
                    className='flex-row canorical-me-popover-footer'
                    style={{
                        borderTop: '1px solid #d2d2d2',
                        justifyContent: 'flex-end',
                        paddingRight: 12
                    }}
                >
                    <Upload {...uploadProps}>
                        <Button
                            icon={<CloudUploadOutlined />}

                        >
                            上传物种名单
                        </Button>
                    </Upload>
                    <Button
                        type={'primary'}
                        icon={<BookOutlined />}
                        loading={loading}
                        style={{ marginLeft: 12 }}
                        onClick={handleStartInsert}
                        disabled={speciesInfoList.length == 0}
                    >
                        填充拉丁名
                    </Button>
                </div>
                <div
                    className='link-text'
                    style={{ marginBottom: 12 }}
                    onClick={handleDownLoadTemplate}
                >
                    <PaperClipOutlined />
                    物种名单上传模版.xlsx
                </div>
            </div>
        )
    }

    const items = [
        {
            key: '1',
            label: '拉丁名填充',
            children: renderTab()
        },
    ]

    return (
        <div className="word-editor-plugins-content">
            <Tabs
                type={'card'}
                defaultActiveKey="1"
                //@ts-ignore
                items={items}
                tabPosition='top'
                className='word-editor-plugin-content-tabs disable-drag'
                size={'small'}
            />

        </div>
    )
}

export default SpeciesDataBase;