import { isEmpty } from "lodash";
import { ProtectlandBaseInfoType, SpeciesInfoType, WordParagraphBlock, WordParagraphBlockSlice } from "../../../utils/types";


const insertItemToUniqunList = (originList: any[], newItem: any, checkAttribute?: string): any[] => {
    let resultList = originList;
    const findItem = originList.find(ele => {
        return checkAttribute ? ele[checkAttribute] == newItem[checkAttribute] : ele == newItem
    })
    if (!findItem) {
        resultList.push(newItem);
    }
    return resultList;
}

const getListTargetSubList = (originList: any[], subIndex: number): any[] => {
    if (originList.length < subIndex) {
        return originList;
    }
    return originList.slice(0, subIndex);
}

const getGenusNameListByFamilyC = (speciesInfoList: SpeciesInfoType[], familyC): string[] => {
    let genusNameList = [];
    speciesInfoList.forEach(ele => {
        if (ele.family_c == familyC) {
            genusNameList = insertItemToUniqunList(genusNameList, ele.genus_c)
        }
    })
    return genusNameList;
}

const getSpeciesNameListByFamilyC = (speciesInfoList: SpeciesInfoType[], familyC): string[] => {
    let speciesNameList = [];
    speciesInfoList.forEach(ele => {
        if (ele.family_c == familyC) {
            speciesNameList = insertItemToUniqunList(speciesNameList, ele.species_c)
        }
    })
    return speciesNameList;
}

const replaceProtectionLevel = (level: string) => {
    if (level == 'I级') {
        return '一级'
    }
    if (level == 'II级') {
        return '二级'
    }
}

const getFamilyNameByFamilyC = (speciesInfoList: SpeciesInfoType[], familyC) => {
    let family = ''
    for (let i = 0; i < speciesInfoList.length; i++) {
        if (speciesInfoList[i].family_c == familyC) {
            family = speciesInfoList[i].family;
            break;
        }
    }
    return family;
}

const getPercent = (value1: number, value2: number) => {
    return ((Number(value1) / Number(value2)) * 100).toFixed(2)
}

const generateWordParagraphBySliceList = (
    inlineSliceList: WordParagraphBlockSlice[]
): WordParagraphBlock => {
    let inlineStyleRanges = [];
    let offset = 0;
    let totalText = "";
    let styledText = "";
    let textStyleMap = {};
    let textStyleMapIndex = 0;
    inlineSliceList.forEach(inlineContent => {
        inlineContent.styleList.forEach(inlineStyle => {
            inlineStyleRanges.push({
                style: inlineStyle,
                offset: offset,
                length: inlineContent.content.length
            })
        })
        if (inlineContent.styleList.includes("ITALIC") || inlineContent.styleList.includes("TimesNewRoman")) {
            const marker = `$$[${textStyleMapIndex}]`;
            styledText += marker;
            textStyleMap[marker] = {
                text: inlineContent.content,
                italic: true,
                fontFamily: "Times New Roman"
            }
            textStyleMapIndex ++;
        } else {
            styledText += inlineContent.content;
        }
        inlineStyleRanges.push({
            style: 'normal_inline_style',
            offset: offset,
            length: inlineContent.content.length
        })
        totalText += inlineContent.content;
        offset = offset + inlineContent.content.length;
    })
    let result: WordParagraphBlock = {
        text: totalText,
        type: 'text',
        inlineStyleRanges,
        paragraphStyle: 'normal_paragraph',
        paragraphType: 'custom_background_paragraph',
        //@ts-ignore
        docItem: {
            textStyleMap,
            text: styledText,
            plainText: totalText,
        }
    }
    return result;
}

const customSort = (a: SpeciesInfoType, b: SpeciesInfoType) => {
    // 先按照其他字段的首字母排序
    // const compareResultFamily = (a.family_c || '').localeCompare(b.family_c || '');
    // if (compareResultFamily !== 0) {
    //     return compareResultFamily;
    // }

    // const compareResultGenus = (a.genus_c || '').localeCompare(b.genus_c || '');
    // if (compareResultGenus !== 0) {
    //     return compareResultGenus;
    // }

    // const compareResultSpecies = (a.species_c || '').localeCompare(b.species_c || '');
    // if (compareResultSpecies !== 0) {
    //     return compareResultSpecies;
    // }

    // 如果其他字段相等，则按照国家保护级别排序，一级优于二级
    const protectionLevelOrder = { '一级': -1, '二级': 1 };
    return protectionLevelOrder[a.nationa_protection_level] - protectionLevelOrder[b.nationa_protection_level];
}

const generateSubTableSpeciesBook = (
    speciesInfoList: SpeciesInfoType[],
    protectlandBaseInfo: ProtectlandBaseInfoType,
):WordParagraphBlock[] => {
    const {
        protectlandArea,
        protectlandAreaUnit = '公顷',
        protectlandLevel,
        protectlandName,
        protectlandProvince,
        protectlandTypeId,
        startYear,
        endYear
    } = protectlandBaseInfo
    //匹配失败的物种名称列表
    let matchFailedSpeciesNameList: string[] = [];
    let plantSpeciesInfoList: SpeciesInfoType[] = [];
    let animalSpeciesInfoList: SpeciesInfoType[] = [];
    let insectTypeCounter = [];             //昆虫样线调查
    let shrubPlantTypeCounter = [];         //灌丛样地调查
    let plantLineTypeCounter = [];          //植物样线调查
    let birdTypeCounter = [];               //鸟类样线调查
    let mammalTypeCounter = [];             //哺乳动物样线调查
    let vegetationTypeCounter = [];         //植被类型调查
    let crawlTypeCounter = [];              //爬行动物样线调查
    let grasslandTypeCounter = [];          //草原样地调查
    let wetlandTypeCounter = [];            //湿地样地调查
    let fungusTypeCounter = [];             //真菌样线调查
    let amphibiousTypeCounter = [];         //两栖动物样线调查
    let tempTypeCounter = [];               //森林样地调查
    //
    let plantFamilyNameList = [];           //植物总科
    let plantGenusNameList = [];            //植物总属
    let plantSpeciesNameList = [];          //植物总种数
    //
    let beiziFamilyNameList = [];           //被子植物的科数
    let beiziGenusNameList = [];            //被子植物的属数
    let beiziSpeciesNameList = [];          //被子植物种数
    //
    let luoziFamilyNameList = [];           //裸子植物的科数
    let luoziGenusNameList = [];            //裸子植物的属数
    let luoziSpeciesNameList = [];          //裸子植物种数
    //
    let fungusFamilyNameList = [];          //大型真菌的科数
    let fungusGenusNameList = [];           //大型真菌的属数
    let fungusSpeciesNameList = [];         //大型真菌的种数
    //
    let protectPlantSpeciesList = [];       //保护植物的种数
    let protectBeiziSpeciesList = [];       //保护被子植物的种数
    let protectLuoziSpeciesList = [];       //保护裸子植物的种数
    let protectFungusSpeciesList = [];      //保护大型真菌的种数
    let protectAnimalSpeciesList = [];      //保护动物的种数
    let beiziProtectSpeciesList = [];       //保护被子植物的种数
    let luoziProtectSpeciesList = [];       //保护裸子植物的种树
    let fungusProtectSpeciesList = [];      //保护大型真菌的种数
    let animalSpeciesNameList = [];         //动物物种名称集合
    let animalFamilyNameList = [];          //动物科名集合
    let animalOrderNameList = [];           //动物目名集合
    //
    let mammalSpeciesNameList = [];         //哺乳动物-物种名称集合
    let mammalFamilyNameList = [];          //哺乳动物-科名集合
    let mammalOrderNameList = [];           //哺乳动物-目名集合
    //
    let birdSpeciesNameList = [];           //鸟类-物种名称集合
    let birdFamilyNameList = [];            //鸟类-科名集合
    let birdOrderNameList = [];             //鸟类-目名集合
    //
    let crawlSpeciesNameList = [];          //爬行动物-物种名称集合
    let crawlFamilyNameList = [];           //爬行动物-科名集合
    let crawlOrderNameList = [];            //爬行动物-目名集合
    //
    let amphibiousSpeciesNameList = [];     //两栖动物-物种名称集合
    let amphibiousFamilyNameList = [];      //两栖动物-科名集合
    let amphibiousOrderNameList = [];       //两栖动物-目名集合
    //
    let insectSpeciesNameList = [];         //昆虫-物种名称集合
    let insectFamilyNameList = [];          //昆虫-科名集合
    let insectOrderNameList = [];           //昆虫-目名集合
    //
    let mammalProtectSpeciesList = [];      //哺乳动物-保护物种名称集合
    let birdProtectSpeciesList = [];        //鸟类-保护物种名称集合
    let crawlProtectSpeciesList = [];       //爬行动物-保护物种名称集合
    let amphibiousProtectSpeciesList = [];  //两栖动物-保护物种名称集合
    let insectProtectSpeciesList = [];      //昆虫-保护物种名称集合
    //开始处理物种信息数据
    speciesInfoList.forEach((speciesInfo, index) => {
        if (speciesInfo.nationa_protection_level) {
            console.log("有国家级保护信息的数据是------>", speciesInfo)
            //@ts-ignore
            speciesInfo.nationa_protection_level = speciesInfo.nationa_protection_level.replace("一级", "I级");
            //@ts-ignore
            speciesInfo.nationa_protection_level = speciesInfo.nationa_protection_level.replace("二级", "II级");
        }
        if (speciesInfo.type == 'plant' || speciesInfo.type == 'fungus') {
            //记录植物信息
            plantFamilyNameList = insertItemToUniqunList(plantFamilyNameList, speciesInfo.family_c);
            plantGenusNameList = insertItemToUniqunList(plantGenusNameList, speciesInfo.genus_c);
            plantSpeciesNameList = insertItemToUniqunList(plantSpeciesNameList, speciesInfo.species_c);
            if (speciesInfo.nationa_protection_level) {
                plantSpeciesInfoList = insertItemToUniqunList(plantSpeciesInfoList, speciesInfo, 'species_c')
            }
        } else {
            //记录动物信息
            animalFamilyNameList = insertItemToUniqunList(animalFamilyNameList, speciesInfo.family_c);
            animalOrderNameList = insertItemToUniqunList(animalOrderNameList, speciesInfo.order_c);
            animalSpeciesNameList = insertItemToUniqunList(animalSpeciesNameList, speciesInfo.species_c)
            if (speciesInfo.nationa_protection_level) {
                animalSpeciesInfoList = insertItemToUniqunList(animalSpeciesInfoList, speciesInfo, 'species_c')
            }
        }
        //分小类处理
        if (speciesInfo.type == 'plant') {
            if (speciesInfo.phylum == 'Angiospermae') {
                //被子植物
                beiziFamilyNameList = insertItemToUniqunList(beiziFamilyNameList, speciesInfo.family_c);
                beiziGenusNameList = insertItemToUniqunList(beiziGenusNameList, speciesInfo.genus_c);
                beiziSpeciesNameList = insertItemToUniqunList(beiziSpeciesNameList, speciesInfo.species_c)
            } else if (speciesInfo.phylum == 'Gymnospermae') {
                //裸子植物
                luoziFamilyNameList = insertItemToUniqunList(luoziFamilyNameList, speciesInfo.family_c);
                luoziGenusNameList = insertItemToUniqunList(luoziGenusNameList, speciesInfo.genus_c);
                luoziSpeciesNameList = insertItemToUniqunList(luoziSpeciesNameList, speciesInfo.species_c)
            }
        } else if (speciesInfo.type == 'fungus') {
            //大型真菌
            luoziFamilyNameList = insertItemToUniqunList(luoziFamilyNameList, speciesInfo.family_c);
            luoziGenusNameList = insertItemToUniqunList(luoziGenusNameList, speciesInfo.genus_c);
            luoziSpeciesNameList = insertItemToUniqunList(luoziSpeciesNameList, speciesInfo.species_c)
        } else if (speciesInfo.type == 'mammal') {
            //哺乳动物
            mammalFamilyNameList = insertItemToUniqunList(mammalFamilyNameList, speciesInfo.family_c);
            mammalOrderNameList = insertItemToUniqunList(mammalOrderNameList, speciesInfo.order_c);
            mammalSpeciesNameList = insertItemToUniqunList(mammalSpeciesNameList, speciesInfo.species_c)
        } else if (speciesInfo.type == 'bird') {
            //鸟类
            birdFamilyNameList = insertItemToUniqunList(birdFamilyNameList, speciesInfo.family_c);
            birdOrderNameList = insertItemToUniqunList(birdOrderNameList, speciesInfo.order_c);
            birdSpeciesNameList = insertItemToUniqunList(birdSpeciesNameList, speciesInfo.species_c)
        } else if (speciesInfo.type == 'amphibious') {
            //两栖
            amphibiousFamilyNameList = insertItemToUniqunList(amphibiousFamilyNameList, speciesInfo.family_c);
            amphibiousOrderNameList = insertItemToUniqunList(amphibiousOrderNameList, speciesInfo.order_c);
            amphibiousSpeciesNameList = insertItemToUniqunList(amphibiousSpeciesNameList, speciesInfo.species_c)
        } else if (speciesInfo.type == 'insect') {
            //昆虫
            insectFamilyNameList = insertItemToUniqunList(insectFamilyNameList, speciesInfo.family_c);
            insectOrderNameList = insertItemToUniqunList(insectOrderNameList, speciesInfo.order_c);
            insectSpeciesNameList = insertItemToUniqunList(insectSpeciesNameList, speciesInfo.species_c)
        }
    })
    console.log("beiziFamilyNameList---->", beiziFamilyNameList)
    interface SpeciesSubTableResult {
        plantSummaryParagraph: WordParagraphBlock
        plantProtecteParagraph: WordParagraphBlock
        plantQuantitativeStatistics: any[][]
        animalSummaryParagraph: WordParagraphBlock
        animalProtecteParagraph: WordParagraphBlock
        animalQuantitativeStatistics: any[][]
        plantProtecteSpeciesBook: any[][]
        animalProtecteSpeciesBook: any[][]
    }
    let result: SpeciesSubTableResult = {
        plantSummaryParagraph: null,
        plantProtecteParagraph: null,
        plantQuantitativeStatistics: [],
        animalSummaryParagraph: null,
        animalProtecteParagraph: null,
        animalQuantitativeStatistics: [],
        plantProtecteSpeciesBook: [],
        animalProtecteSpeciesBook: []
    };
    const plantSummaryParagraphSliceList: WordParagraphBlockSlice[] = [];
    const plantProtecteParagraphSliceList: WordParagraphBlockSlice[] = [];
    const animalSummaryParagraphSliceList: WordParagraphBlockSlice[] = [];
    const animalProtecteParagraphSliceList: WordParagraphBlockSlice[] = [];
    // `[NAME]内环境条件复杂，蕴育了丰富的植物资源。根据野外调查和相关资料，保护区共有高等植物127科472属1089种（被子植物为91科415属1002种、裸子植物28科46属71种、蕨类植物8科11属16种）。芦芽山自然保护区地区被子植物1002种，分别隶属于415属91科，最大三科分别是菊科（Compositae）（48属129种，下同）、禾本科（Gramineae）（29属73种）和蔷薇科（Rosaceae）（22属66种），在该区系组成中占有绝对优势。`
    if (
        plantFamilyNameList.length > 0
    ) {
        plantSummaryParagraphSliceList.push({
            content: `${protectlandName}内环境条件复杂，蕴育了丰富的植物资源。根据野外调查和相关资料，保护区共有高等植物${plantFamilyNameList.length}科${plantGenusNameList.length}属${plantSpeciesNameList.length}种`,
            styleList: ['normal_inline_style']
        });
        if (beiziSpeciesNameList.length || luoziSpeciesNameList.length || fungusSpeciesNameList.length) {
            plantSummaryParagraphSliceList.push({
                content: `(`,
                styleList: ['normal_inline_style']
            })
            if (beiziSpeciesNameList.length) {
                plantSummaryParagraphSliceList.push({
                    content: `被子植物为${beiziFamilyNameList.length}科${beiziGenusNameList.length}属${beiziSpeciesNameList.length}种`,
                    styleList: ['normal_inline_style']
                })
            }
            if (luoziSpeciesNameList.length) {
                plantSummaryParagraphSliceList.push({
                    content: `、裸子植物${luoziFamilyNameList.length}科${luoziGenusNameList.length}属${luoziSpeciesNameList.length}种`,
                    styleList: ['normal_inline_style']
                })
            }
            if (fungusSpeciesNameList.length) {
                plantSummaryParagraphSliceList.push({
                    content: `、蕨类植物${fungusFamilyNameList.length}科${fungusGenusNameList.length}属${fungusSpeciesNameList.length}种`,
                    styleList: ['normal_inline_style']
                })
            }
            plantSummaryParagraphSliceList.push({
                content: `)。`,
                styleList: ['normal_inline_style']
            })
            let plantTypeSpeciesCountList = [beiziSpeciesNameList.length, luoziSpeciesNameList.length, fungusSpeciesNameList.length];
            plantTypeSpeciesCountList = plantTypeSpeciesCountList.sort((a, b) => b - a);
            let plantFamilyNameSubList = [];
            switch (plantTypeSpeciesCountList[0]) {
                case beiziSpeciesNameList.length:
                    plantFamilyNameSubList = getListTargetSubList(beiziFamilyNameList, 3);
                    plantSummaryParagraphSliceList.push({
                        content: `${protectlandName}地区被子植物${beiziSpeciesNameList.length}种，分别隶属于${beiziGenusNameList.length}属${beiziFamilyNameList.length}科，`,
                        styleList: ['normal_inline_style']
                    })
                    break;
                case luoziSpeciesNameList.length:
                    plantFamilyNameSubList = getListTargetSubList(luoziFamilyNameList, 3);
                    plantSummaryParagraphSliceList.push({
                        content: `${protectlandName}地区裸子植物${luoziSpeciesNameList.length}种，分别隶属于${luoziGenusNameList.length}属${luoziFamilyNameList.length}科，`,
                        styleList: ['normal_inline_style']
                    })
                    break;
                case fungusSpeciesNameList.length:
                    plantFamilyNameSubList = getListTargetSubList(fungusFamilyNameList, 3);
                    plantSummaryParagraphSliceList.push({
                        content: `${protectlandName}地区蕨类植物${fungusSpeciesNameList.length}种，分别隶属于${fungusGenusNameList.length}属${fungusFamilyNameList.length}科，`,
                        styleList: ['normal_inline_style']
                    })
                    break;
                default:
                    break;
            }
            plantSummaryParagraphSliceList.push({
                content: `最大三科分别是`,
                styleList: ['normal_inline_style']
            })
            plantFamilyNameSubList.forEach((familyName,familyIndex) => {
                const _genusNameList = getGenusNameListByFamilyC(speciesInfoList, familyName);
                const _speciesNameList = getSpeciesNameListByFamilyC(speciesInfoList, familyName);
                plantSummaryParagraphSliceList.push({
                    content: `${familyName}(`,
                    styleList: ['normal_inline_style']
                })
                plantSummaryParagraphSliceList.push({
                    content: `${getFamilyNameByFamilyC(speciesInfoList, familyName)})`,
                    styleList: ['normal_inline_style', 'TimesNewRoman']
                })
                if(familyIndex < plantFamilyNameSubList.length - 1){
                    plantSummaryParagraphSliceList.push({
                        content: `)(${_genusNameList.length}属${_speciesNameList.length}种)、`,
                        styleList: ['normal_inline_style']
                    })
                }else {
                    plantSummaryParagraphSliceList.push({
                        content: `)(${_genusNameList.length}属${_speciesNameList.length}种)`,
                        styleList: ['normal_inline_style']
                    })
                }
                
            })
        }
        plantSummaryParagraphSliceList.push({
            content: `在该区系组成中占有绝对优势。`,
            styleList: ['normal_inline_style']
        })
        const protectionLevel1PlantSpeciesInfoList = plantSpeciesInfoList.filter(row => {
            return row.nationa_protection_level == 'I级';
        })
        const protectionLevel2PlantSpeciesInfoList = plantSpeciesInfoList.filter(row => {
            return row.nationa_protection_level == 'II级';
        })
        if (protectionLevel1PlantSpeciesInfoList.length || protectionLevel2PlantSpeciesInfoList.length) {
            plantProtecteParagraphSliceList.push({
                content: `依据国家林业和草原局、农业农村部2021年联合发布的《国家重点保护野生植物名录》，${protectlandName}内包括`,
                styleList: ['normal_inline_style']
            });
        }
        if (protectionLevel1PlantSpeciesInfoList.length) {
            plantProtecteParagraphSliceList.push({
                content: `国家一级重点野生保护植物`,
                styleList: ['normal_inline_style']
            });
            let lastIndex = protectionLevel1PlantSpeciesInfoList.length >= 3 ? 2 : protectionLevel1PlantSpeciesInfoList.length - 1;
            protectionLevel1PlantSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
                if (speciesInfoIndex <= 2) {
                    plantProtecteParagraphSliceList.push({
                        content: `${speciesInfo.species_c}（`,
                        styleList: ['normal_inline_style']
                    });
                    plantProtecteParagraphSliceList.push({
                        content: `${speciesInfo.canorical_me}`,
                        styleList: ['normal_inline_style', 'ITALIC', 'TimesNewRoman']
                    });
                    let lastSymbol = '，';
                    if (protectionLevel2PlantSpeciesInfoList.length == 0) {
                        lastSymbol = '。'
                    }
                    plantProtecteParagraphSliceList.push({
                        content: speciesInfoIndex < lastIndex ? `）、` : `）等${protectionLevel1PlantSpeciesInfoList.length}种${lastSymbol}`,
                        styleList: ['normal_inline_style']
                    });
                }
            })
        }
        if (protectionLevel2PlantSpeciesInfoList.length) {
            plantProtecteParagraphSliceList.push({
                content: `国家二级重点野生保护植物`,
                styleList: ['normal_inline_style']
            });
            protectionLevel2PlantSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
                let lastIndex = protectionLevel2PlantSpeciesInfoList.length >= 3 ? 2 : protectionLevel2PlantSpeciesInfoList.length - 1;
                if (speciesInfoIndex <= 2) {
                    plantProtecteParagraphSliceList.push({
                        content: `${speciesInfo.species_c}（`,
                        styleList: ['normal_inline_style']
                    });
                    plantProtecteParagraphSliceList.push({
                        content: `${speciesInfo.canorical_me}`,
                        styleList: ['normal_inline_style', 'ITALIC', 'TimesNewRoman']
                    });
                    plantProtecteParagraphSliceList.push({
                        content: speciesInfoIndex < lastIndex ? `）、` : `）等${protectionLevel2PlantSpeciesInfoList.length}种。`,
                        styleList: ['normal_inline_style']
                    });
                }
            })
        }
        let plantQuantitativeStatistics: any[][] = [
            ["类群", "科数", "占总科数％", "属数", "占总属数%", "种数", "占总种数％"]
        ];
        let beiziQuantitativeStatisticsRow = [
            "被子植物",
            beiziFamilyNameList.length,
            getPercent(beiziFamilyNameList.length, beiziFamilyNameList.length + luoziFamilyNameList.length + fungusFamilyNameList.length),
            beiziGenusNameList.length,
            getPercent(beiziGenusNameList.length, beiziGenusNameList.length + luoziGenusNameList.length + fungusGenusNameList.length),
            beiziSpeciesNameList.length,
            getPercent(beiziSpeciesNameList.length, beiziSpeciesNameList.length + luoziSpeciesNameList.length + fungusSpeciesNameList.length),
        ];
        let luoziQuantitativeStatisticsRow = [
            "裸子植物",
            luoziSpeciesNameList.length,
            getPercent(luoziFamilyNameList.length, beiziFamilyNameList.length + luoziFamilyNameList.length + fungusFamilyNameList.length),
            luoziGenusNameList.length,
            getPercent(luoziGenusNameList.length, beiziGenusNameList.length + luoziGenusNameList.length + fungusGenusNameList.length),
            luoziSpeciesNameList.length,
            getPercent(luoziSpeciesNameList.length, beiziSpeciesNameList.length + luoziSpeciesNameList.length + fungusSpeciesNameList.length),
        ];
        let fungusQuantitativeStatisticsRow = [
            "蕨类植物",
            fungusFamilyNameList.length,
            getPercent(fungusFamilyNameList.length, beiziFamilyNameList.length + luoziFamilyNameList.length + fungusFamilyNameList.length),
            fungusGenusNameList.length,
            getPercent(fungusGenusNameList.length, beiziGenusNameList.length + luoziGenusNameList.length + fungusGenusNameList.length),
            fungusSpeciesNameList.length,
            getPercent(fungusSpeciesNameList.length, beiziSpeciesNameList.length + luoziSpeciesNameList.length + fungusSpeciesNameList.length),
        ]
        let plantTotalQuantitativeStatisticsRow = [
            "合计",
            beiziFamilyNameList.length + luoziFamilyNameList.length + fungusFamilyNameList.length,
            '100.00',
            fungusGenusNameList.length + luoziGenusNameList.length + luoziSpeciesNameList.length,
            '100.00',
            fungusSpeciesNameList.length + fungusGenusNameList.length + fungusSpeciesNameList.length,
            '100.00',
        ]
        if (!isEmpty(beiziQuantitativeStatisticsRow[1]) && beiziQuantitativeStatisticsRow[1] != 0) {
            plantQuantitativeStatistics.push(beiziQuantitativeStatisticsRow);
        }
        if (!isEmpty(luoziQuantitativeStatisticsRow[1]) && luoziQuantitativeStatisticsRow[1] != 0) {
            plantQuantitativeStatistics.push(luoziQuantitativeStatisticsRow);
        }
        if (!isEmpty(fungusQuantitativeStatisticsRow[1]) && fungusQuantitativeStatisticsRow[1] != 0) {
            plantQuantitativeStatistics.push(fungusQuantitativeStatisticsRow);
        }
        if (!isEmpty(plantTotalQuantitativeStatisticsRow[1] && plantTotalQuantitativeStatisticsRow[1] != 0)) {
            plantQuantitativeStatistics.push(plantTotalQuantitativeStatisticsRow);
        }
        result.plantQuantitativeStatistics = plantQuantitativeStatistics;
        let plantSpeciesBook: any[][] = [
            ["序号", "科", "属", "种名", "拉丁文名", "国家保护等级"]
        ]
        plantSpeciesInfoList.forEach(ele => {
            ele.nationa_protection_level = replaceProtectionLevel(ele.nationa_protection_level)
        })
        plantSpeciesInfoList = plantSpeciesInfoList.sort(customSort);
        let plantSpeciesBookContent = [];
        plantSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
            plantSpeciesBookContent.push(
                [
                    speciesInfoIndex + 1,
                    speciesInfo.family_c,
                    speciesInfo.genus_c,
                    speciesInfo.species_c,
                    speciesInfo.canorical_me,
                    speciesInfo.nationa_protection_level
                ]
            )
        })
        plantSpeciesBook = plantSpeciesBook.concat(plantSpeciesBookContent);
        result.plantProtecteSpeciesBook = plantSpeciesBook;
    }
    if (animalSpeciesNameList.length) {
        //动物描述文本
        animalSummaryParagraphSliceList.push({
            content: `${protectlandName}地理环境复杂，动物资源丰富，根据野外调查结合文献资料记载，${protectlandName}共有野生动物${animalOrderNameList.length}目${animalFamilyNameList.length}科${animalSpeciesNameList.length}种`,
            styleList: ['normal_inline_style'],
        })
        if (mammalSpeciesNameList.length) {
            animalSummaryParagraphSliceList.push({
                content: `， 其中兽类有${mammalOrderNameList.length}目${mammalFamilyNameList.length}科${mammalSpeciesNameList.length}种，占野生动物种数的${getPercent(mammalSpeciesNameList.length, animalSpeciesNameList.length)}%`,
                styleList: ['normal_inline_style']
            })
        }
        if (birdSpeciesNameList.length) {
            animalSummaryParagraphSliceList.push({
                content: `；鸟类有${birdOrderNameList.length}目${birdFamilyNameList.length}科${birdSpeciesNameList.length}种，占野生动物种数的${getPercent(birdSpeciesNameList.length, animalSpeciesNameList.length)}%`,
                styleList: ['normal_inline_style']
            })
        }
        if (amphibiousSpeciesNameList.length) {
            animalSummaryParagraphSliceList.push({
                content: `；两爬类有${amphibiousOrderNameList.length}目${amphibiousFamilyNameList.length}科${amphibiousSpeciesNameList.length}种，占野生动物种数的${getPercent(amphibiousSpeciesNameList.length, animalSpeciesNameList.length)}%`,
                styleList: ['normal_inline_style']
            })
        }
        if (insectSpeciesNameList.length) {
            animalSummaryParagraphSliceList.push({
                content: `；昆虫类有${insectOrderNameList.length}目${insectFamilyNameList.length}科${insectSpeciesNameList.length}种，占野生动物种数的${getPercent(insectSpeciesNameList.length, animalSpeciesNameList.length)}%`,
                styleList: ['normal_inline_style']
            })
        }
        animalSummaryParagraphSliceList.push({
            content: '。',
            styleList: ['normal_inline_style']
        })
        console.log("animalSpeciesInfoList-->", animalSpeciesInfoList)
        const protectionLevel1AnimalSpeciesInfoList = animalSpeciesInfoList.filter(row => {
            return row.nationa_protection_level == 'I级';
        })
        const protectionLevel2AnimalSpeciesInfoList = animalSpeciesInfoList.filter(row => {
            return row.nationa_protection_level == 'II级';
        })
        console.log("protectionLevel1AnimalSpeciesInfoList--->", protectionLevel1AnimalSpeciesInfoList)
        console.log("protectionLevel2AnimalSpeciesInfoList--->", protectionLevel2AnimalSpeciesInfoList)
        if (protectionLevel1AnimalSpeciesInfoList.length || protectionLevel2AnimalSpeciesInfoList.length) {
            animalProtecteParagraphSliceList.push({
                content: `依据国家林业和草原局、农业农村部2021年联合发布的《国家重点保护野生动物名录》，${protectlandName}内分布`,
                styleList: ['normal_inline_style']
            });
        }
        if (protectionLevel1AnimalSpeciesInfoList.length) {
            animalProtecteParagraphSliceList.push({
                content: `有我国国家一级重点保护动物`,
                styleList: ['normal_inline_style']
            });
            protectionLevel1AnimalSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
                // console.log("国家一级保护动物遍历---->", speciesInfo)
                let lastIndex = protectionLevel1AnimalSpeciesInfoList.length >= 3 ? 2 : protectionLevel1AnimalSpeciesInfoList.length - 1;
                if (speciesInfoIndex <= 2) {
                    animalProtecteParagraphSliceList.push({
                        content: `${speciesInfo.species_c}（`,
                        styleList: ['normal_inline_style']
                    });
                    animalProtecteParagraphSliceList.push({
                        content: `${speciesInfo.canorical_me}`,
                        styleList: ['normal_inline_style', 'ITALIC', 'TimesNewRoman']
                    });
                    let lastSymbol = '，';
                    if (protectionLevel2AnimalSpeciesInfoList.length == 0) {
                        lastSymbol = '。'
                    }
                    animalProtecteParagraphSliceList.push({
                        content: speciesInfoIndex < lastIndex ? `）、` : `）等${protectionLevel1AnimalSpeciesInfoList.length}种${lastSymbol}`,
                        styleList: ['normal_inline_style']
                    });
                }
            })
        }
        if (protectionLevel2AnimalSpeciesInfoList.length) {
            animalProtecteParagraphSliceList.push({
                content: `国家二级重点保护动物`,
                styleList: ['normal_inline_style']
            });
            protectionLevel2AnimalSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
                let lastIndex = protectionLevel2AnimalSpeciesInfoList.length >= 3 ? 2 : protectionLevel2AnimalSpeciesInfoList.length - 1;
                if (speciesInfoIndex <= 2) {
                    animalProtecteParagraphSliceList.push({
                        content: `${speciesInfo.species_c}（`,
                        styleList: ['normal_inline_style']
                    });
                    animalProtecteParagraphSliceList.push({
                        content: `${speciesInfo.canorical_me}`,
                        styleList: ['normal_inline_style', 'ITALIC', 'TimesNewRoman']
                    });
                    animalProtecteParagraphSliceList.push({
                        content: speciesInfoIndex < lastIndex ? `）、` : `）等${protectionLevel2AnimalSpeciesInfoList.length}种。`,
                        styleList: ['normal_inline_style']
                    });
                }
            })
        }
        console.log("animalProtecteParagraphSliceList-->1111", animalProtecteParagraphSliceList)
        //动物数量统计
        let animalQuantitativeStatistics: any[][] = [
            ["类群", "目数", "占总目数％", "科数", "占总科数%", "种数", "占总种数％"]
        ];
        if (mammalOrderNameList.length) {
            animalQuantitativeStatistics.push(
                [
                    "兽类",
                    mammalOrderNameList.length,
                    getPercent(mammalOrderNameList.length, mammalOrderNameList.length + birdOrderNameList.length + amphibiousOrderNameList.length + insectOrderNameList.length + 0),
                    mammalFamilyNameList.length,
                    getPercent(mammalFamilyNameList.length, mammalFamilyNameList.length + birdFamilyNameList.length + amphibiousFamilyNameList.length + insectFamilyNameList.length + 0),
                    mammalSpeciesNameList.length,
                    getPercent(mammalSpeciesNameList.length, mammalSpeciesNameList.length + birdSpeciesNameList.length + amphibiousSpeciesNameList.length + insectSpeciesNameList.length + 0)
                ]
            )
        }
        if (birdOrderNameList.length) {
            animalQuantitativeStatistics.push(
                [
                    "鸟类",
                    birdOrderNameList.length,
                    getPercent(birdOrderNameList.length, mammalOrderNameList.length + birdOrderNameList.length + amphibiousOrderNameList.length + insectOrderNameList.length + 0),
                    birdFamilyNameList.length,
                    getPercent(birdFamilyNameList.length, mammalFamilyNameList.length + birdFamilyNameList.length + amphibiousFamilyNameList.length + insectFamilyNameList.length + 0),
                    birdSpeciesNameList.length,
                    getPercent(birdSpeciesNameList.length, mammalSpeciesNameList.length + birdSpeciesNameList.length + amphibiousSpeciesNameList.length + insectSpeciesNameList.length + 0)
                ]
            )
        }
        if (amphibiousOrderNameList.length) {
            animalQuantitativeStatistics.push(
                [
                    "两爬",
                    amphibiousOrderNameList.length,
                    getPercent(amphibiousOrderNameList.length, mammalOrderNameList.length + birdOrderNameList.length + amphibiousOrderNameList.length + insectOrderNameList.length + 0),
                    amphibiousFamilyNameList.length,
                    getPercent(amphibiousFamilyNameList.length, mammalFamilyNameList.length + birdFamilyNameList.length + amphibiousFamilyNameList.length + insectFamilyNameList.length + 0),
                    amphibiousSpeciesNameList.length,
                    getPercent(amphibiousSpeciesNameList.length, mammalSpeciesNameList.length + birdSpeciesNameList.length + amphibiousSpeciesNameList.length + insectSpeciesNameList.length + 0)
                ]
            )
        }
        if (insectOrderNameList.length) {
            animalQuantitativeStatistics.push(
                [
                    "昆虫",
                    insectOrderNameList.length,
                    getPercent(insectOrderNameList.length, mammalOrderNameList.length + birdOrderNameList.length + amphibiousOrderNameList.length + insectOrderNameList.length + 0),
                    insectFamilyNameList.length,
                    getPercent(insectFamilyNameList.length, mammalFamilyNameList.length + birdFamilyNameList.length + amphibiousFamilyNameList.length + insectFamilyNameList.length + 0),
                    insectSpeciesNameList.length,
                    getPercent(insectSpeciesNameList.length, mammalSpeciesNameList.length + birdSpeciesNameList.length + amphibiousSpeciesNameList.length + insectSpeciesNameList.length + 0)
                ]
            )
        }
        // if(){
        //     animalQuantitativeStatistics.push(
        //         [
        //             "鱼类",
        //             0,
        //             0,
        //             0,
        //             0,
        //             0,
        //             0
        //         ]
        //     )
        // }
        if (animalOrderNameList.length) {
            animalQuantitativeStatistics.push(
                [
                    "总计",
                    animalOrderNameList.length,
                    100.00,
                    animalFamilyNameList.length,
                    100.00,
                    animalSpeciesNameList.length,
                    100.00
                ]
            )
        }
        result.animalQuantitativeStatistics = animalQuantitativeStatistics;
        let animalSpeciesBook: any[][] = [
            ["序号", "纲", "目", "科", "种名", "拉丁文名", "国家保护等级"]
        ]
        animalSpeciesInfoList.forEach(ele => {
            ele.nationa_protection_level = replaceProtectionLevel(ele.nationa_protection_level);
        })
        animalSpeciesInfoList = animalSpeciesInfoList.sort(customSort);
        let animalSpeciesBookContent = [];
        animalSpeciesInfoList.forEach((speciesInfo, speciesInfoIndex) => {
            animalSpeciesBookContent.push(
                [
                    speciesInfoIndex + 1,
                    speciesInfo.class_c,
                    speciesInfo.order_c,
                    speciesInfo.family_c,
                    speciesInfo.species_c,
                    speciesInfo.canorical_me,
                    speciesInfo.nationa_protection_level
                ]
            )
        })
        animalSpeciesBook = animalSpeciesBook.concat(animalSpeciesBookContent);
        result.animalProtecteSpeciesBook = animalSpeciesBook;
    }
    // console.log("result--->", result)
    let wordParagraphList: WordParagraphBlock[] = [];
    if (plantSummaryParagraphSliceList.length) {
        wordParagraphList.push(generateWordParagraphBySliceList(plantSummaryParagraphSliceList));
        wordParagraphList.push(generateWordParagraphBySliceList(plantProtecteParagraphSliceList));
        if (result.plantQuantitativeStatistics.length > 2) {
            wordParagraphList.push({
                type: 'text',
                text: `表  ${protectlandName}野生植物数量统计表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph',
            })
            wordParagraphList.push({
                type: 'Table',
                tableData: result.plantQuantitativeStatistics,
                tableName: `表  ${protectlandName}野生植物数量统计表`,
            })
        }
        wordParagraphList.push({
            type: 'text',
            isSubTable: true,
            text: `表  ${protectlandName}重点保护野生植物名录`,
            paragraphStyle: 'table_name_paragraph',
            paragraphType: 'custom_background_paragraph',
        })
        wordParagraphList.push({
            type: 'Table',
            isSubTable: true,
            tableData: result.plantProtecteSpeciesBook,
            tableName: `表  ${protectlandName}重点保护野生植物名录`,
        })
    }
    if (animalSummaryParagraphSliceList.length) {
        wordParagraphList.push(generateWordParagraphBySliceList(animalSummaryParagraphSliceList));
        wordParagraphList.push(generateWordParagraphBySliceList(animalProtecteParagraphSliceList));
        if (result.animalQuantitativeStatistics.length > 2) {
            wordParagraphList.push({
                type: 'text',
                text: `表  ${protectlandName}野生动物数量统计表`,
                paragraphStyle: 'table_name_paragraph',
                paragraphType: 'custom_background_paragraph',
            })
            wordParagraphList.push({
                type: 'Table',
                tableData: result.animalQuantitativeStatistics,
                tableName: `表  ${protectlandName}野生动物数量统计表`,
            })
        }
        wordParagraphList.push({
            type: 'text',
            isSubTable: true,
            text: `表  ${protectlandName}重点保护野生动物名录`,
            paragraphStyle: 'table_name_paragraph',
            paragraphType: 'custom_background_paragraph',
        })
        wordParagraphList.push({
            type: 'Table',
            isSubTable: true,
            tableData: result.animalProtecteSpeciesBook,
            tableName: `表  ${protectlandName}重点保护野生动物名录`,
        })
    }
    return wordParagraphList;
}


export {
    generateSubTableSpeciesBook
}