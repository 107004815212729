import { Image, Tooltip } from "antd";
import { DocItemExternalData } from "../../types";
import { useState } from "react";
import './DocImage.scss';
import { Close, CloseSmall, ZoomIn } from "@icon-park/react";
import { handleStopPropagation } from "../../../../utils";

interface Props {
    onDelete: Function
    docItemExternalData: DocItemExternalData
}



const DocImage = (props: Props) => {
    const {
        docItemExternalData,
        onDelete
    } = props;

    const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

    const handleOpenPreview = () => {
        setImagePreviewVisible(true)
    }

    const handleDeleteImage = (e) => {
        onDelete && onDelete(e)
    }

    return (
        <div className="doc-image-content">
            <div className="flex-row doc-image-tools">
                <Tooltip
                    title={'大图预览'}
                >
                    <div 
                        className="flex-row doc-image-tool-item"
                        onClick={handleOpenPreview}
                    >
                        <ZoomIn theme="outline" size="20" fill="#ffffff" />
                    </div>
                </Tooltip>
                <Tooltip
                    title={'删除图片'}
                >
                    <div 
                        className="flex-row doc-image-tool-item"
                        onClick={handleDeleteImage}
                    >
                        <CloseSmall theme="outline" size="24" fill="#ffffff" />
                    </div>
                </Tooltip>
            </div>
            <Image
                className="doc-image-el"
                onDoubleClick={handleStopPropagation}
                src={docItemExternalData && docItemExternalData.imageUrl}
                preview={{
                    visible: imagePreviewVisible,
                    movable: false,
                    onVisibleChange: (val) => setImagePreviewVisible(val)
                }}
            />
        </div>
    )
}

export default DocImage;