
export type EnvironmentType = "development" | "production" | "test";

export const getCurrentEnvironment = (): EnvironmentType => {
    let env: EnvironmentType = process.env.NODE_ENV;
    return env;
}

export const isDevelopment = (): Boolean => {
    return getCurrentEnvironment() == 'development';
}