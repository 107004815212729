import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import './../../../components/context-menu/ContextMenu.scss'
import { TopicNodeActionType, TopicType } from "./../../../utils/types"
import { toastShort } from "../../../utils"
import ContextMenuWrapper from "../../../components/context-menu/ContextMenuWrapper"

interface Props {
    onContextMenuClose: Function
    currentTopicNode: TopicType
    onPickerActionType: (TopicNodeActionType) => void
}

const TopicTreeContextMenu = (props: Props, ref: any) => {
    useImperativeHandle(ref, () => ({
        showContextMenu,
        hideContextMenu
    }))

    const {
        onContextMenuClose,
        currentTopicNode,
        onPickerActionType
    } = props;

    const delayTimer = useRef<any>(null);
    const [subContextMenuType, setSubContextMenuType] = useState<'addChildTopic' | 'addPeerTopic'>(null);

    const contextMenuWrapperRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    const diffXRef = useRef(0);

    const showContextMenu = (e) => {
        const editorContainerEl: Element = document.getElementById('ExcelV2ContainerId');
        const { left } = editorContainerEl.getBoundingClientRect();
        let diffX = left;
        e.clientX = e.clientX + diffX + 42;
        // e.clientX = e.screenX;
        diffXRef.current = diffX;       
        e.clientY = e.clientY + 120;
        contextMenuWrapperRef.current.showContextMenu(e);
    }

    const hideContextMenu = () => {
        contextMenuWrapperRef.current.hideContextMenu();
    }

    const _onPickerActionType = (type: TopicNodeActionType) => {
        onPickerActionType && onPickerActionType(type);
        delayTimer.current = setTimeout(() => {
            handleHideContextMenu();
        }, 200)
    }

    const onClickContextMenu = (e: any, type: TopicNodeActionType) => {
        if (!currentTopicNode) {
            return toastShort('error', '未找到操作节点')
        }
        const { target, clientX, clientY } = e;
        const rect = target.getBoundingClientRect();
        let { top, left, right } = rect;
        const rightTop = { x: right - 245 - diffXRef.current, y: top - 150 };
        switch (type) {
            case 'addChildTopic':
                if (currentTopicNode && currentTopicNode.topicType == 'device') {
                    return toastShort('warning', '设施设备不支持继续添加子节点')
                }
                // subContextMenuRef.current.style.zIndex = 1002;
                // subContextMenuRef.current.style.top = rightTop.y + 'px';
                // subContextMenuRef.current.style.left = rightTop.x + 'px';
                setSubContextMenuType('addChildTopic');
                onClickSubContextMenu(e, 'AddDeviceNode');
                break;
            case 'addPeerTopic':
                // subContextMenuRef.current.style.zIndex = 1002;
                // subContextMenuRef.current.style.top = rightTop.y + 'px';
                // subContextMenuRef.current.style.left = rightTop.x + 'px';
                setSubContextMenuType('addPeerTopic')
                break;
            case 'MoveUp':
                _onPickerActionType('MoveUp')
                break;
            case 'MoveDown':
                _onPickerActionType('MoveDown')
                break;
            case 'MoveTop':
                _onPickerActionType('MoveTop')
                break;
            case 'MoveBottom':
                _onPickerActionType('MoveBottom')
                break;
            case 'ReName':
                _onPickerActionType('ReName')
                break;
            case 'Delete':
                _onPickerActionType('Delete')
                break;
            case 'UnChecked':
                _onPickerActionType('UnChecked')
                break;
            default:
                break;
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const onContextMenuContanerMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onContextMenuHide = () => {
        onContextMenuClose && onContextMenuClose();
        // subContextMenuRef.current.style.zIndex = -1;
        // subContextMenuRef.current.style.left = -999 + 'px';
    }

    const onClickSubContextMenu = (e: any, type: 'AddDeviceNode' | 'AddTextNode') => {
        console.log("onClickSubContextMenu---->", type, subContextMenuType)
        switch (type) {
            case 'AddDeviceNode':
                if (subContextMenuType == 'addChildTopic') {
                    _onPickerActionType('AddDeviceChildNode')
                } else {
                    if (currentTopicNode.pid == '0') {
                        return toastShort('warning', '当前层级不可添加项目节点')
                    }
                    _onPickerActionType('AddDevicePeerNode')
                }
                break;
            case 'AddTextNode':
                if (subContextMenuType == 'addChildTopic') {
                    _onPickerActionType('AddTextChildNode')
                } else {
                    _onPickerActionType('AddTextPeerNode')
                }
                break;
            default:
                break;
        }
        e.preventDefault();
        e.stopPropagation();
    }

    const handleHideContextMenu = () => {
        contextMenuWrapperRef.current?.hideContextMenu();
    }

    return (
        <ContextMenuWrapper
            ref={contextMenuWrapperRef}
            onContextMenuHide={onContextMenuHide}
        >
            <div
                className="flex-col context-menu-container"
                onMouseDown={onContextMenuContanerMouseDown}
            >
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'addChildTopic')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-sheshi" />
                        </div>
                        <p className="context-menu-item-title">新增规划项目</p>
                    </div>
                    {/* <div className="context-menu-item-right">
                        <div className="context-menu-item-right-icon">
                            <i className="iconfont-1 ghzs-gray-xiangxia2" />
                        </div>
                    </div> */}
                </div>
                {/* <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'addPeerTopic')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-xinzengtongjijiedian" />
                        </div>
                        <p className="context-menu-item-title">新增同级节点</p>
                    </div>
                    <div className="context-menu-item-right">
                        <div className="context-menu-item-right-icon">
                            <i className="iconfont-1 ghzs-gray-xiangxia2" />
                        </div>
                    </div>
                </div> */}
                <div className="context-menu-item-border"></div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickContextMenu(e, 'UnChecked')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont ghzs-color-shanchu" />
                        </div>
                        <p
                            className="context-menu-item-title"
                            style={{ color: '#D81E06' }}
                        >删除该行</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
            </div>
            {/* <div
                ref={subContextMenuRef}
                className="sub-context-menu-container"
                onMouseDown={onContextMenuContanerMouseDown}
            >
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickSubContextMenu(e, 'AddTextNode')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-wenben" />
                        </div>
                        <p className="context-menu-item-title">大纲节点</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
                <div
                    className="flex-row context-menu-item"
                    onClick={(e) => onClickSubContextMenu(e, 'AddDeviceNode')}
                >
                    <div className="flex-row context-menu-item-left">
                        <div className="context-menu-item-icon">
                            <i className="iconfont-1 ghzs-gray-sheshi" />
                        </div>
                        <p className="context-menu-item-title">项目节点</p>
                    </div>
                    <div className="context-menu-item-right">
                    </div>
                </div>
            </div> */}
        </ContextMenuWrapper>
    )
}


export default forwardRef(TopicTreeContextMenu)