import 'katex/dist/katex.min.css';
import { Form, Input, Tabs, Collapse, Divider, Button, Empty, message, Select } from "antd";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import TeX from '@matejmazur/react-katex';
import { FileSyncOutlined, SnippetsOutlined } from '@ant-design/icons';
import { WordParagraphBlock } from '../../../utils/types';
import { deepCopy, getChangeEventValue, isEmpty, toastShort } from '../../../utils';
import comDocContext from '../../../context/DocContext';
import { convertWordParagraphListToDocItemList } from '../word-editor/editor-doc-item-generate';
import AutoSelectInput from "../../../components/override-antd-kit/AutoSelectInput";

interface FormData1 {
    sightseeingArea: string                  //可游览面积
    areaForeachTourist: string               //每位游客应占有的合理面积
    openEveryDayHours: string                //景区每天开放时间
    travelInAYearDays: string                //年可游天数
    timeItTakesToVisit: string               //游完景区所需时间
    timeForTouristsToVisitEveryDay: string   //游客每天游览最舒适合理的时间
    dailyEnvironmentalCapacity: string       //日环境容量（人次）
    annualEnvironmentalCapacity: string      //年环境容量（万人次）
    dailyTouristCapacity: string             //日游客容量（人次/日）
    annualTouristCapacity: string            //年游客容量（万人次/年）
}

interface FormData2 {
    dailyTouristBatch: string                 //日游客批数（批）
    touristsCountPerBatch: string             //每批游客人数（人次）
    travelInAYearDays: string                 //年可游天数（天）
    timeItTakesToVisit: string                //游完景区所需时间（小时）
    timeForTouristsToVisitEveryDay: string    //游客每天游览最舒适合理的时间（小时）
    dailyEnvironmentalCapacity: string        //日环境容量（人次）
    annualEnvironmentalCapacity: string       //年环境容量（万人次）
    dailyTouristCapacity: string              //日游客容量（人次/日）
    annualTouristCapacity: string             //年游客容量（万人次/年）
}

interface FormData3 {
    lengthOfTheWalkway: string                  //游道全长（米）
    touristOccupiesReasonable: string           //每位游客占用合理游道长度（米）
    openEveryDayHours: string                   //游道全天开放时间（小时）
    timeItTakesToVisit: string                  //游完全游道所需时间（小时）
    timeItTakesToBack: string                   //沿游道返回所需时间（小时）
    travelInAYearDays: string                   //年可游天数（天）
    timeForTouristsToVisitEveryDay: string      //游客每天游览最舒适合理的时间（小时）
    dailyEnvironmentalCapacity: string          //日环境容量（人次）
    annualEnvironmentalCapacity: string         //年环境容量（万人次）
    dailyTouristCapacity: string                //日游客容量（人次/日）
    annualTouristCapacity: string               //年游客容量（万人次/年）
    method: '1' | '2'
}

interface Props {
    insertDocItemByTargetTopicName: Function
}

const TourismCapacity = (props: Props) => {

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const {
        insertDocItemByTargetTopicName
    } = props;

    const { Panel } = Collapse;
    const { Option } = Select;
    const [messageApi, contextHolder] = message.useMessage();

    const [activeType, setActiveType] = useState('1');

    const delayTimer = useRef<any>(null);
    const delayTimer1 = useRef<any>(null);

    const key = 'updatable';

    const [totalArea, setTotalArea] = useState('0');

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current)
        }
    }, [])

    useEffect(() => {
        const {
            protectlandBaseInfo: {
                protectlandArea
            }
        } = comDocInstance;
        setTotalArea(protectlandArea + '')
    }, [])

    const customBackgroundList1: WordParagraphBlock[] = [
        {
            type: 'text',
            text: '生态环境学有关环境容量的理论研究表明，旅游地区游人的增加或超量进入，必然导致旅游服务设施超载、废弃物、污染物以及对旅游区生物种群干扰现象的增加，而造成生态环境质量的下降乃至恶化，旅游区的旅游资源会受到破坏，造成吸引力锐减，并逐渐衰亡。因此，生态旅游开发必须进行环境容量评估、控制游人流量。'
        },
        {
            type: 'text',
            text: '确定的保护区生态旅游环境容量，必须在保证旅游资源量不下降和生态环境不退化的条件下，能够取得最佳经济效益。同时，还必须满足游客的舒适、安全、卫生和方便等旅游需求。'
        },
        {
            type: 'text',
            text: '（1）日环境容量测算'
        },
        {
            type: 'text',
            text: '根据保护区生态旅游区景观特点，环境容量采用面积法进行测算，公式为：',
        },
        {
            type: 'LaTex',
            expressionLaTex: "C= \\frac{A}{a} \\times{D}"
        },
        {
            type: 'text',
            text: '式中C为日环境容量，A为可游览面积，a为每位游客应占有的合理面积，D为周转率（周转率=景区可游时间／游完景区所需时间）。'
        },
        {
            type: 'text',
            text: '根据[NAME]功能区划，生态旅游安排在保护区实验区内，可供旅游的面积约[可游览面积]。考虑到保护区保护工作的开展以及受基础设施的限制，参考类似自然保护区的环境容量结果，确定每位游客应占有的合理面积为[每位游客应占有的合理面积]。景区可游时间按[景区每天开放时间]，游完景区所需时间平均按[游完景区所需时间]计算，其日环境容量为[日环境容量]。'
        },
        {
            type: 'text',
            text: '（2）年环境容量测算'
        },
        {
            type: 'text',
            text: '[NAME]年旅游时间按照[年可游天数]计算，年环境容量为[年环境容量]。'
        },
        {
            type: 'text',
            text: '（3）游客容量估算'
        },
        {
            type: 'text',
            text: '保护区生态旅游项目的开展是以保护为前提，因此，在经营管理上，游人规模控制在低于环境容量的指标。游客容量计算公式为：'
        },
        {
            type: 'LaTex',
            expressionLaTex: "G= \\frac{t}{T} \\times{C}"
        },
        {
            type: 'text',
            text: '式中，G为日游客容量，单位为人次；t为游完景区所需时间；T为游客每天游览最舒适合理的时间；C为日环境容量，单位为人次。'
        },
        {
            type: 'text',
            text: '根据保护区的现状和参考其它保护区开展旅游经验，游客每天游览最舒适合理的时间按照[游客每天游览最舒适合理的时间]计算，保护区的游客日容量约[日游客容量]，游客年容量为[年游客容量]。'
        },
    ]


    const customBackgroundList2: WordParagraphBlock[] = [
        {
            type: 'text',
            text: '生态环境学有关环境容量的理论研究表明，旅游地区游人的增加或超量进入，必然导致旅游服务设施超载、废弃物、污染物以及对旅游区生物种群干扰现象的增加，而造成生态环境质量的下降乃至恶化，旅游区的旅游资源会受到破坏，造成吸引力锐减，并逐渐衰亡。因此，生态旅游开发必须进行环境容量评估、控制游人流量。'
        },
        {
            type: 'text',
            text: '确定的保护区生态旅游环境容量，必须在保证旅游资源量不下降和生态环境不退化的条件下，能够取得最佳经济效益。同时，还必须满足游客的舒适、安全、卫生和方便等旅游需求。'
        },
        {
            type: 'text',
            text: '（1）日环境容量测算'
        },
        {
            type: 'text',
            text: '根据保护区生态旅游区景观特点，环境容量采用卡口法进行测算，公式为：',
        },
        {
            type: 'LaTex',
            expressionLaTex: "C= D \\times{A}"
        },
        {
            type: 'text',
            text: '式中：C为日环境容量，单位为人次；D为日游客批数，D=t1/t3；A为每批游客人数；t1为每天游览时间，t1=H－t2，单位为分；t3为两批游客相距时间，单位为分；H为每天开放时间，单位为分；t2为游完全程所需时间，单位为分。'
        },
        {
            type: 'text',
            text: '根据[NAME]功能区划，生态旅游安排在保护区实验区内，根据旅游区实际情况计算，其日环境容量为[日环境容量]。'
        },
        {
            type: 'text',
            text: '（2）年环境容量测算'
        },
        {
            type: 'text',
            text: '保护区每年6—10月是旅游旺季，年旅游时间按照[年可游天数]计算，年环境容量为[年环境容量]。'
        },
        {
            type: 'text',
            text: '（3）游客容量估算'
        },
        {
            type: 'text',
            text: '保护区生态旅游项目的开展是以保护为前提，因此，在经营管理上，游人规模控制在低于环境容量的指标。游客容量计算公式为：'
        },
        {
            type: 'LaTex',
            expressionLaTex: "G= \\frac{t}{T} \\times{C}"
        },
        {
            type: 'text',
            text: '式中，G为日游客容量，单位为人次；t为游完景区所需时间；T为游客每天游览最舒适合理的时间；C为日环境容量，单位为人次。'
        },
        {
            type: 'text',
            text: '根据保护区的现状和参考其它保护区开展旅游经验，游客游完景区所需时间按照[游完景区所需时间]，游客每天游览最舒适合理的时间按照[游客每天游览最舒适合理的时间]计算，保护区的游客日容量约[日游客容量]，游客年容量为[年游客容量]。'
        },
    ]


    const customBackgroundList3: WordParagraphBlock[] = [
        {
            type: 'text',
            text: '生态环境学有关环境容量的理论研究表明，旅游地区游人的增加或超量进入，必然导致旅游服务设施超载、废弃物、污染物以及对旅游区生物种群干扰现象的增加，而造成生态环境质量的下降乃至恶化，旅游区的旅游资源会受到破坏，造成吸引力锐减，并逐渐衰亡。因此，生态旅游开发必须进行环境容量评估、控制游人流量。'
        },
        {
            type: 'text',
            text: '本着在保证旅游资源和生态环境可持续发展的条件下，能够取得最佳经济效益，同时满足游客的舒适、安全、卫生和方便等旅游要求的原则，计算环境容量和游客数量，按照科学合理的环境容量控制游客规模，达到人与自然的和谐共处。'
        },
        {
            type: 'text',
            text: '（1）日环境容量测算'
        },
        {
            type: 'text',
            text: '根据保护区生态旅游区景观特点，环境容量采用完全游道法进行测算，公式为：',
        },
        {
            type: 'LaTex',
            expressionLaTex: "C= \\frac{M}{m} \\times{D}"
        },
        {
            type: 'text',
            text: '式中：C为日环境容量，单位为人次；M为游道全长，单位为米；m为每位游客占用合理游道长度，单位为米；D为周转率，D=游道全天开放时间/游完全游道所需时间；'
        },
        {
            type: 'text',
            text: '根据[NAME]功能区划，生态旅游安排在保护区实验区内，根据旅游区实际情况计算，每位游客占用合理游道长度按[每位游客占用合理游道长度]取，景区游线长度均按[游道全长]计，周转率取[游道全天开放时间/游完全游道所需时间]，则日环境容量为[日环境容量]。'
        },
        {
            type: 'text',
            text: '（2）年环境容量测算'
        },
        {
            type: 'text',
            text: '保护区每年6—10月是旅游旺季，年旅游时间按照[年可游天数]计算，年环境容量为[年环境容量]。'
        },
        {
            type: 'text',
            text: '（3）游客容量估算'
        },
        // {
        //     type: 'text',
        //     text: '（3）游客容量估算'
        // },
        {
            type: 'text',
            text: '保护区生态旅游项目的开展是以保护为前提，因此，在经营管理上，游人规模控制在低于环境容量的指标。游客容量计算公式为：'
        },
        {
            type: 'LaTex',
            expressionLaTex: "G= \\frac{t}{T} \\times{C}"
        },
        {
            type: 'text',
            text: '式中，G为日游客容量，单位为人次；t为游完全游道所需时间；T为游客每天游览最舒适合理的时间；C为日环境容量，单位为人次。'
        },
        {
            type: 'text',
            text: '根据保护区的现状和参考其它保护区开展旅游经验，游完全游道所需时间按照[游完全游道所需时间]，游客每天游览最舒适合理的时间按照[游客每天游览最舒适合理的时间]计算，保护区的游客日容量约[日游客容量]，游客年容量为[年游客容量]。'
        },
    ]

    const customBackgroundList4: WordParagraphBlock[] = [
        {
            type: 'text',
            text: '生态环境学有关环境容量的理论研究表明，旅游地区游人的增加或超量进入，必然导致旅游服务设施超载、废弃物、污染物以及对旅游区生物种群干扰现象的增加，而造成生态环境质量的下降乃至恶化，旅游区的旅游资源会受到破坏，造成吸引力锐减，并逐渐衰亡。因此，生态旅游开发必须进行环境容量评估、控制游人流量。'
        },
        {
            type: 'text',
            text: '本着在保证旅游资源和生态环境可持续发展的条件下，能够取得最佳经济效益，同时满足游客的舒适、安全、卫生和方便等旅游要求的原则，计算环境容量和游客数量，按照科学合理的环境容量控制游客规模，达到人与自然的和谐共处。'
        },
        {
            type: 'text',
            text: '（1）日环境容量测算'
        },
        {
            type: 'text',
            text: '根据保护区生态旅游区景观特点，环境容量采用不完全游道法进行测算，公式为：',
        },
        {
            type: 'LaTex',
            expressionLaTex: "C= \\frac{M}{m + (m \\times{E/F})} \\times{D}"
        },
        {
            type: 'text',
            text: '式中：C为日环境容量，单位为人次；M为游道全长，单位为米；m为每位游客占用合理游道长度，单位为米；D为周转率，D=游道全天开放时间/游完全游道所需时间；F为游完全游道所需时间；E为沿游道返回所需时间。'
        },
        {
            type: 'text',
            text: '根据[NAME]功能区划，生态旅游安排在保护区实验区内，根据旅游区实际情况计算，每位游客占用合理游道长度按[每位游客占用合理游道长度]取，景区游线长度均按[游道全长]计，周转率取[游道全天开放时间/游完全游道所需时间]，游完全游道所需时间取[游完全游道所需时间]，沿游道返回所需时间取[沿游道返回所需时间]，则日环境容量为[日环境容量]。'
        },
        {
            type: 'text',
            text: '（2）年环境容量测算'
        },
        {
            type: 'text',
            text: '保护区每年6—10月是旅游旺季，年旅游时间按照[年可游天数]计算，年环境容量为[年环境容量]。'
        },
        {
            type: 'text',
            text: '（3）游客容量估算'
        },
        {
            type: 'text',
            text: '保护区生态旅游项目的开展是以保护为前提，因此，在经营管理上，游人规模控制在低于环境容量的指标。游客容量计算公式为：'
        },
        {
            type: 'LaTex',
            expressionLaTex: "G= \\frac{t}{T} \\times{C}"
        },
        {
            type: 'text',
            text: '式中，G为日游客容量，单位为人次；t为游完全游道所需时间；T为游客每天游览最舒适合理的时间；C为日环境容量，单位为人次。'
        },
        {
            type: 'text',
            text: '根据保护区的现状和参考其它保护区开展旅游经验，游完全游道所需时间按照[游完全游道所需时间]，游客每天游览最舒适合理的时间按照[游客每天游览最舒适合理的时间]计算，保护区的游客日容量约[日游客容量]，游客年容量为[年游客容量]。'
        },
    ]


    const [form1] = Form.useForm<FormData1>();
    const [form2] = Form.useForm<FormData2>();
    const [form3] = Form.useForm<FormData2>();

    const [formData1, setFormData1] = useState<FormData1>({
        sightseeingArea: "",                 //可游览面积
        areaForeachTourist: "",              //每位游客应占有的合理面积
        openEveryDayHours: "",               //景区每天开放时间
        travelInAYearDays: "",               //年可游天数
        timeItTakesToVisit: "",              //游完景区所需时间
        timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间
        dailyEnvironmentalCapacity: "",            //日环境容量（人次）
        annualEnvironmentalCapacity: "",           //年环境容量（万人次）
        dailyTouristCapacity: "",                  //日游客容量（人次/日）
        annualTouristCapacity: "",
    })

    const formData1Ref = useRef<FormData1>({
        sightseeingArea: "",                 //可游览面积
        areaForeachTourist: "",              //每位游客应占有的合理面积
        openEveryDayHours: "",               //景区每天开放时间
        travelInAYearDays: "",               //年可游天数
        timeItTakesToVisit: "",              //游完景区所需时间
        timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间
        dailyEnvironmentalCapacity: "",            //日环境容量（人次）
        annualEnvironmentalCapacity: "",           //年环境容量（万人次）
        dailyTouristCapacity: "",                  //日游客容量（人次/日）
        annualTouristCapacity: "",
    })

    const [formData2, setFormData2] = useState<FormData2>({
        dailyTouristBatch: "",                 //日游客批数（批）
        touristsCountPerBatch: "",             //每批游客人数（人次）
        travelInAYearDays: "",                 //年可游天数（天）
        timeItTakesToVisit: "",                //游完景区所需时间（小时）
        timeForTouristsToVisitEveryDay: "",    //游客每天游览最舒适合理的时间（小时）
        dailyEnvironmentalCapacity: "",        //日环境容量（人次）
        annualEnvironmentalCapacity: "",       //年环境容量（万人次）
        dailyTouristCapacity: "",              //日游客容量（人次/日）
        annualTouristCapacity: "",             //年游客容量（万人次/年）
    })

    const formData2Ref = useRef<FormData2>({
        dailyTouristBatch: "",                 //日游客批数（批）
        touristsCountPerBatch: "",             //每批游客人数（人次）
        travelInAYearDays: "",                 //年可游天数（天）
        timeItTakesToVisit: "",                //游完景区所需时间（小时）
        timeForTouristsToVisitEveryDay: "",    //游客每天游览最舒适合理的时间（小时）
        dailyEnvironmentalCapacity: "",        //日环境容量（人次）
        annualEnvironmentalCapacity: "",       //年环境容量（万人次）
        dailyTouristCapacity: "",              //日游客容量（人次/日）
        annualTouristCapacity: "",             //年游客容量（万人次/年）
    })

    const [formData3, setFormData3] = useState<FormData3>({
        lengthOfTheWalkway: "",                    //游道全长（米）
        touristOccupiesReasonable: "",             //每位游客占用合理游道长度（米）
        openEveryDayHours: "",                     //游道全天开放时间（小时）
        timeItTakesToVisit: "",                    //游完全游道所需时间（小时）
        timeItTakesToBack: "",                     //沿游道返回所需时间（小时）
        travelInAYearDays: "",                     //年可游天数（天）
        timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间（小时）
        dailyEnvironmentalCapacity: "",            //日环境容量（人次）
        annualEnvironmentalCapacity: "",           //年环境容量（万人次）
        dailyTouristCapacity: "",                  //日游客容量（人次/日）
        annualTouristCapacity: "",                 //年游客容量（万人次/年）
        method: '1',
    })

    const formData3Ref = useRef<FormData3>({
        lengthOfTheWalkway: "",                    //游道全长（米）
        touristOccupiesReasonable: "",             //每位游客占用合理游道长度（米）
        openEveryDayHours: "",                     //游道全天开放时间（小时）
        timeItTakesToVisit: "",                    //游完全游道所需时间（小时）
        timeItTakesToBack: "",                     //沿游道返回所需时间（小时）
        travelInAYearDays: "",                     //年可游天数（天）
        timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间（小时）
        dailyEnvironmentalCapacity: "",            //日环境容量（人次）
        annualEnvironmentalCapacity: "",           //年环境容量（万人次）
        dailyTouristCapacity: "",                  //日游客容量（人次/日）
        annualTouristCapacity: "",                 //年游客容量（万人次/年）
        method: '1',
    })

    const [methodOptions] = useState([
        {
            label: "完全游道法",
            value: '1'
        },
        {
            label: "不完全游道法",
            value: '2'
        },
    ])

    const replaceByFromData1 = (originText: string) => {
        const {
            protectland: {
                type,
                subType,
            },
            protectlandBaseInfo: {
                protectlandArea,
                protectlandAreaUnit = '公顷',
                protectlandLevel,
                protectlandName,
                protectlandProvince,
                protectlandTypeId,
                startYear,
                endYear
            }
        } = comDocInstance;

        const {
            sightseeingArea,
            areaForeachTourist,
            openEveryDayHours,
            travelInAYearDays,
            timeItTakesToVisit,
            timeForTouristsToVisitEveryDay,
            dailyEnvironmentalCapacity,
            annualEnvironmentalCapacity,
            dailyTouristCapacity,
            annualTouristCapacity
        } = formData1Ref.current;

        const nameReg = /\[name\]/gi;
        const sightseeingAreaReg = /\[可游览面积\]/gi;
        const areaForeachTouristReg = /\[每位游客应占有的合理面积\]/gi;
        const openEveryDayHoursReg = /\[景区每天开放时间\]/gi;
        const dailyEnvironmentalCapacityReg = /\[日环境容量\]/gi;  //人次
        const travelInAYearDaysReg = /\[年可游天数\]/gi;
        const annualEnvironmentalCapacityReg = /\[年环境容量\]/gi;  //万人次
        const timeForTouristsToVisitEveryDayReg = /\[游客每天游览最舒适合理的时间\]/gi;
        const dailyTouristCapacityReg = /\[日游客容量\]/gi;   //人次/日
        const annualTouristCapacityReg = /\[年游客容量\]/gi;  //万人次/年
        const timeItTakesToVisitReg = /\[游完景区所需时间\]/gi;  //小时

        originText = originText.replace(nameReg, protectlandName);
        originText = originText.replace(sightseeingAreaReg, sightseeingArea + '公顷');
        originText = originText.replace(areaForeachTouristReg, areaForeachTourist + '公顷');
        originText = originText.replace(openEveryDayHoursReg, openEveryDayHours + '小时');
        originText = originText.replace(dailyEnvironmentalCapacityReg, dailyEnvironmentalCapacity + '人次');
        originText = originText.replace(travelInAYearDaysReg, travelInAYearDays + '天');
        originText = originText.replace(annualEnvironmentalCapacityReg, annualEnvironmentalCapacity + '万人次');
        originText = originText.replace(timeForTouristsToVisitEveryDayReg, timeForTouristsToVisitEveryDay + '小时');
        originText = originText.replace(dailyTouristCapacityReg, dailyTouristCapacity + '人次/日');
        originText = originText.replace(annualTouristCapacityReg, annualTouristCapacity + '万人次/年');
        originText = originText.replace(timeItTakesToVisitReg, timeItTakesToVisit + '小时');
        return originText;
    }

    const replaceByFromData2 = (originText: string) => {
        const {
            protectland: {
                type,
                subType,
            },
            protectlandBaseInfo: {
                protectlandArea,
                protectlandAreaUnit = '公顷',
                protectlandLevel,
                protectlandName,
                protectlandProvince,
                protectlandTypeId,
                startYear,
                endYear
            }
        } = comDocInstance;

        const {
            dailyTouristBatch,                 //日游客批数（批）
            touristsCountPerBatch,             //每批游客人数（人次）
            travelInAYearDays,                 //年可游天数（天）
            timeItTakesToVisit,                //游完景区所需时间（小时）
            timeForTouristsToVisitEveryDay,    //游客每天游览最舒适合理的时间（小时）
            dailyEnvironmentalCapacity,        //日环境容量（人次）
            annualEnvironmentalCapacity,       //年环境容量（万人次）
            dailyTouristCapacity,              //日游客容量（人次/日）
            annualTouristCapacity,             //年游客容量（万人次/年）
        } = formData2Ref.current;

        const nameReg = /\[name\]/gi;
        const dailyTouristBatchReg = /\[可游览面积\]/gi;
        const dailyEnvironmentalCapacityReg = /\[日环境容量\]/gi;  //人次   //
        const travelInAYearDaysReg = /\[年可游天数\]/gi;  //
        const timeItTakesToVisitReg = /\[游完景区所需时间\]/gi; //游完景区所需时间
        const annualEnvironmentalCapacityReg = /\[年环境容量\]/gi;  //万人次
        const timeForTouristsToVisitEveryDayReg = /\[游客每天游览最舒适合理的时间\]/gi;    ///
        const dailyTouristCapacityReg = /\[日游客容量\]/gi;   //人次/日    //
        const annualTouristCapacityReg = /\[年游客容量\]/gi;  //万人次/年  //

        originText = originText.replace(nameReg, protectlandName);
        originText = originText.replace(timeItTakesToVisitReg, timeItTakesToVisit + '小时');
        originText = originText.replace(dailyEnvironmentalCapacityReg, dailyEnvironmentalCapacity + '人次');
        originText = originText.replace(travelInAYearDaysReg, travelInAYearDays + '天');
        originText = originText.replace(annualEnvironmentalCapacityReg, annualEnvironmentalCapacity + '万人次');
        originText = originText.replace(timeForTouristsToVisitEveryDayReg, timeForTouristsToVisitEveryDay + '小时');
        originText = originText.replace(dailyTouristCapacityReg, dailyTouristCapacity + '人次/日');
        originText = originText.replace(annualTouristCapacityReg, annualTouristCapacity + '万人次/年');
        return originText;
    }



    const replaceByFromData3 = (originText: string) => {
        const {
            protectland: {
                type,
                subType,
            },
            protectlandBaseInfo: {
                protectlandArea,
                protectlandAreaUnit = '公顷',
                protectlandLevel,
                protectlandName,
                protectlandProvince,
                protectlandTypeId,
                startYear,
                endYear
            }
        } = comDocInstance;

        const {
            lengthOfTheWalkway,                  //游道全长（米）
            touristOccupiesReasonable,           //每位游客占用合理游道长度（米）
            openEveryDayHours,                   //游道全天开放时间（小时）
            timeItTakesToVisit,                  //游完全游道所需时间（小时）
            timeItTakesToBack,                   //沿游道返回所需时间（小时）
            travelInAYearDays,                   //年可游天数（天）
            timeForTouristsToVisitEveryDay,      //游客每天游览最舒适合理的时间（小时）
            dailyEnvironmentalCapacity,          //日环境容量（人次）
            annualEnvironmentalCapacity,         //年环境容量（万人次）
            dailyTouristCapacity,                //日游客容量（人次/日）
            annualTouristCapacity,               //年游客容量（万人次/年）
        } = formData3Ref.current;

        const nameReg = /\[name\]/gi;
        const dailyEnvironmentalCapacityReg = /\[日环境容量\]/gi;  //人次 
        const travelInAYearDaysReg = /\[年可游天数\]/gi;  //
        const annualEnvironmentalCapacityReg = /\[年环境容量\]/gi;  //万人次
        const timeForTouristsToVisitEveryDayReg = /\[游客每天游览最舒适合理的时间\]/gi;
        const dailyTouristCapacityReg = /\[日游客容量\]/gi;   //人次/日   
        const annualTouristCapacityReg = /\[年游客容量\]/gi;  //万人次/年

        const lengthOfTheWalkwayReg = /\[游道全长\]/gi;                       //游道全长
        const turnoverRateReg = /\[游道全天开放时间\/游完全游道所需时间\]/gi;     //游道全天开放时间/游完全游道所需时间
        const timeItTakesToVisitReg = /\[游完全游道所需时间\]/gi;              //游完全游道所需时间 
        const touristOccupiesReasonableReg = /\[每位游客占用合理游道长度\]/gi;  //每位游客占用合理游道长度
        const timeItTakesToBackReg = /\[沿游道返回所需时间\]/gi;               //沿游道返回所需时间

        originText = originText.replace(nameReg, protectlandName);
        originText = originText.replace(timeItTakesToVisitReg, timeItTakesToVisit + '小时');
        originText = originText.replace(dailyEnvironmentalCapacityReg, dailyEnvironmentalCapacity + '人次');
        originText = originText.replace(travelInAYearDaysReg, travelInAYearDays + '天');
        originText = originText.replace(annualEnvironmentalCapacityReg, annualEnvironmentalCapacity + '万人次');
        originText = originText.replace(timeForTouristsToVisitEveryDayReg, timeForTouristsToVisitEveryDay + '小时');
        originText = originText.replace(dailyTouristCapacityReg, dailyTouristCapacity + '人次/日');
        originText = originText.replace(annualTouristCapacityReg, annualTouristCapacity + '万人次/年');

        originText = originText.replace(lengthOfTheWalkwayReg, lengthOfTheWalkway + '米');
        originText = originText.replace(turnoverRateReg, (Number(openEveryDayHours) / Number(timeItTakesToVisit)).toFixed(1));
        originText = originText.replace(touristOccupiesReasonableReg, touristOccupiesReasonable + '米');
        originText = originText.replace(timeItTakesToBackReg, timeItTakesToBack + '小时');
        return originText;
    }

    const clearAllResult = () => {
        form1.resetFields && form1.resetFields();
        form2.resetFields && form2.resetFields();
        form3.resetFields && form3.resetFields();
        setFormData1({
            sightseeingArea: "",                 //可游览面积
            areaForeachTourist: "",              //每位游客应占有的合理面积
            openEveryDayHours: "",               //景区每天开放时间
            travelInAYearDays: "",               //年可游天数
            timeItTakesToVisit: "",              //游完景区所需时间
            timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间
            dailyEnvironmentalCapacity: "",            //日环境容量（人次）
            annualEnvironmentalCapacity: "",           //年环境容量（万人次）
            dailyTouristCapacity: "",                  //日游客容量（人次/日）
            annualTouristCapacity: "",
        })
        setFormData2({
            dailyTouristBatch: "",                 //日游客批数（批）
            touristsCountPerBatch: "",             //每批游客人数（人次）
            travelInAYearDays: "",                 //年可游天数（天）
            timeItTakesToVisit: "",                //游完景区所需时间（小时）
            timeForTouristsToVisitEveryDay: "",    //游客每天游览最舒适合理的时间（小时）
            dailyEnvironmentalCapacity: "",        //日环境容量（人次）
            annualEnvironmentalCapacity: "",       //年环境容量（万人次）
            dailyTouristCapacity: "",              //日游客容量（人次/日）
            annualTouristCapacity: "",             //年游客容量（万人次/年）
        })
        setFormData3({
            lengthOfTheWalkway: "",                    //游道全长（米）
            touristOccupiesReasonable: "",             //每位游客占用合理游道长度（米）
            openEveryDayHours: "",                     //游道全天开放时间（小时）
            timeItTakesToVisit: "",                    //游完全游道所需时间（小时）
            timeItTakesToBack: "",                     //沿游道返回所需时间（小时）
            travelInAYearDays: "",                     //年可游天数（天）
            timeForTouristsToVisitEveryDay: "",        //游客每天游览最舒适合理的时间（小时）
            dailyEnvironmentalCapacity: "",            //日环境容量（人次）
            annualEnvironmentalCapacity: "",           //年环境容量（万人次）
            dailyTouristCapacity: "",                  //日游客容量（人次/日）
            annualTouristCapacity: "",                 //年游客容量（万人次/年）
            method: '1',
        })
    };

    const renderCustomBackground = (customBackground: WordParagraphBlock, customBackgroundIndex: number) => {
        switch (customBackground.type) {
            case 'text':
                return (
                    <p
                        key={customBackgroundIndex + ''}
                        className={'custom_paragraph_6_style'}
                    >{replaceByFromData1(customBackground.text)}</p>
                )
                break;
            case 'LaTex':
                return (
                    <div
                        className='custom_paragraph_6_style_rule'
                    >
                        <TeX
                            key={customBackgroundIndex + ''}
                            math={customBackground.expressionLaTex}
                        />
                    </div>
                )
                break;
            case 'Table':
                break;
            default:
                break;
        }
    }

    const renderCustomBackground2 = (customBackground: WordParagraphBlock, customBackgroundIndex: number) => {
        switch (customBackground.type) {
            case 'text':
                return (
                    <p
                        key={customBackgroundIndex + ''}
                        className={'custom_paragraph_6_style'}
                    >{replaceByFromData2(customBackground.text)}</p>
                )
                break;
            case 'LaTex':
                return (
                    <div
                        className='custom_paragraph_6_style_rule'
                    >
                        <TeX
                            key={customBackgroundIndex + ''}
                            math={customBackground.expressionLaTex}
                        />
                    </div>
                )
                break;
            case 'Table':
                break;
            default:
                break;
        }
    }

    const renderCustomBackground3 = (customBackground: WordParagraphBlock, customBackgroundIndex: number) => {
        switch (customBackground.type) {
            case 'text':
                return (
                    <p
                        key={customBackgroundIndex + ''}
                        className={'custom_paragraph_6_style'}
                    >{replaceByFromData3(customBackground.text)}</p>
                )
                break;
            case 'LaTex':
                return (
                    <div
                        className='custom_paragraph_6_style_rule'
                    >
                        <TeX
                            key={customBackgroundIndex + ''}
                            math={customBackground.expressionLaTex}
                        />
                    </div>
                )
                break;
            case 'Table':
                break;
            default:
                break;
        }
    }

    const handleSetFormData1 = (target: string, value: any) => {
        let _formData = formData1;
        //@ts-ignore
        _formData[target] = value;
        _formData = deepCopy(_formData);
        setFormData1(_formData);
        formData1Ref.current = _formData;
    };

    const handleSetFormData2 = (target: string, value: any) => {
        let _formData = formData2;
        //@ts-ignore
        _formData[target] = value;
        _formData = deepCopy(_formData);
        setFormData2(_formData);
        formData2Ref.current = _formData;
    };

    const handleSetFormData3 = (target: string, value: any) => {
        let _formData = formData3;
        //@ts-ignore
        _formData[target] = value;
        _formData = deepCopy(_formData);
        setFormData3(_formData);
        formData3Ref.current = _formData;
    };

    const onSightseeingAreaTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        // handleSetFormData1('sightseeingArea', getChangeEventValue(e))
        const value = getChangeEventValue(e);
        if (Number(value) <= Number(totalArea)) {
            handleSetFormData1('sightseeingArea', value)
        } else {
            toastShort('error', '可游览面积不超过保护区总面积')
            form1.setFieldValue('sightseeingArea', totalArea)
            handleSetFormData1('sightseeingArea', totalArea)
        }
    }

    const onAreaForeachTouristTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData1('areaForeachTourist', getChangeEventValue(e))
    }

    const onOpenEveryDayHoursTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        // handleSetFormData1('openEveryDayHours', getChangeEventValue(e))
        const value = getChangeEventValue(e);
        if (Number(value) <= 24) {
            handleSetFormData1('openEveryDayHours', value)
        } else {
            toastShort('error', '景区每天开放时间最长不超过24小时')
            form1.setFieldValue('openEveryDayHours', '24')
            handleSetFormData1('openEveryDayHours', '24')
        }
    }

    const onTravelInAYearDaysTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) <= 365) {
            handleSetFormData1('travelInAYearDays', value)
        } else {
            toastShort('error', '年可游天数最大不超过365天')
            form1.setFieldValue('travelInAYearDays', '365')
            handleSetFormData1('travelInAYearDays', '365')
        }
    }

    const onTimeItTakesToVisitTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData1('timeItTakesToVisit', getChangeEventValue(e))
    }

    const onTimeForTouristsToVisitEveryDayTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) <= 24) {
            handleSetFormData1('timeForTouristsToVisitEveryDay', value)
        } else {
            toastShort('error', '游客每天游览最舒适合理的时间最大不超过24小时')
            form1.setFieldValue('timeForTouristsToVisitEveryDay', '24')
            handleSetFormData1('timeForTouristsToVisitEveryDay', '24')
        }
    }

    /***************** 2 *****************/

    const onDailyTouristBatchTextChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData2('dailyTouristBatch', getChangeEventValue(e))
    }

    const onTouristsCountPerBatchTextChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData2('touristsCountPerBatch', getChangeEventValue(e))
    }

    const onTravelInAYearDaysTextChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) <= 365) {
            handleSetFormData2('travelInAYearDays', value)
        } else {
            toastShort('error', '年可游天数最大不超过365天')
            form2.setFieldValue('travelInAYearDays', '365')
            handleSetFormData2('travelInAYearDays', '365')
        }
    }

    const onTimeItTakesToVisitTextChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData2('timeItTakesToVisit', getChangeEventValue(e))
    }

    const onTimeForTouristsToVisitEveryDayTextChange2 = (e: ChangeEvent<HTMLInputElement>) => {
        // handleSetFormData2('timeForTouristsToVisitEveryDay', getChangeEventValue(e))
        const value = getChangeEventValue(e);
        if (Number(value) <= 24) {
            handleSetFormData2('timeForTouristsToVisitEveryDay', value)
        } else {
            toastShort('error', '游客每天游览最舒适合理的时间最大不超过24小时')
            form2.setFieldValue('timeForTouristsToVisitEveryDay', '24')
            handleSetFormData2('timeForTouristsToVisitEveryDay', '24')
        }
    }

    /***************** 3 *****************/

    const onLengthOfTheWalkwayTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData3('lengthOfTheWalkway', getChangeEventValue(e))
    }

    const onTouristOccupiesReasonableTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData3('touristOccupiesReasonable', getChangeEventValue(e))
    }

    const onOpenEveryDayHoursTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        // handleSetFormData3('openEveryDayHours', getChangeEventValue(e))
        const value = getChangeEventValue(e);
        if (Number(value) <= 24) {
            handleSetFormData2('openEveryDayHours', value)
        } else {
            toastShort('error', '景区每天开放时间最长不超过24小时')
            form2.setFieldValue('openEveryDayHours', '24')
            handleSetFormData2('openEveryDayHours', '24')
        }
    }

    const onTimeItTakesToVisitTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData3('timeItTakesToVisit', getChangeEventValue(e))
    }

    const onTimeItTakesToBackTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetFormData3('timeItTakesToBack', getChangeEventValue(e))
    }

    const onTravelInAYearDaysTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        const value = getChangeEventValue(e);
        if (Number(value) <= 365) {
            handleSetFormData3('travelInAYearDays', value)
        } else {
            toastShort('error', '年可游天数最大不超过365天')
            form3.setFieldValue('travelInAYearDays', '365')
            handleSetFormData3('travelInAYearDays', '365')
        }
    }

    const onTimeForTouristsToVisitEveryDayTextChange3 = (e: ChangeEvent<HTMLInputElement>) => {
        // handleSetFormData3('timeForTouristsToVisitEveryDay', getChangeEventValue(e))
        const value = getChangeEventValue(e);
        if (Number(value) <= 24) {
            handleSetFormData3('timeForTouristsToVisitEveryDay', value)
        } else {
            toastShort('error', '游客每天游览最舒适合理的时间最大不超过24小时')
            form3.setFieldValue('timeForTouristsToVisitEveryDay', '24')
            handleSetFormData3('timeForTouristsToVisitEveryDay', '24')
        }
    }

    const onMethodChange3 = (method: string) => {
        handleSetFormData3('method', method)
    }

    const generateResult1 = async () => {
        try {
            const validate = await form1.validateFields();
            if (validate) {
                console.log("formData1---->", formData1)
                const {
                    sightseeingArea,
                    areaForeachTourist,
                    openEveryDayHours,
                    travelInAYearDays,
                    timeItTakesToVisit,
                    timeForTouristsToVisitEveryDay,
                } = formData1Ref.current;
                const _turnoverRate = Number(openEveryDayHours) / Number(timeItTakesToVisit);
                console.log("_turnoverRate--->", _turnoverRate)
                let _dailyEnvironmentalCapacity = (Number(sightseeingArea) / Number(areaForeachTourist) * Number(_turnoverRate)).toFixed(0);
                console.log("_dailyEnvironmentalCapacity---->", _dailyEnvironmentalCapacity)
                let _annualEnvironmentalCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                console.log("_annualEnvironmentalCapacity---->", _annualEnvironmentalCapacity + '万人')
                let _dailyTouristCapacity = (Number(timeItTakesToVisit) / Number(timeForTouristsToVisitEveryDay) * Number(_dailyEnvironmentalCapacity)).toFixed(0);
                let _annualTouristCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                console.log("_annualTouristCapacity--->", _annualTouristCapacity)
                let _formData1 = formData1;
                _formData1.dailyEnvironmentalCapacity = _dailyEnvironmentalCapacity;
                _formData1.annualEnvironmentalCapacity = _annualEnvironmentalCapacity;
                _formData1.dailyTouristCapacity = _dailyTouristCapacity;
                _formData1.annualTouristCapacity = _annualTouristCapacity;
                setFormData1(deepCopy(_formData1))
            }
        } catch (e) {

        }
    }

    const generateResult2 = async () => {
        try {
            const validate = await form2.validateFields();
            if (validate) {
                console.log("formData2---->", formData2)
                const {
                    dailyTouristBatch,                 //日游客批数（批）
                    touristsCountPerBatch,             //每批游客人数（人次）
                    travelInAYearDays,                 //年可游天数（天）
                    timeItTakesToVisit,                //游完景区所需时间（小时）
                    timeForTouristsToVisitEveryDay,    //游客每天游览最舒适合理的时间（小时）
                } = formData2Ref.current;
                let _dailyEnvironmentalCapacity = (Number(dailyTouristBatch) * Number(touristsCountPerBatch)).toFixed(0);
                console.log("_dailyEnvironmentalCapacity---->", _dailyEnvironmentalCapacity)
                let _annualEnvironmentalCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                console.log("_annualEnvironmentalCapacity---->", _annualEnvironmentalCapacity + '万人')
                let _dailyTouristCapacity = (Number(timeItTakesToVisit) / Number(timeForTouristsToVisitEveryDay) * Number(_dailyEnvironmentalCapacity)).toFixed(0);
                let _annualTouristCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                console.log("_annualTouristCapacity--->", _annualTouristCapacity)
                let _formData2 = formData2;
                _formData2.dailyEnvironmentalCapacity = _dailyEnvironmentalCapacity;
                _formData2.annualEnvironmentalCapacity = _annualEnvironmentalCapacity;
                _formData2.dailyTouristCapacity = _dailyTouristCapacity;
                _formData2.annualTouristCapacity = _annualTouristCapacity;
                setFormData2(deepCopy(_formData2))
                formData2Ref.current = deepCopy(_formData2);
            }
        } catch (e) {

        }
    }

    const generateResult3 = async () => {
        try {
            const validate = await form3.validateFields();
            if (validate) {
                console.log("formData3---->", formData3)
                const {
                    lengthOfTheWalkway,                //游道全长（米）
                    touristOccupiesReasonable,         //每位游客占用合理游道长度（米）
                    openEveryDayHours,                 //游道全天开放时间（小时）
                    timeItTakesToVisit,                //游完全游道所需时间（小时）
                    timeItTakesToBack,                 //沿游道返回所需时间（小时）
                    travelInAYearDays,                 //年可游天数（天）
                    timeForTouristsToVisitEveryDay,    //游客每天游览最舒适合理的时间（小时）
                    method,
                } = formData3Ref.current;
                let _formData3 = formData3;
                if (method == '1') {
                    console.log("使用完全游道法------>")
                    //周转率
                    const _turnoverRate = Number(openEveryDayHours) / Number(timeItTakesToVisit);
                    console.log("_turnoverRate--->", _turnoverRate)
                    //日环境容量
                    let _dailyEnvironmentalCapacity = (Number(lengthOfTheWalkway) / Number(touristOccupiesReasonable) * Number(_turnoverRate)).toFixed(0);
                    console.log("_dailyEnvironmentalCapacity---->", _dailyEnvironmentalCapacity)
                    //年环境容量
                    let _annualEnvironmentalCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                    console.log("_annualEnvironmentalCapacity---->", _annualEnvironmentalCapacity + '万人')
                    //日游客容量
                    let _dailyTouristCapacity = (Number(timeItTakesToVisit) / Number(timeForTouristsToVisitEveryDay) * Number(_dailyEnvironmentalCapacity)).toFixed(0);
                    //年游客容量
                    let _annualTouristCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                    console.log("_annualTouristCapacity--->", _annualTouristCapacity)
                    //更新结果
                    _formData3.dailyEnvironmentalCapacity = _dailyEnvironmentalCapacity;
                    _formData3.annualEnvironmentalCapacity = _annualEnvironmentalCapacity;
                    _formData3.dailyTouristCapacity = _dailyTouristCapacity;
                    _formData3.annualTouristCapacity = _annualTouristCapacity;
                } else {
                    console.log("使用不完全游道法------>")
                    //周转率
                    const _turnoverRate = Number(openEveryDayHours) / Number(timeItTakesToVisit);
                    console.log("_turnoverRate--->", _turnoverRate)
                    //日环境容量
                    let _dailyEnvironmentalCapacity = (Number(lengthOfTheWalkway) / (Number(touristOccupiesReasonable) + (Number(touristOccupiesReasonable) * Number(timeItTakesToBack) / Number(timeItTakesToVisit))) * _turnoverRate).toFixed(0);
                    console.log("_dailyEnvironmentalCapacity---->", _dailyEnvironmentalCapacity)
                    //年环境容量
                    let _annualEnvironmentalCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                    console.log("_annualEnvironmentalCapacity---->", _annualEnvironmentalCapacity + '万人')
                    //日游客容量
                    let _dailyTouristCapacity = (Number(timeItTakesToVisit) / Number(timeForTouristsToVisitEveryDay) * Number(_dailyEnvironmentalCapacity)).toFixed(0);
                    //年游客容量
                    let _annualTouristCapacity = (Number(_dailyEnvironmentalCapacity) * Number(travelInAYearDays) / 10000).toFixed(0);
                    console.log("_annualTouristCapacity--->", _annualTouristCapacity)
                    //更新结果
                    _formData3.dailyEnvironmentalCapacity = _dailyEnvironmentalCapacity;
                    _formData3.annualEnvironmentalCapacity = _annualEnvironmentalCapacity;
                    _formData3.dailyTouristCapacity = _dailyTouristCapacity;
                    _formData3.annualTouristCapacity = _annualTouristCapacity;
                }
                setFormData3(deepCopy(_formData3))
                formData3Ref.current = deepCopy(_formData3);
            }
        } catch (e) {

        }
    }


    const insertCustomBackgroundList = async () => {
        try {
            const validate = await form1.validateFields();
        } catch (e) {
            return toastShort('warning', '请完成面积法参数填写')
        }
        try {
            messageApi.open({
                key,
                type: 'loading',
                content: '正在生成最新报告...',
                duration: 0,
            });
            generateResult1();
            // delayTimer.current = setTimeout(() => {
            //     messageApi.open({
            //         key,
            //         type: 'success',
            //         content: '已生成!',
            //         duration: 200,
            //     });
            // }, 500);
            delayTimer.current = setTimeout(() => {
                let wordParagraphList: WordParagraphBlock[] = deepCopy(customBackgroundList1);
                wordParagraphList.forEach(ele => {
                    if (ele.type == 'text') {
                        ele.text = replaceByFromData1(ele.text)
                    }
                })
                insertDocItemByTargetTopicName &&
                    insertDocItemByTargetTopicName([{
                        topicName: "环境容量分析",
                        docItemList: convertWordParagraphListToDocItemList(wordParagraphList)
                    }])
                messageApi.destroy();
                clearAllResult();
            }, 700);
        } catch (e) {
        }

    }

    const insertCustomBackgroundList2 = async () => {
        try {
            const validate = await form2.validateFields();
        } catch (e) {
            return toastShort('warning', '请完成卡口法参数填写')
        }
        try {
            messageApi.open({
                key,
                type: 'loading',
                content: '正在生成最新报告...',
                duration: 0,
            });
            generateResult2();
            // delayTimer.current = setTimeout(() => {
            //     messageApi.open({
            //         key,
            //         type: 'success',
            //         content: '已生成!',
            //         duration: 200,
            //     });
            // }, 500);
            delayTimer.current = setTimeout(() => {
                let _tempCustomBackgroundList2: WordParagraphBlock[] = deepCopy(customBackgroundList2);
                _tempCustomBackgroundList2.forEach(ele => {
                    if (ele.type == 'text') {
                        ele.text = replaceByFromData2(ele.text)
                    }
                })
                insertDocItemByTargetTopicName &&
                    insertDocItemByTargetTopicName([{
                        topicName: "环境容量分析",
                        docItemList: convertWordParagraphListToDocItemList(_tempCustomBackgroundList2)
                    }]);
                messageApi.destroy();
                clearAllResult();
            }, 700);
        } catch (e) {
        }
    }


    const insertCustomBackgroundList3 = async () => {
        try {
            const validate = await form3.validateFields();
        } catch (e) {
            return toastShort('warning', '请完成卡口法参数填写')
        }
        try {
            messageApi.open({
                key,
                type: 'loading',
                content: '正在生成最新报告...',
                duration: 0,
            });
            generateResult3();
            // delayTimer.current = setTimeout(() => {
            //     messageApi.open({
            //         key,
            //         type: 'success',
            //         content: '已生成!',
            //         duration: 200,
            //     });
            // }, 500);
            delayTimer.current = setTimeout(() => {
                if (formData3.method == '1') {
                    let wordParagraphList: WordParagraphBlock[] = deepCopy(customBackgroundList3);
                    wordParagraphList.forEach(ele => {
                        if (ele.type == 'text') {
                            ele.text = replaceByFromData3(ele.text)
                        }
                    })
                    insertDocItemByTargetTopicName &&
                        insertDocItemByTargetTopicName([{
                            topicName: "环境容量分析",
                            docItemList: convertWordParagraphListToDocItemList(wordParagraphList)
                        }])
                } else {
                    let wordParagraphList: WordParagraphBlock[] = deepCopy(customBackgroundList4);
                    wordParagraphList.forEach(ele => {
                        if (ele.type == 'text') {
                            ele.text = replaceByFromData3(ele.text)
                        }
                    })
                    insertDocItemByTargetTopicName &&
                        insertDocItemByTargetTopicName([{
                            topicName: "环境容量分析",
                            docItemList: convertWordParagraphListToDocItemList(wordParagraphList)
                        }])
                }
                messageApi.destroy();
                clearAllResult();
            }, 700);
        } catch (e) {
        }
    }


    const renderMainTab = () => {
        return (
            <div className="word-editor-plugins-content scroll-y">
                <div
                    className='flex-row'
                    style={{
                        width: 'calc(100% - 20px)',
                        height: 42,
                        alignItems: 'center',
                        marginBottom: 12,
                        marginLeft: 5,
                    }}
                >
                    <div
                        style={{ height: 32 }}
                        className='flex-row'
                    >方法选择：</div>
                    <Select
                        style={{
                            flex: 1,

                        }}
                        value={activeType}
                        options={[
                            {
                                label: '面积法',
                                value: '1',
                            },
                            {
                                label: '卡口法',
                                value: '2',
                            },
                            {
                                label: '游路法',
                                value: '3',
                            },
                        ]}
                        onChange={(e) => setActiveType(e)}
                    />
                </div>
                {disPatchNewTabRender()}
            </div>
        )
    }

    const disPatchNewTabRender = () => {
        switch (activeType) {
            case '1':
                return renderTab1();
                break;
            case '2':
                return renderTab2();
                break;
            case '3':
                return renderTab3();
                break;
            default:
                break;
        }
    }

    const renderTab1 = () => {
        return (
            <div className="word-editor-plugins-content scroll-y">
                <Collapse
                    size="small"
                    style={{ width: 'calc(100% - 18px)', marginLeft: 5 }}
                    defaultActiveKey={"1"}
                >
                    <Panel header="参数填写" key="1">
                        <Form
                            form={form1}
                            style={{ width: '100%', paddingLeft: 0, marginLeft: -5 }}
                            labelCol={{ span: 10 }}
                            labelAlign={'right'}
                            labelWrap
                            colon={false}
                            requiredMark={false}
                            className="tourism-capacity-form"
                        >
                            <Form.Item
                                label={"可游览面积"}
                                name={'sightseeingArea'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'公顷'}
                                    type={'number'}
                                    value={formData1.sightseeingArea}
                                    onChange={onSightseeingAreaTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"每位游客应占有的合理面积"}
                                name={'areaForeachTourist'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'公顷'}
                                    type={'number'}
                                    value={formData1.areaForeachTourist}
                                    onChange={onAreaForeachTouristTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"景区每天开放时间"}
                                name={'openEveryDayHours'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'小时'}
                                    type={'number'}
                                    value={formData1.openEveryDayHours}
                                    onChange={onOpenEveryDayHoursTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"年可游天数"}
                                name={'travelInAYearDays'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>天</div>}
                                    type={'number'}
                                    value={formData1.travelInAYearDays}
                                    onChange={onTravelInAYearDaysTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游完景区所需时间"}
                                name={'timeItTakesToVisit'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'小时'}
                                    type={'number'}
                                    value={formData1.timeItTakesToVisit}
                                    onChange={onTimeItTakesToVisitTextChange}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游客每天游览最舒适合理的时间"}
                                name={'timeForTouristsToVisitEveryDay'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'小时'}
                                    type={'number'}
                                    value={formData1.timeForTouristsToVisitEveryDay}
                                    onChange={onTimeForTouristsToVisitEveryDayTextChange}
                                    allowClear
                                />
                            </Form.Item>
                        </Form>
                    </Panel>
                    <Panel header="方法说明" key="2">
                        <div className='word-plugin-generate-template-rule'>
                            <p className='rule-title'>1.日环境容量（人次）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="C= \frac{A}{a} \times{D}"
                                />
                            </div>
                            <p>C：日环境容量</p>
                            <p>A：可游览面积</p>
                            <p>a：每位游客应占有的合理面积</p>
                            <p>D：周转率（周转率=景区每天开放时间／游完景区所需时间）</p>
                            <p className='rule-title'>2.年环境容量（万人次）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年环境容量= \frac{C}{10000} \times{年可游天数}"
                                />
                            </div>
                            <p className='rule-title'>3.日游客容量（人次/日）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="G= \frac{t}{T} \times{C}"
                                />
                            </div>
                            <p>G为日游客容量，单位为人次；</p>
                            <p>t为游完景区所需时间；</p>
                            <p>T为游客每天游览最舒适合理的时间；</p>
                            <p>C为日环境容量，单位为人次。</p>
                            <p className='rule-title'>4.年游客容量（万人次/年）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年游客容量=  \frac{{C}\times{年可游天数}}{10000}"
                                />
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                <div
                    className='flex-row word-editor-plugins-content-option-bar'
                >
                    <Button
                        type={'default'}
                        icon={<FileSyncOutlined />}
                        onClick={generateResult1}
                        style={{ marginRight: '12px' }}
                    >
                        效果预览
                    </Button>
                    <Button
                        type={'primary'}
                        icon={<SnippetsOutlined />}
                        onClick={insertCustomBackgroundList}
                    >
                        确定
                    </Button>

                </div>
                {
                    !isEmpty(formData1.dailyEnvironmentalCapacity) ?
                        <div className="word-plugin-generate-template">
                            {
                                customBackgroundList1.map(((customBackground, index) => {
                                    return (
                                        <React.Fragment
                                            key={index + ''}
                                        >
                                            {renderCustomBackground2(customBackground, index)}
                                        </React.Fragment>
                                    )
                                }))
                            }
                        </div>
                        :
                        <div
                            className="flex-col word-plugin-generate-template"
                            style={{ height: 270, paddingTop: 72 }}
                        >
                            <Empty
                                description={'请确认正确填写计算参数，并点击效果预览按钮'}
                            />
                        </div>
                }
            </div>
        )
    }


    const checkDisable2 = () => {
        let disable = false;
        Object.values(formData2Ref.current).forEach(val => {
            if (isEmpty(val)) {
                disable = true;
            }
        })
        return disable;
    }

    const renderTab2 = () => {
        return (
            <div className="word-editor-plugins-content scroll-y">
                <Collapse
                    size="small"
                    style={{ width: 'calc(100% - 18px)', marginLeft: 5 }}
                    defaultActiveKey={"1"}
                >
                    <Panel header="参数填写" key="1">
                        <Form
                            form={form2}
                            style={{ width: '100%', paddingLeft: 0, marginLeft: -5 }}
                            labelCol={{ span: 10 }}
                            labelAlign={'right'}
                            labelWrap
                            colon={false}
                            requiredMark={false}
                            className="tourism-capacity-form"
                        >
                            <Form.Item
                                label={"日游客批数"}
                                name={'dailyTouristBatch'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>批</div>}
                                    type={'number'}
                                    value={formData2.dailyTouristBatch}
                                    onChange={onDailyTouristBatchTextChange2}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"每批游客人数"}
                                name={'touristsCountPerBatch'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>人次</div>}
                                    type={'number'}
                                    value={formData2.touristsCountPerBatch}
                                    onChange={onTouristsCountPerBatchTextChange2}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"年可游天数"}
                                name={'travelInAYearDays'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>天</div>}
                                    type={'number'}
                                    value={formData2.travelInAYearDays}
                                    onChange={onTravelInAYearDaysTextChange2}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游完景区所需时间"}
                                name={'timeItTakesToVisit'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>小时</div>}
                                    type={'number'}
                                    value={formData2.timeItTakesToVisit}
                                    onChange={onTimeItTakesToVisitTextChange2}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游客每天游览最舒适合理的时间"}
                                name={'timeForTouristsToVisitEveryDay'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'小时'}
                                    type={'number'}
                                    value={formData2.timeForTouristsToVisitEveryDay}
                                    onChange={onTimeForTouristsToVisitEveryDayTextChange2}
                                    allowClear
                                />
                            </Form.Item>
                        </Form>
                    </Panel>
                    <Panel header="方法说明" key="2">
                        <div className='word-plugin-generate-template-rule'>
                            <p className='rule-title'>1.日环境容量（人次）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="C= D \times{A}"
                                />
                            </div>
                            <p>C为日环境容量，单位为人次；</p>
                            <p>D为日游客批数；</p>
                            <p>A为每批游客人数；</p>
                            <p className='rule-title'>2.年环境容量（万人次）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年环境容量=  \frac{C\times年可游天数}{10000}"
                                />
                            </div>
                            <p className='rule-title'>3.日游客容量（人次/日）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="G= \frac{t}{T} \times{C}"
                                />
                            </div>
                            <p>G为日游客容量，单位为人次；</p>
                            <p>t为游完景区所需时间；</p>
                            <p>T为游客每天游览最舒适合理的时间；</p>
                            <p>C为日环境容量，单位为人次。</p>
                            <p className='rule-title'>4.年游客容量（万人次/年）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年游客容量=  \frac{{C}\times{年可游天数}}{10000}"
                                />
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                <div
                    className='flex-row word-editor-plugins-content-option-bar'
                >
                    <Button
                        type={'default'}
                        icon={<FileSyncOutlined />}
                        onClick={generateResult2}
                        style={{ marginRight: '12px' }}
                    >
                        效果预览
                    </Button>
                    <Button
                        type={'primary'}
                        icon={<SnippetsOutlined />}
                        // disabled={checkDisable2()}
                        onClick={insertCustomBackgroundList2}
                    >
                        确定
                    </Button>
                </div>
                {/* <Divider
                    type={'horizontal'}
                    orientation={'center'}
                    style={{ width: 'calc(100% - 18px)', marginRight: 12 }}
                >
                    报告文本预览
                </Divider> */}
                {
                    !isEmpty(formData2.dailyEnvironmentalCapacity) ?
                        <div className="word-plugin-generate-template">
                            {
                                customBackgroundList2.map(((customBackground, index) => {
                                    return (
                                        <React.Fragment
                                            key={index + ''}
                                        >
                                            {renderCustomBackground2(customBackground, index)}
                                        </React.Fragment>
                                    )
                                }))
                            }
                        </div>
                        :
                        <div
                            className="flex-col word-plugin-generate-template"
                            style={{ height: 270, paddingTop: 72 }}
                        >
                            <Empty
                                description={'请确认正确填写计算参数，并点击效果预览按钮'}
                            />
                        </div>
                }
            </div>
        )
    }



    const renderTab3 = () => {
        return (
            <div className="word-editor-plugins-content scroll-y">
                <Collapse
                    size="small"
                    style={{ width: 'calc(100% - 18px)', marginLeft: 5 }}
                    defaultActiveKey={"1"}
                >
                    <Panel header="参数填写" key="1">
                        <Form
                            form={form3}
                            style={{ width: '100%', paddingLeft: 0, marginLeft: -5 }}
                            labelCol={{ span: 10 }}
                            labelAlign={'right'}
                            labelWrap
                            colon={false}
                            requiredMark={false}
                            className="tourism-capacity-form"
                        >
                            <Form.Item
                                label={"游道全长"}
                                name={'lengthOfTheWalkway'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>米</div>}
                                    type={'number'}
                                    value={formData3.lengthOfTheWalkway}
                                    onChange={onLengthOfTheWalkwayTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"每位游客占用合理游道长度"}
                                name={'touristOccupiesReasonable'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>米</div>}
                                    type={'number'}
                                    value={formData3.touristOccupiesReasonable}
                                    onChange={onTouristOccupiesReasonableTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游道全天开放时间"}
                                name={'openEveryDayHours'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>小时</div>}
                                    type={'number'}
                                    value={formData3.openEveryDayHours}
                                    onChange={onOpenEveryDayHoursTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游完全游道所需时间"}
                                name={'timeItTakesToVisit'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>小时</div>}
                                    type={'number'}
                                    value={formData3.timeItTakesToVisit}
                                    onChange={onTimeItTakesToVisitTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"沿游道返回所需时间"}
                                name={'timeItTakesToBack'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>小时</div>}
                                    type={'number'}
                                    value={formData3.timeItTakesToBack}
                                    onChange={onTimeItTakesToBackTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"年可游天数"}
                                name={'travelInAYearDays'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={<div style={{ width: 28 }}>天</div>}
                                    type={'number'}
                                    value={formData3.travelInAYearDays}
                                    onChange={onTravelInAYearDaysTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游客每天游览最舒适合理的时间"}
                                name={'timeForTouristsToVisitEveryDay'}
                                rules={[{ required: true }]}
                            >
                                <AutoSelectInput
                                    placeholder="请输入"
                                    addonAfter={'小时'}
                                    type={'number'}
                                    value={formData3.timeForTouristsToVisitEveryDay}
                                    onChange={onTimeForTouristsToVisitEveryDayTextChange3}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item
                                label={"游道法计算公式选择"}
                                name={'method'}
                                rules={[{ required: true }]}
                            >
                                <Select
                                    options={methodOptions}
                                    placeholder={'请选择'}
                                    value={formData3.method}
                                    onChange={onMethodChange3}
                                />
                            </Form.Item>
                        </Form>
                    </Panel>
                    <Panel header="方法说明" key="2">
                        <div className='word-plugin-generate-template-rule'>
                            <p className='rule-title'>1.日环境容量（人次）计算公式：</p>
                            <p>（1）完全游道法</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="C= \frac{M}{m} \times{D}"
                                />
                            </div>
                            <p>（2）不完全游道法</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="C= \frac{M}{m + (m \times{E/F})} \times{D}"
                                />
                            </div>
                            <p>C为日环境容量，单位为人次；</p>
                            <p>M为游道全长，单位为米；</p>
                            <p>m为每位游客占用合理游道长度，单位为米；</p>
                            <p>D为周转率，D=游道全天开放时间/游完全游道所需时间；</p>
                            <p>F为游完全游道所需时间；</p>
                            <p>E为沿游道返回所需时间。</p>
                            <p className='rule-title'>2.年环境容量（万人次）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年环境容量=  \frac{C\times年可游天数}{10000}"
                                />
                            </div>
                            <p className='rule-title'>3.日游客容量（人次/日）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                            >
                                <TeX
                                    math="G= \frac{t}{T} \times{C}"
                                />
                            </div>
                            <p>G为日游客容量，单位为人次；</p>
                            <p>t为游完景区所需时间；</p>
                            <p>T为游客每天游览最舒适合理的时间；</p>
                            <p>C为日环境容量，单位为人次。</p>
                            <p className='rule-title'>4.年游客容量（万人次/年）计算公式：</p>
                            <div
                                className='flex-row rule-container'
                                style={{ fontSize: 14 }}
                            >
                                <TeX
                                    math="年游客容量=  \frac{{C}\times{年可游天数}}{10000}"
                                />
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                <div
                    className='flex-row word-editor-plugins-content-option-bar'
                >
                    <Button
                        type={'default'}
                        icon={<FileSyncOutlined />}
                        onClick={generateResult3}
                        style={{ marginRight: '12px' }}
                    >
                        效果预览
                    </Button>
                    <Button
                        type={'primary'}
                        icon={<SnippetsOutlined />}
                        onClick={insertCustomBackgroundList3}
                    >
                        确定
                    </Button>

                </div>
                {/* <Divider
                    type={'horizontal'}
                    orientation={'center'}
                    style={{ width: 'calc(100% - 18px)', marginRight: 12 }}
                >
                    报告文本预览({formData3.method === '1' ? '完全游道法' : '不完全游道法'})
                </Divider> */}
                {
                    !isEmpty(formData3.dailyEnvironmentalCapacity) && !isEmpty(formData3.method) ?
                        <>
                            {
                                formData3.method == '1' ?
                                    <div className="word-plugin-generate-template">
                                        {
                                            customBackgroundList3.map(((customBackground, index) => {
                                                return (
                                                    <React.Fragment
                                                        key={index + ''}
                                                    >
                                                        {renderCustomBackground3(customBackground, index)}
                                                    </React.Fragment>
                                                )
                                            }))
                                        }
                                    </div>
                                    :
                                    <div className="word-plugin-generate-template">
                                        {
                                            customBackgroundList4.map(((customBackground, index) => {
                                                return (
                                                    <React.Fragment
                                                        key={index + ''}
                                                    >
                                                        {renderCustomBackground3(customBackground, index)}
                                                    </React.Fragment>
                                                )
                                            }))
                                        }
                                    </div>
                            }
                        </>
                        :
                        <div
                            className="flex-col word-plugin-generate-template"
                            style={{ height: 270, paddingTop: 72 }}
                        >
                            <Empty
                                description={'请确认正确填写计算参数，并点击效果预览按钮'}
                            />
                        </div>
                }
            </div>
        )
    }

    const tourismCapacityTbas = [
        {
            key: '1',
            label: '旅游容量计算',
            children: renderMainTab()
        },
        // {
        //     key: '2',
        //     label: '卡口法',
        //     children: renderTab2()
        // },
        // {
        //     key: '3',
        //     label: '游路法',
        //     children: renderTab3()
        // }
    ]


    return (
        <div className="word-editor-plugins-content">
            <Tabs
                type={'card'}
                defaultActiveKey="1"
                items={tourismCapacityTbas}
                tabPosition='top'
                className='word-editor-plugin-content-tabs disable-drag'
                size={'small'}
            />
            {contextHolder}
        </div>
    )
}

export default TourismCapacity;