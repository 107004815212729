import { forwardRef, useContext, useEffect, useRef, useState } from "react"
import OptionBarBtn from "../../../components/option-bar-btn/OptionBarBtn";
import SymbolPopover from "../../../components/popover/SymbolPopover";
import useReSize from "../../../hooks/useResize";
import ExportPopover from "../../../components/popover/ExportPopover";
import CanoricalMePopover from "../../../components/popover/CanoricalMePopover";
import comDocContext from '../../../context/DocContext';
import { ProfileOutlined } from "@ant-design/icons";
import LatinNameQueryPopover from "../../../components/popover/LatinNameQueryPopover";
import { AddPicture } from "@icon-park/react";

interface Props {
    visible: boolean
    getWordExportParams: Function
}

const WordExtraOptionBar = (props: Props, ref: any) => {

    const {
        comDocInstance,
    } = useContext(comDocContext);

    const {
        visible,
        getWordExportParams,
    } = props;

    const containerRef = useRef(null);

    const containerSize = useReSize(containerRef);

    const symbolPopoverRef = useRef(null);
    const canoricalMePopoverRef = useRef(null);

    const latinNameQueryPopoverRef = useRef(null);

    const symbolBtnRef = useRef(null);
    const latinNameQueryPopoverBtnRef = useRef(null);

    const [symbolPopoverPosition, setSymbolPopoverPosition] = useState({ clientX: 0, clientY: 0 });
    const [latinNameQueryPopoverPosition, setLatinNameQueryPopoverPosition] = useState({ clientX: 0, clientY: 0 });

    const handleOpenSymbolPopover = () => {
        symbolPopoverRef.current.openPopover();
    }

    useEffect(() => {
        measureSymbolPosition();
        measureLatinNameQueryPopoverPosition();
    }, [containerSize, comDocInstance, visible])

    const measureSymbolPosition = () => {
        // const clientRect = symbolBtnRef.current?.getBoundingClientRect();
        // let { top, left } = clientRect;
        // const windowsWidth = window.innerWidth;
        // if (left + 439 < windowsWidth) {
        // } else {
        //     left = windowsWidth - 439;
        // }
        // setSymbolPopoverPosition({ clientX: left, clientY: top + 64 })
    }

    const measureLatinNameQueryPopoverPosition = () => {
        const clientRect = latinNameQueryPopoverBtnRef.current?.getBoundingClientRect();
        let { top, left } = clientRect;
        const windowsWidth = window.innerWidth;
        if (left + 300 < windowsWidth) {
        } else {
            left = windowsWidth - 324;
        }
        setLatinNameQueryPopoverPosition({ clientX: left, clientY: top + 64 })
    }

    const exportPopoverRef = useRef(null);

    const exportBtnRef = useRef(null);

    const [exportPopoverPosition, setExportPopoverPosition] = useState({ clientX: 0, clientY: 0 })

    const handleOpenExportPopover = () => {
        exportPopoverRef.current.openPopover();
    }

    const showLatinNameQueryPopover = () => {
        latinNameQueryPopoverRef.current.openPopover();
    }

    const handleInsertImageToDoc = () => {
        console.log("handleInsertImageToDoc---->")
        document.getElementById('wordInsertImageInput').click();
    }

    useEffect(() => {
        // const clientRect = exportBtnRef.current?.getBoundingClientRect();
        // const { top, left } = clientRect;
        // setExportPopoverPosition({ clientX: left - 25, clientY: top + 64 })
    }, [containerSize])

    const _getWordExportParams = () => {
        const params = getWordExportParams();
        // console.log("params--->", params)
        return params;
    }

    return (
        <div
            ref={containerRef}
            className='edit-page-option-row-item flex-col'
            style={{ width: '110px' }}
        >
            <div className='edit-page-option-row-half flex-row' style={{ height: '100%' }}>
                {/* <div
                    ref={exportBtnRef}
                >
                    <OptionBarBtn
                        icon={<i style={{ fontSize: '21px' }} className='iconfont-1 ghzs-gray-daochuword' />}
                        size={'large'}
                        name={'导出'}
                        onClick={handleOpenExportPopover}
                    />
                </div> */}
                {/* <OptionBarBtn
                    icon={<i style={{ fontSize: '24px' }} className='iconfont ghzs-color-a-bianzu27' />}
                    size={'large'}
                    name={'查找'}
                /> */}
                {/* <div
                    ref={symbolBtnRef}
                >
                    <OptionBarBtn
                        icon={<i style={{ fontSize: '20px' }} className='iconfont-1 ghzs-gray-fuhao' />}
                        size={'large'}
                        name={'符号'}
                        onClick={handleOpenSymbolPopover}
                    />
                </div> */}
                <div
                    ref={latinNameQueryPopoverBtnRef}
                >
                    <OptionBarBtn
                        icon={<ProfileOutlined style={{ fontSize: 21 }} />}
                        size={'large'}
                        tooltipTitle={"拉丁名查询"}
                        tooltipDesc={"为光标选中或所处段落的文本，自动填充物种拉丁名（需至少上传一份物种名单）"}
                        name={'拉丁名查询'}
                        onClick={showLatinNameQueryPopover}
                    />
                </div>
                {/* <div
                    style={{marginLeft: 12}}
                    
                >
                    <OptionBarBtn
                        icon={<AddPicture theme="outline" size="22" fill="#303133"/>}
                        size={'large'}
                        tooltipTitle={"插入图片"}
                        tooltipDesc={"点击此处可选择插入本地文件夹中的图片到规划报告光标所在处下方"}
                        name={'插入图片'}
                        onClick={handleInsertImageToDoc}
                    />
                </div> */}
            </div>
            {/* <SymbolPopover
                ref={symbolPopoverRef}
                clientX={symbolPopoverPosition.clientX}
                clientY={symbolPopoverPosition.clientY}
            /> */}
            <ExportPopover
                ref={exportPopoverRef}
                type={'word'}
                exportWord={_getWordExportParams}
                clientX={exportPopoverPosition.clientX}
                clientY={exportPopoverPosition.clientY}
                comDocInstance={comDocInstance}
            />
            {/* <CanoricalMePopover
                ref={canoricalMePopoverRef}
                fillSpeciesInfoList={fillSpeciesInfoList}
                clientX={canoricalMePopoverPosition.clientX}
                clientY={canoricalMePopoverPosition.clientY}
            /> */}
            <LatinNameQueryPopover
                ref={latinNameQueryPopoverRef}
                clientX={latinNameQueryPopoverPosition.clientX}
                clientY={latinNameQueryPopoverPosition.clientY}
            />
        </div>
    )
}


export default forwardRef(WordExtraOptionBar);
