import "./TopicManagePageV3.scss";
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    Modal,
    Button,
    Tree,
    Checkbox,
    Empty,
    Tooltip,
    Alert,
} from "antd";
import {
    DocInstance,
    EditPageNavType,
    TopicNodeActionType,
    TopicType,
} from "../../../utils/types";
import {
    addTreePropertyForList,
    arrayTreeFilter,
    commonErrorMsg,
    commonSuccessMsg,
    deepCloneV2,
    dfsRecursive,
    expandedKeysFun,
    filterTree,
    generateTreeData,
    getChangeEventValue,
    getCheckBoxChangeEventValue,
    getNodeChildDeviceNodeCheckedCount,
    getNodeChildDeviceNodeCount,
    handleStopPropagation,
    insertChildNodeListToTree,
    insertPeerNodeListToTree,
    insertTopPeerNodeListToTree,
    isEmpty,
    makeClassNameList,
    swapListElements,
    toastShort,
    tree2List,
} from "../../../utils";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    UndoOutlined,
} from "@ant-design/icons";
import {
    processDocInstanceBeforeUpdate,
    updateTopicRealCheckedStatus,
} from "../word-editor/editor-doc-item-generate";
import TopicTreeContextMenu from "../../../components/context-menu/TopicTreeContextMenu";
import comDocContext from "../../../context/DocContext";
import { throttle } from "lodash";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ReNameTopicModal from "../topic-sider-bar/sider-modal/ReNameTopicModal";
import useStateDebounce from "../../../hooks/useStateDebounce";
import emitter from "../../../utils/emitter";
import { CustomScroll } from "react-custom-scroll";
import AddTopicModalNew from "./AddTopicModalNew";
import { DeleteFive, MoreThree } from "@icon-park/react";
import EditTopicModalNew from "./EditTopicModalNew";
import { useLocation, useSearchParams } from "react-router-dom";
import AutoSelectSearchInput from "../../../components/override-antd-kit/AutoSelectSearchInput";
import { showGuide } from "../../../guide/guide-steps-config";
import React from "react";

interface Props { }

type DeviceSortType = "checked" | "default_up" | "default_down"

const TopicManagePage = (props: Props) => {
    const {
        comDocInstance,
        _setComDocInstance,
    } = useContext(comDocContext);

    const containerRef = useRef<any>(null);
    const scrollbarsRef = useRef(null);
    const contextMenuRef = useRef<any>(null);
    const pureTextTopicTreeRef = useRef<any>(null);
    const [windowFullHeight, setWindowFullHeight] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<
        "selectTitle" | "selecteDevice"
    >("selectTitle");

    //所有规划大纲
    const [topicList, setTopicList] = useState<TopicType[]>([]);

    const [draggingNodeKey, setDraggingNodeKey] = useState(null);

    const [pureTextTopicTreeHighLightNode, setPureTextTopicTreeHighLightNode] =
        useState<TopicType>(null);

    const [pureTextTopicTreeSelectedNode, setPureTextTopicTreeSelectedNode] =
        useState<TopicType>(null);

    const [pureTextTreeDataCheckedKeyList, setPureTextTreeDataCheckedKeyList] =
        useState<string[]>([]);

    const [
        pureTextTopicTreeDataSearchValue,
        setPureTextTopicTreeDataSearchValue,
    ] = useState("");

    const [pureTextTopicTreeExpandedKeys, setPureTextTopicTreeExpandedKeys] =
        useState<string[]>([]);

    const [deviceTopicSearchValue, setDeviceTopicSearchValue] =
        useState<string>("");

    const [deviceListSortType, setDeviceListSortType] =
        useState<DeviceSortType>("checked");

    const [topicListHistoryList, setTopicListHistoryList] = useState<{ _topicList: TopicType[], _pureTextTreeDataCheckedKeyList: string[] }[]>([]);
    const [topicListHistoryIndex, setTopicHistoryIndex] = useState(-1);

    const [delayUpdateComDocInstance, setDelayUpdateComDocInstance] =
        useState<DocInstance>(comDocInstance);

    const debouncedComDocInstance = useStateDebounce(
        delayUpdateComDocInstance,
        100
    );

    const [filteredForPureTopicTreeData, setFilteredForPureTopicTreeData] = useState<TopicType[]>([]);

    const [
        filteredForPureTopicTreeExpandedKeys,
        setFilteredForPureTopicTreeExpandedKeys,
    ] = useState<string[]>([]);

    /***** 纯展示 *****/
    const realTextTopicTreeRef = useRef<any>(null);

    const [
        realTextTopicTreeDataSearchValue,
        setRealTextTopicTreeDataSearchValue,
    ] = useState<string>("");

    const [realTextTopicTreeSelectedNode, setRealTextTopicTreeSelectedNode] =
        useState<TopicType>(null);

    const [realTextTopicTreeHighLightNode, setRealTextTopicTreeHighLightNode] =
        useState<TopicType>(null);

    const [realTextTopicTreeExpandedKeys, setRealTextTopicTreeExpandedKeys] =
        useState<string[]>([]);

    const [filteredForRealTopicTreeData, setFilteredForRealTopicTreeData] = useState<TopicType[]>([]);

    const [
        filteredForRealTopicTreeExpandedKeys,
        setFilteredForRealTopicTreeExpandedKeys,
    ] = useState<string[]>([]);

    const [currentDeviceType, setCurrentDeviceType] = useState<"current" | "all">(
        "current"
    );

    const [activeNavType, setActiveNavType] = useState<EditPageNavType>('topic');
    const activeNavTypeRef = useRef<EditPageNavType>('topic');
    const routerLocation = useLocation();
    const [searchParams] = useSearchParams();

    const delaySaveTimer = useRef<any>(null);
    const delayJumpTimer = useRef<any>(null);
    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        const handleUnDoKeyDown = (event) => {
            const isCtrlOrCommand = event.ctrlKey || event.metaKey;
            const isZKey = event.key === 'z' || event.key === 'Z';
            if (isCtrlOrCommand && isZKey) {
                if (activeNavTypeRef.current == 'topic') {
                    undoTopicList();
                    event.preventDefault();
                }
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', handleUnDoKeyDown);
        //@ts-ignore
        const currentActiveNavType: EditPageNavType = searchParams.get("screen");
        activeNavTypeRef.current = currentActiveNavType;
        setActiveNavType(currentActiveNavType);

        return () => {
            delaySaveTimer.current && clearTimeout(delaySaveTimer.current);
            delayJumpTimer.current && clearTimeout(delayJumpTimer.current);
            document.removeEventListener('keydown', handleUnDoKeyDown);
        }
    }, [routerLocation, searchParams, topicListHistoryList])

    useEffect(() => {
        _setComDocInstance({ ...debouncedComDocInstance });
    }, [debouncedComDocInstance]);

    useEffect(() => {
        let resizeObserver = null;
        try {
            resizeObserver = new ResizeObserver(entries => {
                setWindowFullHeight(window.innerHeight);
            });
            resizeObserver && resizeObserver.observe(containerRef.current);
        } catch (e) { }
        return () => {
            try {
                resizeObserver && resizeObserver?.unobserve(containerRef.current);
            } catch (e) { }
        }
    }, []);

    useEffect(() => {
        if (comDocInstance) {
            resetTopicHistoryList(comDocInstance.topicList);
        }
    }, [comDocInstance]);

    const updateTopicList = (newTopicList: TopicType[]) => {
        setTopicList(deepCloneV2(newTopicList));
        let _topicListHistoryList = topicListHistoryList;
        _topicListHistoryList.push({
            _topicList: deepCloneV2(newTopicList),
            _pureTextTreeDataCheckedKeyList: deepCloneV2(pureTextTreeDataCheckedKeyList)
        });
        setTopicHistoryIndex(_topicListHistoryList.length - 1);
        setTopicListHistoryList([..._topicListHistoryList]);
    };

    const getInitPureTextTopicCheckedIdList = (initTopicList: TopicType[]) => {
        let result = [];
        let _tree: TopicType[] = generateTreeData(
            addTreePropertyForList(initTopicList)
        );
        dfsRecursive(_tree, (node: TopicType) => {
            if (node.topicType == "text") {
                if (node.checked) {
                    result.push(node.id);
                } else if (
                    node.children &&
                    node.children
                        .filter((ele) => ele.checked)
                        .filter((ele) => ele.topicType === "device").length
                ) {
                    result.push(node.id);
                }
            }
        });
        return result;
    };

    const topicTree = useMemo(() => {
        let _tree = generateTreeData(addTreePropertyForList(topicList));
        let serialNumberList = [];
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                const childDeviceNodeCount = getNodeChildDeviceNodeCount(topic);
                const childDeviceNodeCheckedCount =
                    getNodeChildDeviceNodeCheckedCount(topic);
                if (serialNumberList.length > level) {
                    serialNumberList = serialNumberList.splice(0, level + 1);
                    serialNumberList[serialNumberList.length - 1]++;
                } else if (serialNumberList.length == level) {
                    if (isEmpty(serialNumberList[level])) {
                        serialNumberList[level] = 1;
                    } else {
                        serialNumberList[level]++;
                    }
                } else {
                    serialNumberList.push(1);
                }
                const serialNumber = serialNumberList.join(".");
                topic.treeLevel = level;
                topic.serialNumber = serialNumber;
                topic.childDeviceNodeCount = childDeviceNodeCount;
                topic.childDeviceNodeCheckedCount = childDeviceNodeCheckedCount;
            }
        });
        const topicTreeData: TopicType[] = deepCloneV2(_tree);
        return topicTreeData;
    }, [topicList]);

    const pureTextTopicTreeData = useMemo(() => {
        let _tree = generateTreeData(addTreePropertyForList(topicList));
        let serialNumberList = [];
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                const childDeviceNodeCount = getNodeChildDeviceNodeCount(topic);
                const childDeviceNodeCheckedCount =
                    getNodeChildDeviceNodeCheckedCount(topic);
                if (serialNumberList.length > level) {
                    serialNumberList = serialNumberList.splice(0, level + 1);
                    serialNumberList[serialNumberList.length - 1]++;
                } else if (serialNumberList.length == level) {
                    if (isEmpty(serialNumberList[level])) {
                        serialNumberList[level] = 1;
                    } else {
                        serialNumberList[level]++;
                    }
                } else {
                    serialNumberList.push(1);
                }
                const serialNumber = serialNumberList.join(".");
                topic.treeLevel = level;
                topic.serialNumber = serialNumber;
                topic.childDeviceNodeCount = childDeviceNodeCount;
                topic.childDeviceNodeCheckedCount = childDeviceNodeCheckedCount;
            }
        });
        _tree = filterTree(_tree, (node) => node.topicType == "text");
        const _pureTextTopicTreeData: TopicType[] = deepCloneV2(_tree);
        return _pureTextTopicTreeData;
    }, [topicList]);

    const filteredForPureTextTopicTree = useMemo(() => {
        let _tree = generateTreeData(addTreePropertyForList(topicList));
        let serialNumberList = [];
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                if (serialNumberList.length > level) {
                    serialNumberList = serialNumberList.splice(0, level + 1);
                    serialNumberList[serialNumberList.length - 1]++;
                } else if (serialNumberList.length == level) {
                    if (isEmpty(serialNumberList[level])) {
                        serialNumberList[level] = 1;
                    } else {
                        serialNumberList[level]++;
                    }
                } else {
                    serialNumberList.push(1);
                }
                const serialNumber = serialNumberList.join(".");
                topic.treeLevel = level;
                topic.serialNumber = serialNumber;
            }
        });
        _tree = filterTree(_tree, (node) =>
            node.topicName.includes(pureTextTopicTreeDataSearchValue)
        );
        const _pureTextTopicTreeData: TopicType[] = deepCloneV2(_tree);
        return _pureTextTopicTreeData;
    }, [pureTextTopicTreeDataSearchValue, topicList]);

    const filteredForRealTextTopicTree = useMemo(() => {
        let _tree = generateTreeData(addTreePropertyForList(topicList));
        let serialNumberList = [];
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                if (serialNumberList.length > level) {
                    serialNumberList = serialNumberList.splice(0, level + 1);
                    serialNumberList[serialNumberList.length - 1]++;
                } else if (serialNumberList.length == level) {
                    if (isEmpty(serialNumberList[level])) {
                        serialNumberList[level] = 1;
                    } else {
                        serialNumberList[level]++;
                    }
                } else {
                    serialNumberList.push(1);
                }
                const serialNumber = serialNumberList.join(".");
                topic.treeLevel = level;
                topic.serialNumber = serialNumber;
            }
        });
        _tree = filterTree(
            _tree,
            (node) =>
                node.topicType == "text" &&
                pureTextTreeDataCheckedKeyList.includes(node.id)
        );
        _tree = filterTree(_tree, (node) =>
            node.topicName.includes(realTextTopicTreeDataSearchValue)
        );
        const _pureTextTopicTreeData: TopicType[] = deepCloneV2(_tree);
        return _pureTextTopicTreeData;
    }, [realTextTopicTreeDataSearchValue, topicList]);

    const onNodeContextMenuByClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        node: TopicType
    ) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isEmpty(pureTextTopicTreeDataSearchValue)) {
            toastShort("warning", "请退出搜索模式后重试");
            return false;
        }
        setPureTextTopicTreeHighLightNode(node);
        setPureTextTopicTreeSelectedNode(node);
        let originX = e.clientX;
        let originY = e.clientY;
        originY = originY;
        if (originY + 268 > windowFullHeight) {
            originY = windowFullHeight - 350;
        }
        e.clientX = originX;
        e.clientY = originY;
        contextMenuRef.current.showContextMenu(e);
    };

    const onNodeContextMenu = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        node: TopicType
    ) => {
        if (!isEmpty(pureTextTopicTreeDataSearchValue)) {
            toastShort("warning", "请退出搜索模式后重试");
            return false;
        }
        e.preventDefault();
        e.stopPropagation();
        setPureTextTopicTreeHighLightNode(node);
        setPureTextTopicTreeSelectedNode(node);
        let originX = e.clientX;
        let originY = e.clientY;
        originY = originY - 42;
        if (originY + 268 > windowFullHeight) {
            originY = windowFullHeight - 350;
        }
        e.clientX = originX;
        e.clientY = originY;
        contextMenuRef.current.showContextMenu(e);
    };

    const onDoubleClickTextTopic = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        node: TopicType
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setPureTextTopicTreeHighLightNode(node);
        setPureTextTopicTreeSelectedNode(node);
        onPickerTreeNodeActionTypeForPureTopicTree("ReName", node);
    };

    const adaptNodeSerialNumber = (node: TopicType) => {
        if (node.topicType == "device") {
            return null;
        }
        return (
            <div className="topic-tree-node-extra" style={{ fontWeight: '700', color: '#555' }}>{node.serialNumber}&nbsp;</div>
        );
    };

    const formatSearchTextForAllTopicTree = (originStr: string) => {
        try {
            if (isEmpty(pureTextTopicTreeDataSearchValue)) {
                return originStr;
            }
            let reg = new RegExp(pureTextTopicTreeDataSearchValue);
            let str = originStr.split(reg);
            let txt = [];
            if (str.length === 0 || str.length === 1) {
                return originStr;
            }
            if (str.length > 0) {
                txt.push(<span key={"0-0"}>{str[0]}</span>);
            }
            txt.push(
                <span key={"0-1"} style={{ color: "#ff0000" }}>
                    {pureTextTopicTreeDataSearchValue}
                </span>
            );
            if (str.length > 1) {
                txt.push(<span key={"0-2"}>{str[1]}</span>);
            }
            if (str.length >= 3) {
                for (let i = 2; i < str.length; i++) {
                    txt.push(
                        <span key={"0-3"} style={{ color: "#ff0000" }}>
                            {pureTextTopicTreeDataSearchValue}
                        </span>
                    );
                    txt.push(<span key={"0-4"}>{str[i]}</span>);
                }
            }
            return txt;
        } catch (e) {
            return originStr;
        }
    };

    const formatSearchTextForRealTopicTree = (originStr: string) => {
        try {
            if (isEmpty(realTextTopicTreeDataSearchValue)) {
                return originStr;
            }
            let reg = new RegExp(realTextTopicTreeDataSearchValue);
            let str = originStr.split(reg);
            let txt = [];
            if (str.length === 0 || str.length === 1) {
                return originStr;
            }
            if (str.length > 0) {
                txt.push(<span key={"0-0"}>{str[0]}</span>);
            }
            txt.push(
                <span key={"0-1"} style={{ color: "#ff0000" }}>
                    {realTextTopicTreeDataSearchValue}
                </span>
            );
            if (str.length > 1) {
                txt.push(<span key={"0-2"}>{str[1]}</span>);
            }
            if (str.length >= 3) {
                for (let i = 2; i < str.length; i++) {
                    txt.push(
                        <span key={"0-3"} style={{ color: "#ff0000" }}>
                            {realTextTopicTreeDataSearchValue}
                        </span>
                    );
                    txt.push(<span key={"0-4"}>{str[i]}</span>);
                }
            }
            return txt;
        } catch (e) {
            return originStr;
        }
    };

    const renderPureTextTopicTreeNode = (node: TopicType) => {
        const isDragging = node.key === draggingNodeKey;
        const className = isDragging ? "draggingNode" : "";
        return (
            <div
                className={makeClassNameList(["device-manage-tree-node", className])}
                //@ts-ignore
                topic-type={node.topicType}
                key={node.id}
                onContextMenu={(e) => onNodeContextMenu(e, node)}
                onDoubleClick={(e) => onDoubleClickTextTopic(e, node)}
            // style={
            //     pureTextTopicTreeHighLightNode &&
            //         pureTextTopicTreeHighLightNode.id == node.id
            //         ? { backgroundColor: "#f2f2f2" }
            //         : null
            // }
            >
                <div
                    className="flex-row device-manage-tree-node-content"
                // style={
                //     pureTextTopicTreeHighLightNode &&
                //         pureTextTopicTreeHighLightNode.id == node.id
                //         ? { borderBottom: "1px solid #7f7f7f" }
                //         : null
                // }
                >
                    <div
                        style={{ height: '100%', flex: 1 }}
                        className="flex-row"
                    >
                        {adaptNodeSerialNumber(node)}
                        <div
                            className="single-line-text"
                            style={{
                                maxWidth: 520,
                                // fontWeight: node.topicType == "text" ? "normal" : "bold",
                                fontWeight: 400,
                            }}
                        >
                            {formatSearchTextForAllTopicTree(node.title)}
                        </div>
                    </div>
                    {/* {
                        pureTextTopicTreeHighLightNode && pureTextTopicTreeHighLightNode.id == node.id ? */}
                    {/* <Tooltip
                        title={'删除大纲(仅自定义大纲)'}
                    >
                        <div
                            className="flex-row device-more-btn topic-tree-more-btn"
                            onClick={(e) => handleDeleteNode(e, node)}
                        >
                            <DeleteFive
                                theme={"outline"}
                                size="18"
                                strokeWidth={3}
                                fill="#ff0000"
                            />
                        </div>
                    </Tooltip> */}
                    <div
                        className="flex-row device-more-btn topic-tree-more-btn"
                        onClick={(e) => onNodeContextMenuByClick(e, node)}
                    >
                        <MoreThree
                            theme={"outline"}
                            size="18"
                            strokeWidth={3}
                            fill="#303133"
                            style={{ transform: 'rotateZ(90deg)' }}
                        />
                    </div>
                    {/* :
                            null
                    } */}
                </div>
            </div>
        );
    };

    const renderRealTextTopicTreeNode = (node: TopicType) => {
        const isDragging = node.key === draggingNodeKey;
        const className = isDragging ? "draggingNode" : "";
        return (
            <div
                className={makeClassNameList(["device-manage-tree-node", className])}
                //@ts-ignore
                topic-type={node.topicType}
                key={node.id}
            >
                <div
                    className={
                        makeClassNameList([
                            "flex-row device-manage-tree-node-content real-topic-node",
                            realTextTopicTreeSelectedNode && realTextTopicTreeSelectedNode.id == node.id ?
                                'active'
                                :
                                null
                        ])
                    }
                // style={
                //     realTextTopicTreeHighLightNode &&
                //         realTextTopicTreeHighLightNode.id == node.id
                //         ? { borderBottom: "1px solid #7f7f7f" }
                //         : null
                // }
                >
                    <div style={{ height: '100%', flex: 1 }} className="flex-row">
                        {adaptNodeSerialNumber(node)}
                        <div
                            className="single-line-text"
                            style={{
                                maxWidth: 280,
                                fontWeight: node.childDeviceNodeCount ? 900 : 400,
                            }}
                        >
                            {formatSearchTextForRealTopicTree(node.title)}
                        </div>
                        {node.topicType == "text" &&
                            node.childDeviceNodeCount &&
                            node.childDeviceNodeCount != node.directDeviceChildNodeCount ? (
                            <Tooltip title={"本节包含项目总数量"}>
                                <div className="flex-row device-manage-tree-node-total-count">
                                    {node.childDeviceNodeCount}
                                </div>
                            </Tooltip>
                        ) : null}
                        {node.topicType == "text" && node.childDeviceNodeCheckedCount ? (
                            <Tooltip title={"本节选择项目总数量"}>
                                <div className="flex-row device-manage-tree-node-count">
                                    {node.childDeviceNodeCheckedCount}
                                </div>
                            </Tooltip>
                        ) : null}
                    </div>

                </div>
            </div>
        );
    };

    const onSelectPureTextTopicNode = (ids: string[]) => {
        const findTopicNode = topicList.find((ele) => {
            return ele.id == ids[0];
        });
        setPureTextTopicTreeSelectedNode(findTopicNode);
        setPureTextTopicTreeHighLightNode(findTopicNode);
    };

    const onSelectRealTextTopicNode = (ids: string[]) => {
        const findTopicNode = topicList.find((ele) => {
            return ele.id == ids[0];
        });
        setRealTextTopicTreeSelectedNode(findTopicNode);
        setRealTextTopicTreeHighLightNode(findTopicNode);
    };

    const onPureTextTreeDataCheckedChange = (checkedKeys: string[]) => {
        // console.log("onPureTextTreeDataCheckedChange--->", checkedKeys);
        // setPureTextTreeDataCheckedKeyList(checkedKeys);
        let _tempTopicList = topicList;
        if (!isEmpty(pureTextTopicTreeDataSearchValue)) {
            const filteredTopicList: TopicType[] = tree2List([
                ...filteredForPureTextTopicTree,
            ]);
            const tempTopicTreeSelectNodeIdList = pureTextTreeDataCheckedKeyList;
            const tempDiffTopicTreeSelectNodeIdList = [];
            tempTopicTreeSelectNodeIdList.forEach((id) => {
                const find = filteredTopicList.find((node) => {
                    return node.id == id;
                });
                if (!find) {
                    tempDiffTopicTreeSelectNodeIdList.push(id);
                }
            })
            const mergedTopicTreeSelectNodeIdList =
                tempDiffTopicTreeSelectNodeIdList.concat(checkedKeys);
            let _tempTopicList = topicList;
            _tempTopicList.forEach((topic) => {
                if (mergedTopicTreeSelectNodeIdList.includes(topic.id)) {
                    topic.checked = true;
                } else if (topic.topicType == 'text') {
                    topic.checked = false;
                    topic.children.forEach(subNode => {
                        if (subNode.topicType == 'device') {
                            console.log("取消项目节点勾选")
                            subNode.checked = false;
                        }
                    })
                }
            })
            setPureTextTreeDataCheckedKeyList(mergedTopicTreeSelectNodeIdList);
        } else {
            setPureTextTreeDataCheckedKeyList(checkedKeys);
            _tempTopicList.forEach((topic) => {
                if (checkedKeys.includes(topic.id)) {
                    topic.checked = true;
                } else if (topic.topicType == 'text') {
                    topic.checked = false;
                    topic.children.forEach(subNode => {
                        if (subNode.topicType == 'device') {
                            // console.log("取消项目节点勾选")
                            subNode.checked = false;
                        }
                    })
                }
            });
        }
        updateTopicList(deepCloneV2(_tempTopicList));
    };

    const scrollTo = useCallback(
        throttle((target) => {
            const { bottom: currentBottom, top: currentTop } =
                target.getBoundingClientRect();
            const { bottom: boxBottom, top: boxTop } = document
                .getElementsByClassName("ant-tree-list-holder")[1]
                .getBoundingClientRect();
            if (currentTop > boxBottom - 48) {
                document.getElementsByClassName("ant-tree-list-holder")[1].scrollTop =
                    document.getElementsByClassName("ant-tree-list-holder")[1].scrollTop +
                    32;
            }
            if (boxTop + 48 > currentBottom) {
                document.getElementsByClassName("ant-tree-list-holder")[1].scrollTop =
                    document.getElementsByClassName("ant-tree-list-holder")[1].scrollTop -
                    32;
            }
        }, 100),
        [pureTextTopicTreeRef]
    );

    const onDragOver = (info: any) => {
        if (info.event.target) {
            scrollTo(info.event.target);
        }
    };

    const onDragStart = (info) => {
        setDraggingNodeKey(info.node.key);
    };

    const onDragEnd = (e) => {
        setDraggingNodeKey(null);
    };

    const findTreeNode = (
        treeData: TopicType[],
        targetNodeId: string
    ): TopicType => {
        let findNode = null;
        treeData.forEach((node) => {
            if (node.id == targetNodeId) {
                findNode = node;
            } else if (node.children && node.children.length && !findNode) {
                let _findNode = findTreeNode(node.children, targetNodeId);
                if (_findNode) {
                    findNode = _findNode;
                }
            }
        });
        return findNode;
    };

    const findNode = (key, data) => {
        let result;
        const find = (key, data) => {
            data.forEach((item) => {
                if (item.key === key) {
                    result = item;
                } else if (item.children) {
                    find(key, item.children);
                }
            });
        };
        find(key, data);
        return result;
    };

    const deleteNode = (key, data) => {
        data.forEach((item, index, arr) => {
            if (item.key === key) {
                arr.splice(index, 1);
            } else if (item.children) {
                deleteNode(key, item.children);
            }
        });
    };

    const updateNodePid = (node: TopicType, pid: string): TopicType => {
        node.pid = pid;
        if (node.children) {
            node.children.forEach((child) => updateNodePid(child, node.key));
        }
        return node;
    };

    // 获取兄弟节点和父节点的 key
    const getSiblingsAndParent = (key, data, parentKey = null) => {
        for (const item of data) {
            if (item.key === key) {
                return { siblings: data, parentKey };
            }
            if (item.children) {
                const result = getSiblingsAndParent(key, item.children, item.key);
                if (result) return result;
            }
        }
        return null;
    };

    function reorderParentArrayBasedOnSubset(parent, subset) {
        const parentMap = new Map(parent.map((e) => [e.id, e]));
        // 更新父集中的元素属性
        subset.forEach((subElement) => {
            if (parentMap.has(subElement.id)) {
                // 直接在映射中更新元素属性
                Object.assign(parentMap.get(subElement.id), subElement);
            }
        });

        // 创建一个排序后的父集数组
        const sortedAndUpdatedParent = parent
            .filter((e) => parentMap.has(e.id)) // 保证只包括现在还存在的元素
            .sort((a, b) => {
                // 使用子集的顺序来排序父集中的元素
                const indexA = subset.findIndex((e) => e.id === a.id);
                const indexB = subset.findIndex((e) => e.id === b.id);
                return indexA - indexB;
            });

        // 返回更新和排序后的父集
        return sortedAndUpdatedParent;
    }

    const onTopicNodeDrop = (info) => {
        setDraggingNodeKey("");
        if (!isEmpty(pureTextTopicTreeDataSearchValue)) {
            toastShort("error", "搜索模式下不可使用拖拽功能");
            return;
        }
        const treeData = deepCloneV2(pureTextTopicTreeData);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split("-");
        const dropPosition =
            info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const targetNode = info.node;
        const dropNode = findNode(dropKey, treeData);
        const dragNode = findNode(dragKey, treeData);
        let newDragNode = dragNode;
        if (dropNode.topicType === "device" && !info.dropToGap) {
            toastShort("error", "当前节点不可拖拽在所选位置");
            return;
        }
        deleteNode(dragKey, treeData);
        if (info.dropToGap) {
            const siblingsAndParent = getSiblingsAndParent(dropKey, treeData);
            if (!siblingsAndParent) return; // 确保找到了兄弟节点和父节点

            const siblings = siblingsAndParent.siblings;
            const parentKey = siblingsAndParent.parentKey;
            const index = siblings.findIndex((item) => item.key === dropKey);
            if (dropPosition < 0) {
                siblings.splice(Math.max(0, index), 0, newDragNode); // 确保即使index为-1，也能正确处理
            } else {
                siblings.splice(index + 1, 0, newDragNode);
            }
            newDragNode = updateNodePid(newDragNode, parentKey);
        } else {
            if (dragNode && dragNode.pid == targetNode.id) {
                dfsRecursive(treeData, (node: TopicType) => {
                    if (node.id == targetNode.id) {
                        let findIndex = node.children.findIndex(
                            (topic) => topic.id == dragNode.id
                        );
                        let needToMoveDownNodeIndexList = [];
                        node.children.forEach((ele, index) => {
                            if (index <= findIndex) {
                                needToMoveDownNodeIndexList.push(index);
                            }
                        });
                        for (let i = needToMoveDownNodeIndexList.length - 1; i > 0; i--) {
                            const currentNodeIndex = needToMoveDownNodeIndexList[i];
                            const currentNodeBeforeIndex = needToMoveDownNodeIndexList[i - 1];
                            node.children = swapListElements(
                                node.children,
                                currentNodeBeforeIndex,
                                currentNodeIndex
                            );
                        }
                    }
                });
            } else {
                const dropNode = findNode(dropKey, treeData);
                if (dropNode.topicType === "device") {
                    return;
                }
                dropNode.children = dropNode.children || [];
                dropNode.children.unshift(dragNode);
                newDragNode = updateNodePid(dragNode, dropNode.id);
            }
        }
        //更新到数据
        if (isEmpty(newDragNode.pid)) {
            newDragNode.pid = "0";
        }
        const currentTreeDataTopicList: TopicType[] = [...tree2List(treeData)];
        currentTreeDataTopicList.forEach((ele, index) => {
            ele.index = index;
            if (newDragNode && ele.id == newDragNode.id) {
                ele = deepCloneV2(newDragNode);
            }
        });
        let newTopicList = reorderParentArrayBasedOnSubset(
            topicList,
            currentTreeDataTopicList
        );
        updateTopicList(deepCloneV2(newTopicList));
    };

    const filterFn = (data, filterText) => {
        if (!filterText) {
            return true;
        }
        return new RegExp(filterText, "i").test(data.title);
    };

    const onPureTextTopicTreeDataSearchValueChange = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const newSearchValue = getChangeEventValue(e);
        setPureTextTopicTreeDataSearchValue(newSearchValue);
        if (!isEmpty(newSearchValue)) {
            let tempFilteredTopicTree = arrayTreeFilter(
                deepCloneV2(pureTextTopicTreeData),
                filterFn,
                newSearchValue
            );
            let tempFilteredTopicTreeExpandedKeys = expandedKeysFun(
                tempFilteredTopicTree
            );
            setFilteredForPureTopicTreeData(tempFilteredTopicTree);
            setFilteredForPureTopicTreeExpandedKeys(
                tempFilteredTopicTreeExpandedKeys
            );
        }
    };

    const onPureTextTopicTreeExpanded = (keys: string[]) => {
        setPureTextTopicTreeExpandedKeys(deepCloneV2(keys));
    };

    const expandAllNode = () => {
        let allKeys = [];
        dfsRecursive(pureTextTopicTreeData, (node: TopicType) => {
            allKeys.push(node.id);
        });
        setPureTextTopicTreeExpandedKeys(allKeys);
    };

    const unExpandAllNode = () => {
        setPureTextTopicTreeExpandedKeys([]);
    };

    const expandRealTopicTreeAllNode = () => {
        let allKeys = [];
        dfsRecursive(realTextTopicTreeData, (node: TopicType) => {
            allKeys.push(node.id);
        });
        setRealTextTopicTreeExpandedKeys(allKeys);
    };

    const unExpandRealTopicTreeAllNode = () => {
        setRealTextTopicTreeExpandedKeys([]);
    };

    const sortDeviceNodeList = (topicList: TopicType[], sortType: DeviceSortType): TopicType[] => {
        return topicList.sort((a, b) => {
            if (deviceListSortType == "default_up") {
                const parentOrder = a.parentTextTopicTitle.localeCompare(b.parentTextTopicTitle, 'zh-Hans-CN', { sensitivity: 'accent' });
                if (parentOrder === 0) {
                    return a.topicName.localeCompare(b.topicName, 'zh-Hans-CN', { sensitivity: 'accent' });
                }
                return parentOrder;
                // return a.topicName.localeCompare(b.topicName, 'zh-Hans-CN', { sensitivity: 'accent' });
            } else if (deviceListSortType == "default_down") {
                const parentOrder = b.parentTextTopicTitle.localeCompare(a.parentTextTopicTitle, 'zh-Hans-CN', { sensitivity: 'accent' });
                if (parentOrder === 0) {
                    return b.topicName.localeCompare(a.topicName, 'zh-Hans-CN', { sensitivity: 'accent' });
                }
                return parentOrder;
                // return b.topicName.localeCompare(a.topicName, 'zh-Hans-CN', { sensitivity: 'accent' });
            } else if (deviceListSortType == "checked") {
                if (a.checked && !b.checked) {
                    return -1;
                }
                if (b.checked && !a.checked) {
                    return 1;
                }
            }
            return 0;
        });
    }

    const deviceNodeList = useMemo(() => {
        let tempDeviceNodeList: TopicType[] = [];
        if (realTextTopicTreeSelectedNode) {
            dfsRecursive(topicTree, (node: TopicType, level: number) => {
                if (node.id == realTextTopicTreeSelectedNode.id) {
                    node.children.forEach(subNode => {
                        if (subNode.topicType == 'device') {
                            tempDeviceNodeList.push(subNode);
                        }
                    })
                }
            });
        }
        const _deviceNodeList = tempDeviceNodeList
            .filter(
                (node) =>
                    node.title.includes(deviceTopicSearchValue)
            )
        // let _tempTopicList = tree2List()
        _deviceNodeList.forEach(topic => {
            let findTopic = topicList.find(item => item.id == topic.id);
            console.log("findTopic--->", findTopic)
        })

        console.log("最新的_deviceNodeList--->", realTextTopicTreeSelectedNode, tempDeviceNodeList);
        return _deviceNodeList;
    }, [
        topicList,
        realTextTopicTreeSelectedNode,
        deviceTopicSearchValue,
        deviceListSortType,
        currentDeviceType,
    ]);

    const onDeviceTopicSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDeviceTopicSearchValue(getChangeEventValue(e));
    };

    const checkDeviceNodeIsChecked = (node: TopicType) => {
        return node.checked;
    };

    const checkDeviceNodeIsDisable = (node: TopicType) => {
        // return false;
        if (
            realTextTopicTreeSelectedNode &&
            node.pid !== realTextTopicTreeSelectedNode.id &&
            node.checked
        ) {
            return true;
        } else {
            return false;
        }
    }

    const onCheckedChange = (e: CheckboxChangeEvent, node: TopicType) => {
        // e.stopPropagation();
        // e.preventDefault();
        const checked = getCheckBoxChangeEventValue(e);
        let tempTopicList = topicList;
        tempTopicList.forEach((deviceNode) => {
            if (deviceNode.id == node.id) {
                deviceNode.checked = checked;
                // console.log("onCheckedChange--->deviceNode--->", deviceNode)
            }
        })
        updateTopicList(deepCloneV2(tempTopicList))
    }

    const formatDeviceTopicSearchText = (originStr: string) => {
        try {
            if (isEmpty(deviceTopicSearchValue)) {
                return originStr;
            }
            let reg = new RegExp(deviceTopicSearchValue);
            let str = originStr.split(reg);
            let txt = [];
            if (str.length === 0 || str.length === 1) {
                return originStr;
            }
            if (str.length > 0) {
                txt.push(<span key={"0-0"}>{str[0]}</span>);
            }
            txt.push(
                <span key={"0-1"} style={{ color: "#ff0000" }}>
                    {deviceTopicSearchValue}
                </span>
            );
            if (str.length > 1) {
                txt.push(<span key={"0-2"}>{str[1]}</span>);
            }
            if (str.length >= 3) {
                for (let i = 2; i < str.length; i++) {
                    txt.push(
                        <span key={"0-3"} style={{ color: "#ff0000" }}>
                            {deviceTopicSearchValue}
                        </span>
                    );
                    txt.push(<span key={"0-4"}>{str[i]}</span>);
                }
            }
            return txt;
        } catch (e) {
            return originStr;
        }
    };

    /************ 规划大纲的操作树相关逻辑 start ************/

    const addTopicModalRefForPureTopicTree = useRef<any>(null);
    const addTopicModalRefForRealTopicTree = useRef<any>(null);

    const renameTopicModalRef = useRef<any>(null);
    const editTopicModalNewRef = useRef<any>(null);
    const [currentTreeNodeActionType, setCurrentTreeNodeActionType] =
        useState<TopicNodeActionType>(null);

    const onContextMenuClose = () => {
        setPureTextTopicTreeHighLightNode(null);
    };

    const moveNode = (
        nodeList: TopicType[],
        targetId: string,
        direction: "up" | "down" | "top" | "bottom"
    ): TopicType[] => {
        let findIndex = -1;
        if (nodeList.length == 0 || nodeList.length == 1) {
            return nodeList;
        }
        for (let i = 0; i < nodeList.length; i++) {
            if (nodeList[i].id == targetId) {
                findIndex = i;
                break;
            }
        }
        if (findIndex > -1) {
            const targetNodePid = nodeList[findIndex].pid;
            switch (direction) {
                case "top":
                    let needToMoveDownNodeIndexList: number[] = [];
                    for (let i = 0; i < nodeList.length; i++) {
                        if (nodeList[i].pid == targetNodePid) {
                            if (i <= findIndex) {
                                needToMoveDownNodeIndexList.push(i);
                            }
                        }
                    }
                    if (needToMoveDownNodeIndexList[0] == findIndex) {
                        toastShort("warning", "该节点已置顶，请勿重复操作");
                    } else {
                        toastShort("success", commonSuccessMsg);
                        let _nodeList = nodeList;
                        for (let i = needToMoveDownNodeIndexList.length - 1; i > 0; i--) {
                            const currentNodeIndex = needToMoveDownNodeIndexList[i];
                            const currentNodeBeforeIndex = needToMoveDownNodeIndexList[i - 1];
                            _nodeList = swapListElements(
                                _nodeList,
                                currentNodeBeforeIndex,
                                currentNodeIndex
                            );
                        }
                        return _nodeList;
                    }
                    break;
                case "bottom":
                    let needToMoveUpNodeIndexList: number[] = [];
                    for (let i = 0; i < nodeList.length; i++) {
                        if (nodeList[i].pid == targetNodePid) {
                            if (i >= findIndex) {
                                needToMoveUpNodeIndexList.push(i);
                            }
                        }
                    }
                    if (
                        needToMoveUpNodeIndexList[needToMoveUpNodeIndexList.length - 1] ==
                        findIndex
                    ) {
                        toastShort("warning", "该节点已置底，请勿重复操作");
                    } else {
                        toastShort("success", commonSuccessMsg);
                        let _nodeList = nodeList;
                        for (let i = 0; i < needToMoveUpNodeIndexList.length; i++) {
                            const currentNodeIndex = needToMoveUpNodeIndexList[i];
                            const currentNodeBeforeIndex = needToMoveUpNodeIndexList[i - 1];
                            _nodeList = swapListElements(
                                _nodeList,
                                currentNodeBeforeIndex,
                                currentNodeIndex
                            );
                        }
                        return _nodeList;
                    }
                    break;
                case "up":
                    if (
                        nodeList[findIndex - 1] &&
                        nodeList[findIndex - 1].pid == targetNodePid
                    ) {
                        let currentChildrenNodeIndexList: number[] = [];
                        for (let i = 0; i < nodeList.length; i++) {
                            if (nodeList[i].pid == targetNodePid) {
                                if (i <= findIndex) {
                                    currentChildrenNodeIndexList.push(i);
                                }
                            }
                        }
                        let _nodeList = nodeList;
                        _nodeList = swapListElements(
                            _nodeList,
                            currentChildrenNodeIndexList[
                            currentChildrenNodeIndexList.length - 2
                            ],
                            currentChildrenNodeIndexList[
                            currentChildrenNodeIndexList.length - 1
                            ]
                        );
                        toastShort("success", commonSuccessMsg);
                        return _nodeList;
                    } else {
                        toastShort("warning", "该节点已置顶，不可继续上移");
                    }
                    break;
                case "down":
                    if (
                        nodeList[findIndex + 1] &&
                        nodeList[findIndex + 1].pid == targetNodePid
                    ) {
                        toastShort("success", commonSuccessMsg);
                        const _nodeList = swapListElements(
                            nodeList,
                            findIndex,
                            findIndex + 1
                        );
                        return _nodeList;
                    } else {
                        toastShort("warning", "该节点已置底，不可继续下移");
                    }
                    break;
                default:
                    break;
            }
            return nodeList;
        } else {
            toastShort("error", "节点不存在，请您刷新页面重试！");
        }
    };

    const handleMoveCurrentSelectedNode = (
        moveType: "up" | "down" | "top" | "bottom"
    ) => {
        try {
            let _tempTopicList = topicList;
            _tempTopicList = moveNode(
                _tempTopicList,
                pureTextTopicTreeSelectedNode.id,
                moveType
            );
            updateTopicList(deepCloneV2(_tempTopicList));
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const [modal, contextHolder] = Modal.useModal();

    const handleDeleteNode = (node: TopicType) => {
        // handleStopPropagation(e);
        console.log("直接删除node---->", node)
        // setPureTextTopicTreeSelectedNode(node);
        // setPureTextTopicTreeHighLightNode(node);
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: `确认删除此${node.topicType == "device" ? "项目" : "大纲"
                }？`,
            okText: "确认",
            cancelText: "取消",
            centered: true,
            onOk: () => confirmToDeleteTargetNode(node),
        });
    }

    const confirmToDeleteTargetNode = (targetNode: TopicType) => {
        try {
            let _tempTopicList = topicList;
            for (let i = 0; i < _tempTopicList.length; i++) {
                if (_tempTopicList[i].id == targetNode.id) {
                    _tempTopicList.splice(i, 1);
                    break;
                }
            }
            updateTopicList(deepCloneV2(_tempTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const handleDeleteCurrentSelectedNodeV2 = () => {
        if(pureTextTopicTreeSelectedNode && !pureTextTopicTreeSelectedNode.id.includes('custom')){
            return toastShort('error', '系统大纲无法删除')
        }
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: `确认删除此${pureTextTopicTreeSelectedNode.topicType == "device" ? "项目" : "大纲"
                }？`,
            okText: "确认",
            cancelText: "取消",
            centered: true,
            // onOk: () => confirmToDeleteCurrentSelectedNodeV2(pureTextTopicTreeSelectedNode),
            onOk: () => confirmToDeleteTargetNode(pureTextTopicTreeSelectedNode),
        });
    }

    const handleDeleteCurrentSelectedNode = () => {
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: `确认删除此${pureTextTopicTreeSelectedNode.topicType == "device" ? "项目" : "大纲"
                }？`,
            okText: "确认",
            cancelText: "取消",
            centered: true,
            onOk: confirmToDeleteCurrentSelectedNode,
        });
    };

    const confirmToDeleteCurrentSelectedNodeV2 = (targetNode?: TopicType) => {
        console.log("targetNode--->", targetNode)
        try {
            let _tempTopicList = topicList;
            for (let i = 0; i < _tempTopicList.length; i++) {
                if (_tempTopicList[i].id == targetNode ? targetNode.id : pureTextTopicTreeSelectedNode.id) {
                    _tempTopicList.splice(i, 1);
                    break;
                }
            }
            updateTopicList(deepCloneV2(_tempTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const confirmToDeleteCurrentSelectedNode = (targetNode?: TopicType) => {
        try {
            let _tempTopicList = topicList;
            for (let i = 0; i < _tempTopicList.length; i++) {
                if (_tempTopicList[i].id == targetNode ? targetNode.id : pureTextTopicTreeSelectedNode.id) {
                    _tempTopicList.splice(i, 1);
                    break;
                }
            }
            updateTopicList(deepCloneV2(_tempTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const findAllDescendants = (treeData: TopicType[], nodeId: string) => {
        const result = [];
        function findDescendants(node) {
            if (!node || !node.children) return;
            node.children.forEach((child) => {
                result.push(child.id);
                findDescendants(child);
            });
        }

        function findNodeAndDescendants(nodes) {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].id === nodeId) {
                    findDescendants(nodes[i]);
                    break;
                }
                if (nodes[i].children) {
                    findNodeAndDescendants(nodes[i].children);
                }
            }
        }
        findNodeAndDescendants(treeData);
        return result;
    };

    const findTreePathByTargetNodeIdList = (
        treeData: TopicType[],
        nodeId: string
    ) => {
        const map = new Map();
        // 建立每个节点与其父节点的映射
        function buildMap(node, parentId = null) {
            if (!node) return;
            map.set(node.id, parentId);
            if (node.children) {
                node.children.forEach((child) => buildMap(child, node.id));
            }
        }
        // 遍历树数据，建立映射
        treeData.forEach((node) => buildMap(node));
        // 从指定节点回溯到根节点
        const path = [];
        let currentId = nodeId;
        while (currentId !== null) {
            path.push(currentId);
            currentId = map.get(currentId);
        }
        return path.reverse(); // 反转数组，使其从根节点开始
    };

    const confirmToUnCheckedCurrentSelectedNode = () => {
        try {
            // //需要直接取消勾选的节点
            let needToUnCheckedIdList = findAllDescendants(
                topicTree,
                pureTextTopicTreeSelectedNode.id
            );
            // //需要取消勾选的节点(如果此节点是勾选的话);
            let needToUnCheckedIfNodeChecedIdList = findTreePathByTargetNodeIdList(
                topicTree,
                pureTextTopicTreeSelectedNode.id
            );
            const tempTopicTreeData = generateTreeData(topicList);
            dfsRecursive(tempTopicTreeData, (node: TopicType) => {
                if (node.id == pureTextTopicTreeSelectedNode.id) {
                    node.checked = false;
                }
                if (needToUnCheckedIdList.includes(node.id)) {
                    node.checked = false;
                }
                if (needToUnCheckedIfNodeChecedIdList.includes(node.id)) {
                    node.checked = false;
                }
            });
            updateTopicList(deepCloneV2(tree2List(tempTopicTreeData)));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const handleUnCheckedCurrentSelectedNode = () => {
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: "确认从本文大纲中移除此节点吗？移除后您可在大纲配置中重新勾选",
            okText: "确认",
            cancelText: "取消",
            centered: true,
            onOk: confirmToUnCheckedCurrentSelectedNode,
        });
    }

    const onPickerTreeNodeActionTypeForPureTopicTree = (
        actionType: TopicNodeActionType,
        _pureTextTopicTreeSelectedNode?: TopicType
    ) => {
        setCurrentTreeNodeActionType(actionType);
        switch (actionType) {
            case "AddDeviceChildNode":
                addTopicModalRefForPureTopicTree.current.openModal();
                break;
            case "AddTextChildNode":
                if (Number(pureTextTopicTreeSelectedNode.topicLevel) >= 6) {
                    return toastShort("error", "大纲节点最多不能超过6级");
                }
                addTopicModalRefForPureTopicTree.current.openModal();
                break;
            case "AddDevicePeerNode":
                addTopicModalRefForPureTopicTree.current.openModal();
                break;
            case "AddTextPeerNode":
                addTopicModalRefForPureTopicTree.current.openModal();
                break;
            case "MoveUp":
                handleMoveCurrentSelectedNode("up");
                break;
            case "MoveDown":
                handleMoveCurrentSelectedNode("down");
                break;
            case "MoveTop":
                handleMoveCurrentSelectedNode("top");
                break;
            case "MoveBottom":
                handleMoveCurrentSelectedNode("bottom");
                break;
            case "ReName":
                renameTopicModalRef.current.openModal(
                    _pureTextTopicTreeSelectedNode
                        ? _pureTextTopicTreeSelectedNode.topicName
                        : pureTextTopicTreeSelectedNode.topicName
                );
                break;
            case "Delete":
                // handleDeleteNode(_pureTextTopicTreeSelectedNode);
                handleDeleteCurrentSelectedNodeV2();
                // handleDeleteCurrentSelectedNode();
                break;
            case "UnChecked":
                handleUnCheckedCurrentSelectedNode();
                break;
            default:
                break;
        }
    };

    const onPickerTreeNodeActionTypeForRealTopicTree = (
        actionType: TopicNodeActionType,
    ) => {
        setCurrentTreeNodeActionType(actionType);
        switch (actionType) {
            case "AddDeviceChildNode":
                addTopicModalRefForRealTopicTree.current.openModal();
                break;
            case "AddTextChildNode":
                if (Number(realTextTopicTreeSelectedNode.topicLevel) >= 6) {
                    return toastShort("error", "大纲节点最多不能超过6级");
                }
                addTopicModalRefForRealTopicTree.current.openModal();
                break;
            case "AddDevicePeerNode":
                addTopicModalRefForRealTopicTree.current.openModal();
                break;
            case "AddTextPeerNode":
                addTopicModalRefForRealTopicTree.current.openModal();
                break;
            default:
                break;
        }
    };

    const onChangeTopicForPureTopicTree = (newTopic: TopicType, originTopic: TopicType) => {
        try {
            let _tempTreeData = topicTree;
            let _checkedTopicIdList = pureTextTreeDataCheckedKeyList;
            if (originTopic) {
                deleteNode(originTopic.id, _tempTreeData);
            }
            newTopic.checked = true;
            newTopic.childrenDeviceTopicListLength = 0;
            _checkedTopicIdList.push(newTopic.id);
            setPureTextTreeDataCheckedKeyList([..._checkedTopicIdList]);
            let newTopicNodeList = [newTopic];
            switch (currentTreeNodeActionType) {
                case "AddDeviceChildNode":
                    insertChildNodeListToTree(
                        _tempTreeData,
                        newTopicNodeList,
                        pureTextTopicTreeSelectedNode.id
                    );
                    break;
                case "AddTextChildNode":
                    insertChildNodeListToTree(
                        _tempTreeData,
                        newTopicNodeList,
                        pureTextTopicTreeSelectedNode.id
                    );
                    break;
                case "AddDevicePeerNode":
                    if (pureTextTopicTreeSelectedNode.pid == "0") {
                        _tempTreeData = insertTopPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            pureTextTopicTreeSelectedNode.id
                        );
                    } else {
                        insertPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            pureTextTopicTreeSelectedNode.id
                        );
                    }
                    break;
                case "AddTextPeerNode":
                    if (pureTextTopicTreeSelectedNode.pid == "0") {
                        _tempTreeData = insertTopPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            pureTextTopicTreeSelectedNode.id
                        );
                    } else {
                        insertPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            pureTextTopicTreeSelectedNode.id
                        );
                    }
                    break;
                default:
                    break;
            }
            const _tempTopicList = [...tree2List(_tempTreeData)];
            updateTopicList(deepCloneV2(_tempTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const onChangeTopicForRealTopicTree = (newTopic: TopicType, originTopic: TopicType) => {
        try {
            let _tempTreeData = topicTree;
            let _checkedTopicIdList = pureTextTreeDataCheckedKeyList;
            if (originTopic) {
                deleteNode(originTopic.id, _tempTreeData);
            }
            newTopic.checked = true;
            newTopic.childrenDeviceTopicListLength = 0;
            _checkedTopicIdList.push(newTopic.id);
            setPureTextTreeDataCheckedKeyList([..._checkedTopicIdList]);
            let newTopicNodeList = [newTopic];
            switch (currentTreeNodeActionType) {
                case "AddDeviceChildNode":
                    insertChildNodeListToTree(
                        _tempTreeData,
                        newTopicNodeList,
                        realTextTopicTreeSelectedNode.id
                    );
                    break;
                case "AddTextChildNode":
                    insertChildNodeListToTree(
                        _tempTreeData,
                        newTopicNodeList,
                        realTextTopicTreeSelectedNode.id
                    );
                    break;
                case "AddDevicePeerNode":
                    if (realTextTopicTreeSelectedNode.pid == "0") {
                        _tempTreeData = insertTopPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            realTextTopicTreeSelectedNode.id
                        );
                    } else {
                        insertPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            realTextTopicTreeSelectedNode.id
                        );
                    }
                    break;
                case "AddTextPeerNode":
                    if (realTextTopicTreeSelectedNode.pid == "0") {
                        _tempTreeData = insertTopPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            realTextTopicTreeSelectedNode.id
                        );
                    } else {
                        insertPeerNodeListToTree(
                            _tempTreeData,
                            newTopicNodeList,
                            realTextTopicTreeSelectedNode.id
                        );
                    }
                    break;
                default:
                    break;
            }
            const _tempTopicList = [...tree2List(_tempTreeData)];
            updateTopicList(deepCloneV2(_tempTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    };

    const onChangeTopicForDeviceNodeList = (node: TopicType) => {
        try {
            // console.log("编辑好的是---->", node);
            let _tempTreeData = topicTree;
            const _tempTopicList: TopicType[] = [...tree2List(_tempTreeData)];
            // let need
            let newTopicList = _tempTopicList.map(topic => {
                if (topic.id == node.id) {
                    // console.log("更新替换上去---->", topic)
                    return deepCloneV2(node)
                }
                return topic;
            })
            updateTopicList(deepCloneV2(newTopicList));
            toastShort("success", commonSuccessMsg);
        } catch (e) {
            toastShort("error", commonErrorMsg);
        }
    }

    const _onReNameFinish = (newName: string) => {
        let _tempTopicList = topicList;
        _tempTopicList.forEach((topic) => {
            if (topic.id == pureTextTopicTreeSelectedNode.id) {
                topic.topicName = newName;
            }
        });
        updateTopicList(deepCloneV2(_tempTopicList));
    };

    /************ 规划大纲的操作树相关逻辑 end *************/

    const resetTopicListCheckedStatus = () => {
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: `确认恢复规划项目为系统默认推荐？`,
            okText: "确认",
            cancelText: "取消",
            centered: true,
            onOk: confirmToResetTopicListCheckedStatus,
        });
    }

    const resetTopicHistoryList = (newTopicList: TopicType[]) => {
        setTopicList(newTopicList);
        setTopicHistoryIndex(0);
        const checkedPureTextTopicIdList = getInitPureTextTopicCheckedIdList(newTopicList);
        setPureTextTreeDataCheckedKeyList(checkedPureTextTopicIdList);
        const initTopicListHistoryList = [{
            _topicList: newTopicList,
            _pureTextTreeDataCheckedKeyList: deepCloneV2(checkedPureTextTopicIdList)

        }];
        setTopicListHistoryList([...initTopicListHistoryList]);
    }

    const confirmToResetTopicListCheckedStatus = () => {
        let tempTopicList = topicList;
        const checkedKeys: string[] = [];
        tempTopicList.forEach(topic => {
            if (topic.topicType == 'device') {
                topic.checked = topic.defaultChecked;
            } else if (topic.topicType == 'text' && topic.defaultChecked) {
                // checkedKeys.push(topic.id);
            }
        })
        updateTopicList(deepCloneV2(tempTopicList));;
    }

    const checkIsSelectedAllDeviceToicList = (): boolean => {
        let isSelectedAll = true;
        if (deviceNodeList.length == 0) {
            isSelectedAll = false;
        }
        deviceNodeList.forEach((node) => {
            if (!checkDeviceNodeIsChecked(node)) {
                isSelectedAll = false;
            }
        });
        return isSelectedAll;
    };

    const switchCheckAllStatus = () => {
        const isCheckedAll = checkIsSelectedAllDeviceToicList();
        let desc = isCheckedAll
            ? "确认将面板内规划项目全部取消勾选？"
            : "确认全部勾选面板内规划项目，并将其放置到当前选中的规划大纲中";
        modal.confirm({
            title: "温馨提示",
            icon: <ExclamationCircleOutlined />,
            content: desc,
            okText: "确认",
            cancelText: "取消",
            centered: true,
            onOk: confirmToSwitchCheckedAllStatus,
        });
    };

    const confirmToSwitchCheckedAllStatus = () => {
        const isCheckedAll = checkIsSelectedAllDeviceToicList();
        const checked = !isCheckedAll;
        let tempTopicList = topicList;
        deviceNodeList.forEach((node) => {
            const deviceNode = tempTopicList.find((ele) => ele.id == node.id);
            deviceNode.checked = checked;
            deviceNode.pid = realTextTopicTreeSelectedNode.id;
        })
        updateTopicList(deepCloneV2(tempTopicList))
    };

    const handleResetDeviceListRecommand = () => {
        // console.log("当前的设备列表---->", deviceNodeList);
        let tempTopicList = topicList;
        let tempDeviceNodeList = [...deviceNodeList];
        tempDeviceNodeList.forEach(topic => {
            if (topic.topicType == 'device' && !topic.id.toString().includes('custom')) {
                topic.checked = topic.defaultChecked;
            }
        })
        tempDeviceNodeList.forEach(deviceNode => {
            let findIndex = tempTopicList.findIndex(topic => topic.id == deviceNode.id);
            if (findIndex > -1) {
                tempTopicList[findIndex] = { ...deviceNode };
            }
        })
        toastShort('success', '已还原本节大纲默认项目推荐')
        updateTopicList(deepCloneV2(tempTopicList));
    }

    const handleOpenAddDeviceTopicModal = () => {
        onPickerTreeNodeActionTypeForRealTopicTree("AddDeviceChildNode");
    };

    const confirmToUpdateTopicList = (_topicList?: TopicType[]) => {
        setConfirmLoading(true);
        let tempTopicList = _topicList ? _topicList : topicList;
        let tempPureTextTreeDataCheckedKeyList = pureTextTreeDataCheckedKeyList;
        try {
            tempTopicList.forEach((node) => {
                if (tempPureTextTreeDataCheckedKeyList.includes(node.id)) {
                    node.checked = true;
                }
            })
            let newTopicTree = generateTreeData(tempTopicList);
            newTopicTree = updateTopicRealCheckedStatus(newTopicTree);
            dfsRecursive(newTopicTree, (node: TopicType) => {
                if (node.topicType == "text") {
                    //如果大纲中，该节点是选中状态
                    if (tempPureTextTreeDataCheckedKeyList.includes(node.id)) {
                        node.checked = true;
                    } else {
                        // let 
                        // if (node.realCheckedStatus == 'noChecked') {
                        //     node.checked = false;
                        //     if (node.children) {
                        //         node.children.forEach(subNode => {
                        //             if (subNode.topicType == 'device') {
                        //                 subNode.checked = false;
                        //             }
                        //         })
                        //     }
                        // }
                    }
                    //该大纲节点下所有直接设备子节点
                    let childDeviceTopicTotalCount = 0;
                    //该大纲节点下所有选中的设备节点
                    let childDeviceTopucCheckedCount = 0;
                    if (node.children && node.children.length) {
                        node.children.forEach((subNode) => {
                            if (subNode.topicType == "device") {
                                if (subNode.checked) {
                                    childDeviceTopucCheckedCount++;
                                }
                                childDeviceTopicTotalCount++;
                            }
                        })
                    }
                    if (!tempPureTextTreeDataCheckedKeyList.includes(node.id)) {
                        if (node.children && node.children.length == childDeviceTopicTotalCount) {
                            // console.log("这是设备子节点的直接上级文本大纲----->", node.topicName);
                            node.children.forEach(subNode => {
                                if (subNode.topicType == 'device') {
                                    subNode.checked = false;
                                }
                            })
                        }
                    }
                }
            });
            newTopicTree = updateTopicRealCheckedStatus(newTopicTree);
            const newTopicList = tree2List(newTopicTree);
            const _newTopicList = deepCloneV2(newTopicList);
            let _tempComDocInstance = comDocInstance;
            _tempComDocInstance.topicList = _newTopicList;
            handleUpdateComDocInstance(deepCloneV2(_tempComDocInstance));
            resetTopicHistoryList(_newTopicList);
            emitter.emit("AutoSwitchNavType", { newNavType: "excel" });
            if (!_topicList) {
                toastShort("success", "大纲配置已更新，已为您自动跳转至投资估算表格");
            } else {
                toastShort("success", "重置成功！")
            }
            setConfirmLoading(false);
        } catch (e) {
            toastShort("error", "保存失败,请您稍后重试");
            setConfirmLoading(false);
        }
        // }, 500);
        // delayJumpTimer.current = setTimeout(() => {
        //     emitter.emit("AutoSwitchNavType", { newNavType: "excel" });
        // }, 1000);
    };

    const handleUpdateComDocInstance = (newComDocInstance: DocInstance) => {
        let _newComDocInstance = processDocInstanceBeforeUpdate(newComDocInstance);
        _newComDocInstance.updateComponentName = "TopicManagePage";
        _newComDocInstance.topicList = newComDocInstance.topicList.map((topic) => {
            delete topic.children;
            return topic;
        });
        setDelayUpdateComDocInstance({ ..._newComDocInstance });
    };

    const onRealTextTopicTreeDataSearchValueChange = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const newSearchValue = getChangeEventValue(e);
        setRealTextTopicTreeDataSearchValue(getChangeEventValue(e));
        if (!isEmpty(newSearchValue)) {
            let tempFilteredTopicTree = arrayTreeFilter(
                deepCloneV2(realTextTopicTreeData),
                filterFn,
                newSearchValue
            );
            let tempFilteredTopicTreeExpandedKeys = expandedKeysFun(
                tempFilteredTopicTree
            );
            setFilteredForRealTopicTreeData(tempFilteredTopicTree);
            setFilteredForRealTopicTreeExpandedKeys(
                tempFilteredTopicTreeExpandedKeys
            );
        }
    };

    const realTextTopicTreeData = useMemo(() => {
        let _tree = generateTreeData(addTreePropertyForList(deepCloneV2(topicList)));
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                const childDeviceNodeCount = getNodeChildDeviceNodeCount(topic);
                const childDeviceNodeCheckedCount =
                    getNodeChildDeviceNodeCheckedCount(topic);
                topic.childDeviceNodeCount = childDeviceNodeCount;
                topic.childDeviceNodeCheckedCount = childDeviceNodeCheckedCount;
            }
        });
        _tree = filterTree(
            _tree,
            (node) =>
                node.topicType == "text" &&
                pureTextTreeDataCheckedKeyList.includes(node.id)
        );
        let serialNumberList = [];
        dfsRecursive(_tree, (topic: TopicType, level: number) => {
            if (topic.topicType == "text") {
                if (serialNumberList.length > level) {
                    serialNumberList = serialNumberList.splice(0, level + 1);
                    serialNumberList[serialNumberList.length - 1]++;
                } else if (serialNumberList.length == level) {
                    if (isEmpty(serialNumberList[level])) {
                        serialNumberList[level] = 1;
                    } else {
                        serialNumberList[level]++;
                    }
                } else {
                    serialNumberList.push(1);
                }
                const serialNumber = serialNumberList.join(".");
                topic.treeLevel = level;
                topic.serialNumber = serialNumber;
            }
        });
        const _pureTextTopicTreeData: TopicType[] = deepCloneV2(_tree);
        return _pureTextTopicTreeData;
    }, [topicList, pureTextTreeDataCheckedKeyList]);

    const onRealTextTopicTreeExpandedKeys = (keys: string[]) => {
        setRealTextTopicTreeExpandedKeys(keys);
    };

    const backToSelectTitle = () => {
        setCurrentStep("selectTitle");
        setRealTextTopicTreeDataSearchValue("");
        setRealTextTopicTreeSelectedNode(null);
        setRealTextTopicTreeHighLightNode(null);
    };

    const undoTopicList = () => {
        if (topicListHistoryList.length) {
            const newTopicListHistoryIndex = topicListHistoryIndex - 1;
            let newTopicList = topicListHistoryList[newTopicListHistoryIndex]._topicList;
            setTopicList(deepCloneV2(newTopicList));
            setTopicHistoryIndex(newTopicListHistoryIndex);
            const _checkedPureTextTopicIdList = topicListHistoryList[newTopicListHistoryIndex]._pureTextTreeDataCheckedKeyList;
            // console.log(_checkedPureTextTopicIdList, _checkedPureTextTopicIdList.length)
            setPureTextTreeDataCheckedKeyList(deepCloneV2(_checkedPureTextTopicIdList));
            let findTopic = newTopicList.find(ele => ele.topicName == '区碑');
            // console.log("findTopic---->111", topicListHistoryList, deepCloneV2(findTopic))
        }
    };

    const redoTopicList = () => {
        if (topicListHistoryList.length) {
            const newTopicListHistoryIndex = topicListHistoryIndex + 1;
            let newTopicList = topicListHistoryList[newTopicListHistoryIndex]._topicList;
            setTopicList([...newTopicList]);
            setTopicHistoryIndex(newTopicListHistoryIndex);
            const _checkedPureTextTopicIdList = topicListHistoryList[newTopicListHistoryIndex]._pureTextTreeDataCheckedKeyList;
            // console.log(_checkedPureTextTopicIdList, _checkedPureTextTopicIdList.length)
            setPureTextTreeDataCheckedKeyList(deepCloneV2(_checkedPureTextTopicIdList));
            let findTopic = newTopicList.find(ele => ele.topicName == '区碑');
            // console.log("findTopic---->111", topicListHistoryList, deepCloneV2(findTopic))
        }
    };

    const handleOpenDeviceTopicEditModal = (node: TopicType) => {
        editTopicModalNewRef.current.openModal(node)
    }

    const goToSelectDeviceList = () => {
        setCurrentStep("selecteDevice");
        let _realTextTopicTreeExpandedKeys = realTextTopicTreeExpandedKeys;
        let firstIncludeDeviceChildTextTopic: TopicType = null;
        let includeDeviceChildTextTopicList: TopicType[] = [];
        dfsRecursive(realTextTopicTreeData, (node: TopicType) => {
            if (
                node.childDeviceNodeCount
            ) {
                if (!firstIncludeDeviceChildTextTopic) {
                    firstIncludeDeviceChildTextTopic = node;
                }
                includeDeviceChildTextTopicList.push(node)
            }
        })
        if (includeDeviceChildTextTopicList.length) {
            includeDeviceChildTextTopicList.forEach(node => {
                const nodePathList: string[] = findTreePathByTargetNodeIdList(realTextTopicTreeData, node.id);
                nodePathList.forEach(pathId => {
                    if (!_realTextTopicTreeExpandedKeys.includes(pathId)) {
                        _realTextTopicTreeExpandedKeys.push(pathId)
                    }
                })
            })
            setRealTextTopicTreeSelectedNode(firstIncludeDeviceChildTextTopic);
            setRealTextTopicTreeHighLightNode(firstIncludeDeviceChildTextTopic);
            setRealTextTopicTreeExpandedKeys(deepCloneV2(_realTextTopicTreeExpandedKeys));
            realTextTopicTreeRef.current.scrollTo({ key: firstIncludeDeviceChildTextTopic.id })
        }
        // if (firstIncludeDeviceChildTextTopic) {
        //     const nodePathList = findTreePathByTargetNodeIdList(realTextTopicTreeData, firstIncludeDeviceChildTextTopic.id);
        //     nodePathList.forEach(pathId => {
        //         if (!_realTextTopicTreeExpandedKeys.includes(pathId)) {
        //             _realTextTopicTreeExpandedKeys.push(pathId)
        //         }
        //     })
        //     setRealTextTopicTreeSelectedNode(firstIncludeDeviceChildTextTopic);
        //     setRealTextTopicTreeHighLightNode(firstIncludeDeviceChildTextTopic);
        //     setRealTextTopicTreeExpandedKeys(deepCloneV2(_realTextTopicTreeExpandedKeys));
        //     realTextTopicTreeRef.current.scrollTo({ key: firstIncludeDeviceChildTextTopic.id })
        // }
    }

    const doNotShowAgainKey = useRef('TopicManagePageV3_DeleteDevice_doNotShowAgainKey')
    const doNotShowAgainRef = useRef<boolean>(Boolean(localStorage.getItem(doNotShowAgainKey.current)) || false);

    const onDoNotShowAgainChange = (e: CheckboxChangeEvent) => {
        localStorage.setItem(doNotShowAgainKey.current, getChangeEventValue(e) + '')
        doNotShowAgainRef.current = getCheckBoxChangeEventValue(e);
    };

    const confirmToDeleteDeviceTopic = (node: TopicType) => {
        let _tempTreeData = topicTree;
        let _tempTopicList = [...tree2List(_tempTreeData)];
        let findIndex = _tempTopicList.findIndex(topic => topic.key == node.id);
        if (findIndex != -1) {
            _tempTopicList.splice(findIndex, 1);
            updateTopicList(deepCloneV2(_tempTopicList));
        }
    }

    const handleDeleteDeviceNode = (node: TopicType) => {
        if (doNotShowAgainRef.current) {
            confirmToDeleteDeviceTopic(node);
        } else {
            modal.confirm({
                title: '温馨提示',
                icon: <ExclamationCircleOutlined />,
                content: (
                    <div className="with-not-show-again-modal-content">
                        确认删除此项目？此操作不可撤销
                        <div
                            className="not-show-again-row"
                        >
                            <Checkbox onChange={onDoNotShowAgainChange} />
                            &nbsp;下次不再提示
                        </div>
                    </div>
                ),
                okText: '确认',
                cancelText: '取消',
                centered: true,
                onOk: () => confirmToDeleteDeviceTopic(node),
            });
        }
    }

    const [deviceAlertClosed, setDeviceAlertClosed] = useState(false);

    return (
        <div ref={containerRef} style={{ width: "100%", height: "100%", fontWeight: '400' }}>
            <div className="flex-row topic-manage-page-container-v3">
                <div className="flex-col topic-manage-page-content">
                    <div className="flex-row topic-manage-page-panel-row">
                        <div
                            className={
                                makeClassNameList([
                                    "flex-col topic-manage-page-pannel-first",
                                    currentStep == 'selectTitle' ? 'show' : 'hide'
                                ])
                            }
                        >
                            <div className="topic-manage-page-pannel-first-content">
                                <div
                                    className={makeClassNameList([
                                        "flex-col topic-manage-page-pannel-overlay",
                                        currentStep == 'selecteDevice' ? 'show' : 'hide'
                                    ])}
                                ></div>
                                <div className="flex-row topic-manage-page-pannel-title">
                                    <div className="flex-row">
                                        <div className="topic-manage-page-pannel-title-text">
                                            规划大纲编制
                                        </div>
                                    </div>
                                </div>
                                <div className="topic-manage-page-pannel-body">
                                    <div className="flex-row topic-manage-page-pannel-search-row">
                                        <AutoSelectSearchInput
                                            placeholder="搜索规划大纲"
                                            value={pureTextTopicTreeDataSearchValue}
                                            onChange={onPureTextTopicTreeDataSearchValueChange}
                                            allowClear
                                            maxLength={10}
                                            className="topic-search-box"
                                        />
                                        <div className="flex-row btn-group">
                                            <Tooltip title={"展开所有级别大纲"}>
                                                <div
                                                    className="flex-row expand-option-btn"
                                                    onClick={expandAllNode}
                                                >
                                                    <img src={require('./../../../assets/image/expand-icon.png')} />
                                                </div>
                                            </Tooltip>
                                            <div style={{ width: 1, height: 24, backgroundColor: '#d9d9d9' }}></div>
                                            <Tooltip title={"收起所有级别大纲"}>
                                                <div
                                                    className="flex-row expand-option-btn"
                                                    onClick={unExpandAllNode}
                                                >
                                                    <img src={require('./../../../assets/image/flip-icon.png')} />
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Tree
                                        ref={pureTextTopicTreeRef}
                                        style={{ width: "100%", overflow: "hidden" }}
                                        showLine
                                        autoExpandParent={false}
                                        defaultExpandAll={false}
                                        switcherIcon={<DownOutlined />}
                                        checkable={true}
                                        //@ts-ignore
                                        titleRender={renderPureTextTopicTreeNode}
                                        expandedKeys={
                                            isEmpty(pureTextTopicTreeDataSearchValue)
                                                ? pureTextTopicTreeExpandedKeys
                                                : filteredForPureTopicTreeExpandedKeys
                                        }
                                        onExpand={onPureTextTopicTreeExpanded}
                                        //@ts-ignore
                                        treeData={
                                            isEmpty(pureTextTopicTreeDataSearchValue)
                                                ? pureTextTopicTreeData
                                                : filteredForPureTextTopicTree
                                        }
                                        selectedKeys={
                                            pureTextTopicTreeSelectedNode
                                                ? [pureTextTopicTreeSelectedNode.id]
                                                : []
                                        }
                                        onSelect={onSelectPureTextTopicNode}
                                        checkedKeys={pureTextTreeDataCheckedKeyList}
                                        onCheck={onPureTextTreeDataCheckedChange}
                                        height={windowFullHeight - 254}
                                        blockNode
                                        draggable={{
                                            icon: false,
                                            nodeDraggable: () =>
                                                isEmpty(pureTextTopicTreeDataSearchValue),
                                        }}
                                        onDragStart={onDragStart}
                                        onDragOver={onDragOver}
                                        onDragEnd={onDragEnd}
                                        onDrop={onTopicNodeDrop}
                                    />
                                </div>
                            </div>
                            <div
                                className="flex-row topic-manage-page-pannel-first-footer"
                            >
                                {/* <Button
                                    type={'primary'}
                                    onClick={goToSelectDeviceList}
                                >
                                    编辑完成 进入下一步
                                    <div style={{ fontWeight: 700 }}>大纲确认</div>
                                    <ArrowRight style={{ marginTop: 5, marginLeft: 7, marginRight: 7 }} theme="filled" size="18" fill="#fff" />
                                </Button> */}
                                <img
                                    src={require('./../../../assets/image/arrow-right-fill-icon.png')}
                                    // src={require('./../../../assets/image/back-arrow-icon.png')}
                                    id="goToSelectDeviceListBtnId"
                                    className="topic-manage-icon-btn"
                                    onClick={goToSelectDeviceList}
                                />
                            </div>
                        </div>
                        <div
                            className={makeClassNameList([
                                "flex-col topic-manage-page-pannel-second",
                                currentStep == 'selecteDevice' ? 'show' : 'hide'
                            ])}
                        >
                            <div
                                className={makeClassNameList([
                                    "flex-col topic-manage-page-pannel-overlay",
                                    currentStep == 'selectTitle' ? 'show' : 'hide'
                                ])}
                            ></div>
                            <div className="flex-row topic-manage-page-pannel-second-content">
                                <div className="flex-col topic-manage-page-pannel-second-left">
                                    <div className="flex-row topic-manage-page-pannel-title">
                                        <div className="flex-row">
                                            <div className="topic-manage-page-pannel-title-text">
                                                规划项目选择
                                            </div>
                                        </div>
                                    </div>
                                    <div className="topic-manage-page-pannel-body center-body">
                                        <div className="flex-row topic-manage-page-pannel-search-row">
                                            <AutoSelectSearchInput
                                                placeholder="搜索规划大纲"
                                                value={realTextTopicTreeDataSearchValue}
                                                onChange={onRealTextTopicTreeDataSearchValueChange}
                                                allowClear
                                                className="topic-search-box"
                                            />
                                            <div className="flex-row btn-group">
                                                <Tooltip title={"展开所有级别大纲"}>
                                                    <div
                                                        className="flex-row expand-option-btn"
                                                        onClick={expandRealTopicTreeAllNode}
                                                    >
                                                        <img src={require('./../../../assets/image/expand-icon.png')} />
                                                    </div>
                                                </Tooltip>
                                                <div style={{ width: 1, height: 24, backgroundColor: '#d9d9d9' }}></div>
                                                <Tooltip title={"收起所有级别大纲"}>
                                                    <div
                                                        className="flex-row expand-option-btn"
                                                        onClick={unExpandRealTopicTreeAllNode}
                                                    >
                                                        <img src={require('./../../../assets/image/flip-icon.png')} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{ width: '100%', marginBottom: 8 }}>
                                            <Alert
                                                type={'warning'}
                                                className="flex-row"
                                                style={{ paddingTop: 3, paddingBottom: 3 }}
                                                description={<div style={{ color: '#7f7f7f' }}><span style={{ color: '#303133' }}>注</span>：“加粗的大纲”包含规划项目</div>}
                                                closable
                                            />
                                        </div>
                                        <Tree
                                            ref={realTextTopicTreeRef}
                                            style={{ width: "100%", overflow: "hidden" }}
                                            showLine
                                            autoExpandParent={false}
                                            defaultExpandAll={false}
                                            switcherIcon={<DownOutlined />}
                                            //@ts-ignore
                                            titleRender={renderRealTextTopicTreeNode}
                                            expandedKeys={
                                                isEmpty(realTextTopicTreeDataSearchValue)
                                                    ? realTextTopicTreeExpandedKeys
                                                    : filteredForRealTopicTreeExpandedKeys
                                            }
                                            onExpand={onRealTextTopicTreeExpandedKeys}
                                            //@ts-ignore
                                            treeData={
                                                isEmpty(realTextTopicTreeDataSearchValue)
                                                    ? realTextTopicTreeData
                                                    : filteredForRealTextTopicTree
                                            }
                                            selectedKeys={
                                                realTextTopicTreeSelectedNode
                                                    ? [realTextTopicTreeSelectedNode.id]
                                                    : []
                                            }
                                            onSelect={onSelectRealTextTopicNode}
                                            height={windowFullHeight - 254 - 42}
                                            blockNode
                                        />
                                    </div>
                                </div>
                                <div className="flex-col topic-manage-page-pannel-second-right">
                                    <div className="topic-manage-page-pannel-second-right-header-show"></div>
                                    <div className="flex-row topic-manage-page-pannel-second-right-header">
                                        {
                                            realTextTopicTreeSelectedNode && realTextTopicTreeSelectedNode.topicName ?
                                                <div style={{ fontWeight: '700' }}>
                                                    {realTextTopicTreeSelectedNode && realTextTopicTreeSelectedNode.topicName}
                                                    {/* {`（${realTextTopicTreeSelectedNode.childDeviceNodeCheckedCount}/${realTextTopicTreeSelectedNode.childDeviceNodeCount}）`} */}
                                                    {`（${deviceNodeList.filter(ele => ele.checked).length}/${deviceNodeList.length}）`}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div
                                        className="flex-col"
                                        style={{
                                            flex: 1,
                                            width: '100%',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            paddingLeft: 12,
                                            paddingRight: 12,
                                            paddingTop: 0,
                                            paddingBottom: 12,
                                        }}
                                    >
                                        <div
                                            className="flex-row topic-manage-page-pannel-search-row"
                                            style={{ marginTop: -5 }}
                                        >
                                            <AutoSelectSearchInput
                                                placeholder="搜索规划项目"
                                                value={deviceTopicSearchValue}
                                                onChange={onDeviceTopicSearchValueChange}
                                                allowClear
                                                maxLength={10}
                                                className="topic-search-box"
                                            />
                                            <Button
                                                id="topicManagePageResetRecommandBtnId"
                                                type={'primary'}
                                                className="heavy-kit"
                                                style={{ marginLeft: 12 }}
                                                disabled={isEmpty(realTextTopicTreeSelectedNode)}
                                                onClick={handleResetDeviceListRecommand}
                                                ghost
                                            ><UndoOutlined />还原默认推荐</Button>
                                            <Button
                                                id="topicManagePageAddTopicBtnId"
                                                type={'primary'}
                                                className="heavy-kit"
                                                style={{ marginLeft: 12 }}
                                                disabled={isEmpty(realTextTopicTreeSelectedNode)}
                                                onClick={handleOpenAddDeviceTopicModal}
                                            ><PlusOutlined />新建项目</Button>
                                        </div>
                                        {
                                            realTextTopicTreeSelectedNode && deviceNodeList.length ?

                                                <>
                                                    <div style={{ width: '100%', marginBottom: 12 }}>
                                                        <Alert
                                                            type={'warning'}
                                                            className="flex-row"
                                                            style={{ paddingTop: 3, paddingBottom: 3 }}
                                                            description={<div style={{ color: '#7f7f7f' }}><span style={{ color: '#303133' }}>注</span>：规划项目数量单价可在“投资估算表”统一编辑</div>}
                                                            onClose={() => setDeviceAlertClosed(true)}
                                                            closable
                                                        />
                                                    </div>
                                                    <CustomScroll
                                                        ref={scrollbarsRef}
                                                        heightRelativeToParent={deviceAlertClosed ? "calc(100vh - 320px)" : "calc(100vh - 349px)"}
                                                    >
                                                        <table style={{ width: '37.2vw' }} className="dash-borad-table-el all-border-table" >
                                                            <thead>
                                                                <tr className="dash-borad-table-el-header">
                                                                    <th style={{ width: '5%' }} className="device-topic-check-cell">
                                                                        <Checkbox
                                                                            checked={checkIsSelectedAllDeviceToicList()}
                                                                            onChange={switchCheckAllStatus}
                                                                        />
                                                                    </th>
                                                                    <th>项目名称</th>
                                                                    <th style={{ width: '10%' }}>数量</th>
                                                                    <th style={{ width: '10%' }}>单位</th>
                                                                    <th style={{ width: '18%', textAlign: 'center' }}>操作</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    deviceNodeList.map((node, index) => {
                                                                        return (
                                                                            <React.Fragment
                                                                                key={index + '1'}
                                                                            >
                                                                                <tr
                                                                                    className="dash-borad-table-el-body-row"

                                                                                >
                                                                                    <td>
                                                                                        <Checkbox
                                                                                            key={index + '-checkbox'}
                                                                                            checked={node.checked}
                                                                                            onChange={(e) => onCheckedChange(e, node)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>{formatDeviceTopicSearchText(node.title)}</td>
                                                                                    <td style={{ textAlign: 'center', paddingLeft: 0 }}>{node.count}</td>
                                                                                    <td style={{ textAlign: 'center', paddingLeft: 0 }}>{node.unit}</td>
                                                                                    <td style={{ textAlign: 'center', paddingLeft: 0 }}>
                                                                                        <div className="flex-row" style={{ width: '100%', justifyContent: 'center' }}>
                                                                                            <Tooltip
                                                                                                title={!node.checked ? '请勾选项目后操作' : ''}
                                                                                            >
                                                                                                <Button
                                                                                                    type={'text'}
                                                                                                    size={'small'}
                                                                                                    style={{ color: node.checked ? '#2256D6' : '#2256D672' }}
                                                                                                    disabled={!node.checked}
                                                                                                    onClick={() => handleOpenDeviceTopicEditModal(node)}
                                                                                                >更多</Button>
                                                                                            </Tooltip>
                                                                                            {
                                                                                                node.isCustom ?
                                                                                                    <Tooltip
                                                                                                        title={!node.checked ? '请勾选项目后操作' : ''}
                                                                                                    >
                                                                                                        <Button
                                                                                                            type={'text'}
                                                                                                            size={'small'}
                                                                                                            onClick={() => handleDeleteDeviceNode(node)}
                                                                                                            disabled={!node.checked}
                                                                                                            danger
                                                                                                        >
                                                                                                            删除
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </CustomScroll>
                                                </>
                                                :
                                                <div style={{ width: '100%' }} className="flex-col">
                                                    <Empty
                                                        imageStyle={{ height: 160 }}
                                                        style={{ marginTop: '20%' }}
                                                        image={require('./../../../assets/image/empty-icon.png')}
                                                        description={<div style={{ color: '#7f7f7f', marginTop: 12 }}>
                                                            {
                                                                realTextTopicTreeSelectedNode ?
                                                                    '本节默认没有规划项目，如需添加自行新增'
                                                                    :
                                                                    '请选择规划大纲'
                                                            }
                                                        </div>}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row topic-manage-page-pannel-second-footer">
                                {/* <Button
                                    type={'primary'}
                                    ghost
                                    onClick={backToSelectTitle}
                                >
                                    重新编辑大纲
                                    <ArrowLeft style={{ marginTop: 5, marginLeft: 7, marginRight: 7 }} theme="filled" size="18" fill={saasConfig.colorConfig.colorPrimary} />
                                    <div style={{ fontWeight: 700 }}>返回修改</div>
                                </Button> */}
                                <img
                                    // src={require('./../../../assets/image/arrow-right-fill-icon.png')}
                                    src={require('./../../../assets/image/back-arrow-icon-heavy.png')}
                                    className="topic-manage-back-icon"
                                    onClick={backToSelectTitle}
                                />
                                <div className="flex-row">
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={undoTopicList}
                                        disabled={topicListHistoryIndex <= 0}
                                    >
                                        <ArrowLeftOutlined />
                                        撤销
                                    </Button>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={redoTopicList}
                                        disabled={
                                            topicListHistoryList.length <= topicListHistoryIndex + 1
                                        }
                                    >
                                        恢复
                                        <ArrowRightOutlined />
                                    </Button>
                                    <Button
                                        id="topicManagePageNextBtnId"
                                        type={'primary'}
                                        onClick={() => confirmToUpdateTopicList()}
                                        loading={confirmLoading}
                                    // disabled={topicListHistoryList.length < 2}
                                    >
                                        {/* 确认完成 */}
                                        下一步
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TopicTreeContextMenu
                ref={contextMenuRef}
                currentScreen={"allTopic"}
                onContextMenuClose={onContextMenuClose}
                currentTopicNode={pureTextTopicTreeSelectedNode}
                onPickerActionType={onPickerTreeNodeActionTypeForPureTopicTree}
                allowOption={"just-text"}
                allowDelete={'allow-custom'}
            />
            <AddTopicModalNew
                key={'1'}
                ref={addTopicModalRefForPureTopicTree}
                currentTopicNode={pureTextTopicTreeSelectedNode}
                currentTreeNodeActionType={currentTreeNodeActionType}
                onFinish={onChangeTopicForPureTopicTree}
            />
            <AddTopicModalNew
                key={'2'}
                ref={addTopicModalRefForRealTopicTree}
                currentTopicNode={realTextTopicTreeSelectedNode}
                currentTreeNodeActionType={currentTreeNodeActionType}
                onFinish={onChangeTopicForRealTopicTree}
            />
            <EditTopicModalNew
                ref={editTopicModalNewRef}
                key={'3'}
                onFinish={onChangeTopicForDeviceNodeList}
            />
            <ReNameTopicModal
                ref={renameTopicModalRef}
                onFinish={_onReNameFinish}
            />
            {contextHolder}
        </div>
    );
};

export default TopicManagePage;
