import { getRequest, postRequest } from "../http-client";
// import * as formRequest from './../http-client/form-request';
import { exportPostRequest } from "../http-client/exportService";
import { DeptType } from "../utils/types";

const systemApi = {
    //登录接口
    login: (params: any) => {
        return postRequest('/user/login', params)
    },
    //注册接口
    register: (params: any) => {
        return postRequest('/user/register', params)
    },
    phoneRegister: (params: any) => {
        return postRequest('/user/phone_register', params)
    },
    sendCode: (params: any) => {
        return postRequest('/sms/send', params)
    },
    getUserInfo: (params: any) => {
        return postRequest('/user/get_user_info', params)
    },
    //获取今日
    getToDayResidueRegisterUserNum() {
        return getRequest('/user/get_today_residue_user_num', null)
    },
    //添加部门
    addDept: (params: DeptType) => {
        return postRequest('/dept/create_dept', params)
    },
    //获取部门列表
    getDeptList: (params: any) => {
        return postRequest('/dept/query_dept_list', params)
    },
    deleteDept: (params: any) => {
        return postRequest('/dept/delete_dept', params)
    },
    getUserList: (params: any) => {
        return postRequest('/user/query_user_list__back', params)
    },
    //获取角色列表
    getRoleList: (params: any) => {
        return postRequest('/role/query_role_list', params)
    },
    //创建/修改角色信息
    addRole: (params: any) => {
        return postRequest('/role/create_role', params)
    },
    //删除角色
    deleteRole: (params: any) => {
        return postRequest('/role/delete_role', params)
    },
    //大纲信息
    getTopicList: (params: any) => {
        return postRequest('/topic_info/query_topic_info_list', params)
    },
    addTopic: (params: any) => {
        return postRequest('/topic_info/create_topic_info', params)
    },
    //删除大纲
    deleteTopic: (params: any) => {
        return postRequest('/topic_info/delete_topic_info', params)
    },
    //获取保护地类型分类
    getProtectlandTypeList: (params: any) => {
        return postRequest('/protectland/query_protectland_list', params)
    },
    getProtectlandTypeTopicTemplateList: (params: any) => {
        return postRequest('/protectland/query_protectland_template_list', params)
    },
    addProtectlandType: (params: any) => {
        return postRequest('/protectland/create_protectland', params)
    },
    deleteProtectlandType: (params: any) => {
        return postRequest('/protectland/delete_protectland', params)
    },
    addTopicTemplate: (params: any) => {
        return postRequest('/topic_template/create_topic_template', params)
    },
    deleteTopicTemplate: (params: any) => {
        return postRequest('/topic_template/delete_topic_template', params);
    },
    getTopicTemplateList: (params: any) => {
        return postRequest('/topic_template/query_topic_template_list', params)
    },
    getSpeciesInfoList: (params: any) => {
        return postRequest('/import-data/query_species_base_info_list', params)
    },
    //保护地基本信息填写
    addProtectlandBaseInfo: (params: any) => {
        return postRequest('/protectland/create_protectland_base_info', params)
    },
    //文档模块
    addProject: (params: any) => {
        return postRequest('/doc/create_doc', params)
    },
    addProjectByTopicList: (params: any) => {
        return postRequest('/doc/create_doc_by_topic_list', params)
    },
    editProject: (params: any) => {
        // return postRequest('/doc/update_doc_topList', params)
    },
    deleteProject: (params: any) => {
        return postRequest('/doc/delete_doc', params)
    },
    getProjectBaseInfo: (params: any) => {
        return postRequest('/doc/query_doc_base_info', params)
    },
    updateProjectBaseInfo: (params: any) => {
        return postRequest('/doc/update_doc_base_info', params)
    },
    updateProjectTopicList: (params: any) => {
        return postRequest('/doc/update_doc_topList', params)
    },
    updateDocExtraConfigInfo: (params: any) => {
        return postRequest('/doc/add_or_update_doc_config', params)
    },
    queryDocExtraConfigInfo: (params: any) => {
        return postRequest('/doc/query_doc_config_info', params)
    },
    getProjectList: (params: any) => {
        return postRequest('/doc/query_doc_list', params)
    },
    exportExcel: (params: any) => {
        return postRequest('/export-api/planning-assistant/export-xls', params)
    },
    exportWord: (params: any) => {
        return postRequest('/export-api/planning-assistant/export-doc', params)
    },
    copyProject: (params: any) => {
        return postRequest('/doc/copy_doc', params)
    },

    /**
     * 查询共享文件
     * @param params 
     * @returns 
     */
    queryShareFileList: (params: any) => {
        return postRequest('/shared_folder/query_share_folder', params)
    },

    /**
     * 创建共享文件
     * @param params 
     * @returns 
     */
    createFolderFile: (params: any) => {
        return postRequest('/shared_folder/create_share_file', params)
    },

    /**
     * 基础文件上传
     * @param params 
     * @returns 
     */
    baseFileUpload: (params: any) => {
        return postRequest('/shared_folder/base_file_upload', params)
    },

    /**
     * 创建或更新共享文件夹
     * @param params 
     * @returns 
     */
    createOrUpdateShareFolder: (params: any) => {
        return postRequest('/shared_folder/create_or_update_share_folder', params)
    },

    deleteShareFileMutiple: (params: any) => {
        return postRequest('/shared_folder/del_share_folder', params)
    },

    /**
     * 创建或修改规划分组
     * @param params 
     * @returns 
     */
    createDocGroup: (params: any) => {
        return postRequest('/sectionalization/create_sect', params)
    },

    /**
     * 删除规划分组
     * @param params 
     * @returns 
     */
    deleteDocGroup: (params: any) => {
        return postRequest('/sectionalization/delete_sect', params)
    },

    /**
     * 查询规划分组列表
     * @param params 
     * @returns 
     */
    queryDocGroupList: (params: any) => {
        return postRequest('/sectionalization/query_sect_list', params)
    },

    /**
     * 批量查询物种信息
     * @param params names
     * @returns 
     */
    querySpeciesInfoMutiple: (params: any) => {
        return postRequest('/import-data/batch_query_species_record_by_species_c', params)
    },
    addMapLayer: (params: any) => {
        return postRequest('/geospatial/basics/basicsAdd', params)
    },
    editMapLayer: (params: any) => {
        return postRequest('/geospatial/basics/basicsEdit', params)
    },
    deleteMapLayer: (params: any) => {
        return postRequest('/geospatial/basics/basicsDelete', params)
    },
    qeuryMapLayer: (params: any) => {
        return postRequest('/geospatial/basics/basicsList', params)
    },
    exportWordReport: (params: any) => {
        // return exportPostRequest('/export-api/planning-assistant/export-base-doc', params)
        return exportPostRequest('/export-api/planning-assistant/export-base-doc-yn-ghzs', params)
    },
    editUserInfoBySelf: (params: any) => {
        return postRequest('/user/create_user_back', params)
    },
    changePasswordBySelf: (params: any) => {
        return postRequest('/user/edit_password', params)
    },
    floraQuery: (params: any) => {
        return postRequest('/import-data/flora_select', params)
    },
    getPanningBasisList: (params: any) => {
        return postRequest('/panning_basis/query_panning_basis_list', params)
    },
    getProtectlandNameInfoList: (params: any) => {
        return postRequest('/protectland/query_yn_protectland_list', params)
    },
    fillSpeciesLatinName: (params: any) => {
        return postRequest('/export-api/tools-service/fillSpeciesLatinName', params)
    },
    createFeedBack: (params: any) => {
        return postRequest('/question_back/create_question_back', params)
    },
    createModelEssay: (params: any) => {
        return postRequest('/doc/create_user_background_text', params)
    },
    getModelEssayList: (params: any) => {
        return postRequest('/doc/query_doc_background_text_list', params);
    },
    deleteModelEssayMutiple: (params: any) => {
        return postRequest('/doc/delete_user_background_text', params);
    },
    checkDocNameRepeat: (params: any) => {
        return postRequest('/doc/query_doc_name', params)
    },
    updateDocBaseInfo: (params: any) => {
        return postRequest('/doc/update_doc_base_info', params)
    },
    getUserGuideCurrentStep: (params: any) => {
        return postRequest('/user/get_user_guide_now_step', params)
    },
    updateUserGuideCurrentStep: (params: any) => {
        return postRequest('/user/edit_user_guide_now_step', params)
    }
}


export default systemApi;