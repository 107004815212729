
export interface ThemeConfig {
    systemType: SaasSystemType
    systemNameFirst: string
    systemNameSecond: string
    colorConfig: {
        colorPrimary: string
        colorPrimaryDisabled?: string
        colorSuccess?: string
        colorWarning?: string
        colorError?: string
        colorInfo?: string
        colorTextBase?: string
        colorBgBase?: string
    }
    themeLogoPath: any
    lightLogoPath: any
    matchPath?: string
    nodeJsApiBaseUrl?: string
    javaApiBaseUrl?: string
    templateBaseUrl?: string
    uploadTemplate?: UploadTemplate
}

export type UploadTemplateName =
    "保护地基本概况上传模板.docx"
    | "社区情况统计表上传模板.xlsx"
    | "土地利用现状表上传模板.xlsx"
    | "功能区划表上传模板.xlsx"
    | "人员现状统计表上传模板.xlsx"
    | "基础设施现状统计表上传模板.xlsx"
    | "野生动植物资源统计表上传模板.xlsx"
    | "物种名单上传模板.xlsx"
    | "植被与生态系统类型统计分析上传模板.xlsx"
    | "地类统计分析.xlsx"
    | "环境因子分析上传模板.xlsx"
    | "社区经济统计.xlsx"
    | "管理队伍统计.xlsx"


export interface UploadTemplate {
    "保护地基本概况上传模板": string
    "社区情况统计表上传模板"?: string
    "土地利用现状表上传模板"?: string
    "功能区划表上传模板"?: string
    "人员现状统计表上传模板"?: string
    "基础设施现状统计表上传模板"?: string
    "野生动植物资源统计表上传模板"?: string
    "物种名单上传模板"?: string
    "植被与生态系统类型统计分析上传模板"?: string
    "地类统计分析"?: string
    "环境因子分析上传模板"?: string
    "社区经济统计"?: string
    "管理队伍统计"?: string
}


export type SaasSystemType = 'HuaWeiTestServer' | 'QingYuanDefault' | 'YunNanLinGuiYuan'

//默认配置是北京青远生态
const themeConfigDefault: ThemeConfig = {
    systemType: 'QingYuanDefault',
    systemNameFirst: "自然保护地总体规划",
    systemNameSecond: "智能编制系统",
    colorConfig: {
        colorPrimary: "#3fb075",
        colorPrimaryDisabled: "#3fb07572",
        colorBgBase: "#3fb07512"
    },
    themeLogoPath: require('./../assets/image/logo-qingyuan.jpg'),
    lightLogoPath: require('./../assets/image/logo-qingyuan-light.png'),
    matchPath: "",
    nodeJsApiBaseUrl: "https://ghzsapi.zhbhd.cn",
    javaApiBaseUrl: "https://ghzsapi.zhbhd.cn",
    templateBaseUrl: "https://ghzs.zhbhd.cn",
}

//官方测试服
const themeConfigQingYuanTest: ThemeConfig = {
    systemType: 'HuaWeiTestServer',
    systemNameFirst: "自然保护地总体规划",
    systemNameSecond: "智能编制系统（测试服）",
    colorConfig: {
        colorPrimary: "#3fb075",
        colorPrimaryDisabled: "#3fb07572",
        colorBgBase: "#3fb07512"
    },
    themeLogoPath: require('./../assets/image/logo-qingyuan.jpg'),
    lightLogoPath: require('./../assets/image/logo-qingyuan-light.png'),
    matchPath: "",
    nodeJsApiBaseUrl: "http://ghzsapi.map-cloud.cn",
    javaApiBaseUrl: "https://ghzsapi.zhbhd.cn",
    templateBaseUrl: "http://ghzs.map-cloud.cn",
}

//云南林规院配置
const themeConfigYunNan: ThemeConfig = {
    systemType: 'YunNanLinGuiYuan',
    systemNameFirst: "总体规划智慧辅助系统", //总体规划智慧辅助系统
    systemNameSecond: "",
    colorConfig: {
        colorPrimary: "#3fb075",
        colorPrimaryDisabled: '#3fb07572',
        colorBgBase: "#3fb07512",
    },
    themeLogoPath: require('./../assets/image/logo-yunnan.jpg'),
    lightLogoPath: require('./../assets/image/logo-yunnan.jpg'),
    matchPath: "/ghzs_api",
    nodeJsApiBaseUrl: "http://220.163.43.218:9001",
    javaApiBaseUrl: "http://220.163.43.218:9001",
    templateBaseUrl: "http://220.163.43.218:9001",
}

export const saasConfig = themeConfigDefault;         //青远公网
// export const saasConfig = themeConfigQingYuanTest;    //华为云测试服
// export const saasConfig = themeConfigYunNan;          //云南林规院