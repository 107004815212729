import React from 'react';

import { BaseSlateItemType, DocItemType, SlateElementProps } from '../../types';
import { CombineParagraph } from '../combine-paragraph';
import { Heading1 } from '../heading1';
import { Heading2 } from '../heading2';
import { Heading3 } from '../heading3';
import { Heading4 } from '../heading4';
import { Heading5 } from '../heading5';
import { Heading6 } from '../heading6';
import { Input } from '../input';
import { Paragraph } from '../paragraph';
import { TableNameParagraph } from '../table-name-paragraph';
import { SlateTable } from '../table';
import { Tag } from '../tag';
import { LaTex } from '../la-tex';
import { DocImageEl } from '../image';
import { TableDescParagraph } from '../table-desc-paragraph';

const NeverElement = (type: DocItemType): never => {
  throw new Error(`unknown element:${type}.`);
};

export const SlateElement = React.memo(function SlateElement(props: SlateElementProps) {
  const {
    element: { type = DocItemType.P },
  } = props;
  switch (type) {
    case DocItemType.P:
      return <Paragraph {...props} />;
    case DocItemType.CP:
      return <CombineParagraph {...props} />;
    case DocItemType.H1:
      return <Heading1 {...props} />;
    case DocItemType.H2:
      return <Heading2 {...props} />;
    case DocItemType.H3:
      return <Heading3 {...props} />;
    case DocItemType.H4:
      return <Heading4 {...props} />;
    case DocItemType.H5:
      return <Heading5 {...props} />;
    case DocItemType.H6:
      return <Heading6 {...props} />;
    case DocItemType.TABLE:
      return <SlateTable {...props} />;
    case DocItemType.LaTex:
      return <LaTex {...props} />;
    case DocItemType.TABLE_NAME:
      return <TableNameParagraph {...props} />;
    case DocItemType.TABLE_DESC:
      return <TableDescParagraph {...props} />;
    case DocItemType.Image:
      return <DocImageEl {...props} />
    case BaseSlateItemType.Input:
      return <Input {...props} />;
    case BaseSlateItemType.Tag:
      return <Tag {...props} />;
    default:
      return NeverElement(type);
  }
});
