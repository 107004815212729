import React from 'react';

import { BoldConfig, FontFamilyConfig, FontSizeConfig } from '../../constants';
import { useEmitSlateItemClick, useEmitSlateItemImageDeleteClick } from '../../hooks';
import { SlateElementProps } from '../../types';
import { createClassNameMark } from '../../utils';

import Style from './main.module.scss';
import DocImage from './DocImage';
const classNames = createClassNameMark(Style);
export const DocImageEl = React.memo(function Heading6({ attributes, children, element, style }: SlateElementProps) {
    const { externalData } = element;
    const onClick = useEmitSlateItemClick(element);
    const onDelete = useEmitSlateItemImageDeleteClick(element);

    return (
        <div
            {...attributes}
            data-doc-item-id={element.id}
            className={classNames('doc-image-container')}
            onClick={onClick}
        >
            {/* {children} */}
            {
                externalData && externalData.imageUrl ?
                    <DocImage
                        docItemExternalData={externalData}
                        onDelete={onDelete}
                    />
                    :
                    null
            }
        </div>
    );
})
